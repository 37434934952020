import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useLocation } from 'react-router-dom'

export const useGTMPageView = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const timer = setTimeout(() => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: pathname,
          title: document.title,
        },
      })
    }, 200)

    return () => clearTimeout(timer)
  }, [pathname])
}
