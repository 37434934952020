import React, { ComponentType } from 'react'

import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import theme from 'config/theme'

function withTheme<T extends object>(WrappedComponent: ComponentType<T>) {
  return (props: T) => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* overwrite global styles if needed */}
        {/* <GlobalStyles styles={{ html: {} }} /> */}
        <WrappedComponent {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export { withTheme }
