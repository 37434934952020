import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { Box, Link, Typography } from '@mui/material'
import { Button } from '@rent/ui'
import { useCountdownTimer } from '@rent/ui/hooks'
import { LayoutWithHeader } from 'components/layouts'
import { NotReadyComponent } from 'components/shared'
import {
  ConsultantData,
  ExternalLinks,
  GeneralRoute,
  OrderStatus,
  PaymentStatus,
  RouteQueryParam,
  RouteQueryParamFinishedStatus,
  RouteQueryParamPaymentType,
} from 'consts'
import { useRouteQuery } from 'hooks'

import { useGetCustomerOrder, useGetPaymentInfo } from '../api'

type SummaryInfo = {
  title: string
  content: any
}

const allowedStatuses = [
  OrderStatus.OFFER,
  OrderStatus.OFFER_ACCEPTED,
  OrderStatus.BOOKED,
  OrderStatus.CANCELED,
  OrderStatus.ORDERED,
  OrderStatus.WAITING_FOR_ACCEPTANCE,
  OrderStatus.RISK_ACCEPTED,
  OrderStatus.RISK_REJECTED,
]

const OrderFinishedConfirmation = () => {
  const { formatMessage } = useIntl()
  const routerQuery = useRouteQuery()
  const { orderId } = useParams()
  const navigate = useNavigate()

  const orderFinishedStatus = routerQuery.get(RouteQueryParam.ORDER_FINISHED_STATUS)

  const [summaryInfo, setSummaryInfo] = useState<SummaryInfo | null>(null)

  const { data: orderData, isLoading, isSuccess, isError, error } = useGetCustomerOrder(orderId ?? '')

  const [shouldCheckPaymentInfo, setShouldCheckPaymentInfo] = useState(false)
  const { data: pData, isFetching: pIsFetching, isError: pIsError } = useGetPaymentInfo(orderId ?? '', shouldCheckPaymentInfo)

  const loading = isLoading || pIsFetching

  const handleRedirect = () => {
    window.location.replace(orderData?.returnURL ?? ExternalLinks.WESUB)
  }

  const { remainingSeconds } = useCountdownTimer({
    minutes: 0,
    seconds: 15,
    hideSecZeros: true,
    callbackFn: handleRedirect,
  })

  const checkOrderFinishedStatus = () => {
    switch (orderFinishedStatus) {
      case null:
        navigate(`/${GeneralRoute.NOT_FOUND}`)
        break
      case RouteQueryParamPaymentType.DEBIT_NOTE_SUCCESS:
        setSummaryInfo({
          title: formatMessage({ id: 'notification.thank_you_for_your_order' }),
          content: formatMessage({ id: 'notification.processing_debit_note' }),
        })
        break
      case RouteQueryParamPaymentType.AUTOPAY_LINK_GENERATION_OUT_OF_TIME:
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.we_need_more_time_to_process_your_order' }),
        })
        break
      case RouteQueryParamPaymentType.EXTENSION_SUCCESS:
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.processing_extension' }),
        })
        break
      case RouteQueryParamFinishedStatus.AGREEMENT_NOT_ACCEPTED:
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.agreement_generated_but_not_accepted' }),
        })
        break

      case RouteQueryParamFinishedStatus.PRODUCTS_NOT_AVAILABLE:
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.products_not_available' }),
        })
        break

      case RouteQueryParamPaymentType.AUTOPAY_CALLBACK:
        setShouldCheckPaymentInfo(true)
        break

      default:
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.order_processing_generic_error' }),
        })
        break
    }
  }

  useEffect(() => {
    if (orderData && allowedStatuses.includes(orderData?.status as OrderStatus)) {
      if (orderData.status === OrderStatus.BOOKED && orderFinishedStatus !== RouteQueryParamPaymentType.AUTOPAY_CALLBACK) {
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.order_finished' }),
        })
      } else if (orderData.status === OrderStatus.CANCELED) {
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.order_cancelled' }),
        })
      } else if (orderData.status === OrderStatus.OFFER_ACCEPTED && orderData.generatedContract) {
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content: formatMessage({ id: 'notification.agreement_generated_but_not_accepted' }),
        })
      } else {
        checkOrderFinishedStatus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, routerQuery])

  useEffect(() => {
    if (!pIsFetching && shouldCheckPaymentInfo) {
      if (pIsError) {
        navigate(`/${GeneralRoute.NOT_FOUND}`)
      } else {
        setSummaryInfo({
          title: formatMessage({ id: 'notification.dear_customer' }),
          content:
            pData?.paymentStatus === PaymentStatus.SUCCESS
              ? formatMessage({ id: 'notification.payment_success' })
              : formatMessage({ id: 'notification.waiting_for_payment' }),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCheckPaymentInfo, pData, pIsFetching, pIsError])

  return (
    <>
      <Helmet>
        <title>RentPay - {formatMessage({ id: 'finalization' })}</title>
      </Helmet>
      <NotReadyComponent isLoading={loading} isSuccess={isSuccess} isError={isError} error={error}>
        <LayoutWithHeader maxWidth="77.2rem" noBgr={!summaryInfo}>
          {!loading && !isError && !allowedStatuses.includes(orderData?.status as OrderStatus) && (
            <Navigate to={`/${GeneralRoute.NOT_FOUND}`} />
          )}

          {summaryInfo && (
            <Box>
              <Typography variant="h2" mb={2.4}>
                {summaryInfo.title}
              </Typography>
              <Typography color="text.secondary" mb={2.4} sx={{ whiteSpace: 'pre-line' }}>
                {summaryInfo.content}
              </Typography>

              <Typography color="text.secondary" variant="body2">
                {formatMessage(
                  { id: 'notification.contact_us_if_any_question' },
                  { email: <Link href={`mailto:${ConsultantData.email}`}>{ConsultantData.email}</Link> },
                )}
              </Typography>

              <Button sx={{ mt: { xs: 3.2, md: 4.6 }, mb: 1.6 }} onClick={handleRedirect}>
                {formatMessage({ id: 'button.go_to_the_store' })}
              </Button>

              <Typography color="text.secondary" variant="body2">
                {formatMessage({ id: 'notification.auto_return_to_the_store' }, { val: remainingSeconds })}
              </Typography>
            </Box>
          )}
        </LayoutWithHeader>
      </NotReadyComponent>
    </>
  )
}

export default OrderFinishedConfirmation
