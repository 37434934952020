import { ReactNode, memo, useMemo } from 'react'
import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Currency } from 'components/shared'
import {
  calcProductPrice,
  calcProductsLoanTotalPrice,
  calcProductsTotalLoanFee,
  calcProductsTotalPrice,
  checkProductsHaveSameRentalPeriod,
} from 'helpers'
import { IMappedProduct } from 'models'

import { containerStyle, gridItemContainerStyle, totalPriceStyle } from './styles'

interface ProductsDataSummaryLoanProps {
  items: IMappedProduct[]
  actionButton?: ReactNode | null
}

const ProductsDataSummaryLoan = memo(({ items, actionButton }: ProductsDataSummaryLoanProps) => {
  const { formatMessage } = useIntl()

  const { totalPrice, totalLoanPrice, loanFee, rentalPeriod } = useMemo(() => {
    const calculatedProducts = items.map(product => calcProductPrice(product))

    const totalPrice = calcProductsTotalPrice(calculatedProducts)
    const totalLoanPrice = calcProductsLoanTotalPrice(calculatedProducts)
    const loanFee = calcProductsTotalLoanFee(calculatedProducts)
    const isSameRentalPeriod = checkProductsHaveSameRentalPeriod(calculatedProducts)

    return {
      totalPrice,
      totalLoanPrice,
      loanFee,
      rentalPeriod: isSameRentalPeriod ? calculatedProducts[0]?.rentalPeriod : null,
    }
  }, [items])

  return (
    <>
      <Divider />
      <Box sx={containerStyle}>
        {/* rentalPeriod */}
        {rentalPeriod && (
          <>
            <Box sx={gridItemContainerStyle}>
              <Typography variant="body2">{formatMessage({ id: 'loan_period' })}</Typography>

              <Typography variant="body2" fontWeight={700}>
                {rentalPeriod} {formatMessage({ id: 'months' })}
              </Typography>
            </Box>
            <Divider sx={{ my: 1.6 }} />
          </>
        )}

        {/* month loan price */}
        <Box sx={gridItemContainerStyle}>
          <Typography variant="body2">{formatMessage({ id: 'month_loan_price' })}</Typography>
          <Typography variant="body2" fontWeight={700}>
            <Currency value={totalPrice} mth={false} net={null} />
          </Typography>
        </Box>
        <Divider sx={{ my: 1.6 }} />

        {/* prowizja */}
        <>
          <Box sx={[gridItemContainerStyle, { mb: 0 }]}>
            <Typography variant="body2">{formatMessage({ id: 'commission' })}</Typography>
            <Typography variant="body2" fontWeight={700}>
              <Currency value={loanFee} mth={false} net={null} />
            </Typography>
          </Box>
          <Typography variant="subtitle2">({formatMessage({ id: 'commissionIncluded' })})</Typography>
          <Divider sx={{ my: 1.6 }} />
        </>

        {/* final loan price */}
        <Box sx={gridItemContainerStyle}>
          <Typography variant="body2" fontWeight={700} mt={0.2} mr={0.8}>
            {formatMessage({ id: 'final_loan_price' })}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flexBasis: '80%' }}>
            <Typography variant="h6" sx={totalPriceStyle}>
              <Currency value={totalLoanPrice} mth={false} />
            </Typography>
          </Box>
        </Box>

        {/* actionButton */}
        {actionButton && <Box mt={3.2}>{actionButton}</Box>}
      </Box>
    </>
  )
})
export default ProductsDataSummaryLoan
