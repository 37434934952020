import { useEffect } from 'react'

import { useStepLayoutContext } from 'components/layouts'

import { useGetVendorData } from '../api/useGetVendorData'

interface VendorData {
  vendorId: number | undefined
}

/**
 * The `useVendorData` function retrieves vendor data and sets the vendor image if it exists.
 * @param {VendorData}  - - `vendorId`: The ID of the vendor for which the data is being fetched.
 */
export const useVendorData = ({ vendorId }: VendorData) => {
  const { vendorImg, setVendorImg } = useStepLayoutContext()
  const { data } = useGetVendorData(!vendorImg && vendorId ? vendorId.toString() : '')

  useEffect(() => {
    if (data?.banner && data?.bannerContentType) {
      setVendorImg({
        src: `data:${data.bannerContentType};base64,${data.banner}`,
        alt: 'vendorImg',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.banner, data?.bannerContentType])
}
