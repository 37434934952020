import { Outlet } from 'react-router-dom'

import { useGTMPageView } from 'hooks'

const OrderContainer = () => {
  useGTMPageView()

  return <Outlet />
}

export default OrderContainer
