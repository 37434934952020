export const enum OrderStatus {
  CONTRACT_PATTERN = 'CONTRACT_PATTERN',
  CONTRACT_LOADED = 'CONTRACT_LOADED',
  PRE_ORDER = 'PRE_ORDER',
  NEW = 'NEW',
  OFFER = 'OFFER',
  REJECTED = 'REJECTED',
  WAITING_FOR_ACCEPTANCE = 'WAITING_FOR_ACCEPTANCE',
  RISK_REJECTED = 'RISK_REJECTED',
  RISK_ACCEPTED = 'RISK_ACCEPTED',
  ORDERED = 'ORDERED',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  LEASE_ACCEPTED = 'LEASE_ACCEPTED',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  BOOKED = 'BOOKED',
  BOT = 'BOT',
  PREVIEW_ONLY = 'PREVIEW_ONLY',
  UNACCEPTABLE_LEGAL_FORM = 'UNACCEPTABLE_LEGAL_FORM',
  INVALID_CLIENT_DATA = 'INVALID_CLIENT_DATA',
  CREDIT_APPLICANTS = 'CREDIT_APPLICANTS',
}
