import { ReactNode, memo, useContext } from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { OrderContext } from 'context'
import { IMappedProduct } from 'models'

import HelpBlock from '../HelpBlock'
import OfferProviderBox from './OfferProviderBox'
import ProductsDataSummary from './ProductsDataSummary'
import ProductsDataSummaryLoan from './ProductsDataSummary/ProductsDataSummaryLoan'
import ProductsList from './ProductsList'

interface OrderSummaryPanelProps {
  items: IMappedProduct[]
  actionButton?: ReactNode | null
  promoBox?: ReactNode | null
  isFirstStep?: boolean
}

export const OrderSummaryPanel = memo(({ items, actionButton, isFirstStep, promoBox }: OrderSummaryPanelProps) => {
  const { contextData } = useContext(OrderContext)
  return (
    <Box mb={1.6}>
      {!contextData.isLoan && promoBox && promoBox}
      <ProductsList items={items} isFirstStep={isFirstStep} isLoan={contextData.isLoan} />
      <Paper sx={{ bgcolor: 'background.default', mb: 1.2 }}>
        <OfferProviderBox />
        {contextData.isLoan ? (
          <ProductsDataSummaryLoan items={items} actionButton={actionButton} />
        ) : (
          <ProductsDataSummary items={items} actionButton={actionButton} />
        )}
      </Paper>
      <HelpBlock isLoan={contextData.isLoan} />
    </Box>
  )
})

export default OrderSummaryPanel
