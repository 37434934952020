import { SxProps, Theme } from '@mui/material'
import { containerSpacings } from 'components/shared/styledComponents'

export const offerProviderBoxBoxStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...containerSpacings,
}

export const imgStyle: SxProps<Theme> = {
  display: 'flex',
  '& img': {
    height: 34,
    width: 'auto',
  },
}
