const terms = {
  title: 'REGULAMIN KORZYSTANIA I ZAWIERANIA TRANSAKCJI PRZEZ RENTPAY',
  content: `
  <div>
  <p class="subtitle">I. WPROWADZENIE</p>
  <p>
    Niniejszy dokument (zwany dalej „Regulaminem”) wraz z innymi dokumentami w nim wspominanymi, określa warunki, jakim podlega korzystanie
    z RentPay oraz zawieranie umów i realizowanie transakcji za jego pośrednictwem.
  </p>
  <p>
    Zachęcamy do starannego zapoznania się z niniejszym Regulaminem oraz zasadami przetwarzania danych osobowych dostępnych na stronie
    internetowej <a href="https://wesub.eu/pl/rodo/" target="_blank" rel="noopener noreferrer">https://wesub.eu/pl/rodo/</a> przed
    rozpoczęciem korzystania z RentPay.
  </p>
  <p>
    Akceptacja Regulaminu jest dobrowolna, aczkolwiek konieczna w celu korzystania z RentPay, a finalnie zawierania transakcji, tj.: umów.
    Po zaakceptowaniu postanowień niniejszego Regulaminu stają się one wiążące stosownie dla Użytkownika, Usługobiorcy lub Pożyczkobiorcy.
  </p>
  <p>
    Regulamin może ulec zmianom w dowolnym czasie, jednakże zmiany nie będą miały wpływu na korzystanie z RentPay i Zamówienia złożone przed
    wejściem w życie zmian Regulaminu.
  </p>
  <p>
    W razie pytań dotyczących Regulaminu lub zasad przetwarzania danych osobowych, Użytkownik lub stosownie Usługobiorca lub Pożyczkobiorca
    może skontaktować się z nami poprzez kontakt e-mailowy pod adresem: <a href="mailto:iod@wesub.pl">iod@wesub.pl</a>
  </p>
  <p>
    Poprzez RentPay mogą być organizowane Promocje na wybrane Przedmioty. O zasadach dotyczących Promocji decyduje WeSub S.A., podając je do
    wiadomości publicznej na RentPay lub za pośrednictwem podjęcia innych działań marketingowych po uprzednim uzyskaniu zgody Użytkownika
    lub stosownie Usługobiorcy, Pożyczkobiorcy.
  </p>
  <p>
    Ogłoszenia, reklamy, oferty, cenniki i inne informacje o Przedmiotach, podane na RentPay, w szczególności ich opisy, parametry
    techniczne i użytkowe oraz ceny, stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71 Kodeksu cywilnego.
  </p>
  <p>
    WeSub S.A. zastrzega, iż realizowanie transakcji za pośrednictwem RentPay, tj.: zawieranie umów uzależnione jest każdorazowo od
    akceptacji transakcji przez WeSub S.A. W przypadku, gdy weryfikacja stosownie Użytkownika lub Usługobiorcy/Pożyczkobiorcy wykaże
    jakiekolwiek ryzyko lub nieprawidłowości, WeSub S.A. zastrzega prawo do odmowy zawarcia Umowy stosownie z Użytkownikiem, Usługobiorcą
    lub Pożyczkobiorcą zgodnie z rozdziałem XV niniejszego Regulaminu.
  </p>
  <p class="subtitle">II. INFORMACJE O NAS</p>
  <p>
    <span class="font-bold">WeSub S.A.</span> z siedzibą w Warszawie przy ul. Branickiego 15, wpisana do rejestru przedsiębiorców Krajowego
    Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Warszawie XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS:
    0000891739, posiadającą REGON: 388564561 oraz NIP: 9512516964, posiadająca kapitał zakładowy w wysokości 5 000 000,00 zł.
  </p>
  <p>
    WeSub S.A. to pionierska grupa doradcza specjalizująca się w tworzeniu rozwiązań biznesowych w oparciu o idee gospodarki subskrypcyjnej.
    Usługi i programy oferowane przez WeSub S.A. skierowane są wyłącznie dla firm oraz klientów prowadzących działalność gospodarczą.
  </p>
  <h3 class="subtitle">III. DEFINICJE</h3>
  <p>
    <span class="fancy-font">Czas realizacji Zamówienia</span> - liczba Dni roboczych, w których Usługodawca skompletuje Zamówienie złożone
    przez Usługobiorcę, powierzy zamówione Przedmioty przewoźnikowi oraz dostarczy zamówione Przedmioty za pośrednictwem przewoźnika do
    Usługobiorcy.
  </p>
  <p>
    <span class="fancy-font">Użytkownik</span> - podmiot, tj.: osoba prowadząca jednoosobową działalność gospodarczą, dla której zgodnie z
    danymi zawartymi w szczególności w Centralnej Ewidencji i Informacji o Działalności Gospodarczej, Umowa z Usługodawcą posiada charakter
    zawodowy i jest bezpośrednio związana z jej działalnością gospodarczą, tj do której nie stosuje się przepisu art. 38a ustawy z dnia z
    dnia 30 maja 2014 r. o prawach konsumenta (Dz.U.2020.287 t.j. ze zm.) lub/i spółka handlowa w rozumieniu powszechnie obowiązujących
    przepisów prawa, która zamierza zawrzeć US/UP z Usługodawcą.
  </p>
  <p>
    <span class="fancy-font">Usługobiorca/Pożyczkobiorca</span> - podmiot, tj.: osoba prowadząca jednoosobową działalność gospodarczą, dla
    której zgodnie z danymi zawartymi w szczególności w Centralnej Ewidencji i Informacji o Działalności Gospodarczej, Umowa z Usługodawcą
    posiada charakter zawodowy i jest bezpośrednio związana z jej działalnością gospodarczą, tj. do której nie stosuje się przepisu art. 38a
    ustawy z dnia z dnia 30 maja 2014 r. o prawach konsumenta (Dz.U.2020.287 t.j. ze zm.) lub/i spółka handlowa w rozumieniu powszechnie
    obowiązujących przepisów prawa, która zawarła US/UP z Usługodawcą. Każdorazowe odniesienie w niniejszym Regulaminie do którejkolwiek z
    ww. wymienionych nazw (Użytkownik, Usługobiorca, Pożyczkobiorca) oznacza odniesienie do powyższych pojęć.
  </p>
  <p><span class="fancy-font">Dni robocze</span> - dni tygodnia od poniedziałku do piątku, z wyłączeniem dni ustawowo wolnych od pracy.</p>
  <p>
    <span class="fancy-font">Formularz</span> - usługa elektroniczna zawierająca aktywne pola umożliwiające Użytkownikowi/Usługobiorcy
    złożenia zapytania o Ofertę oraz złożenie Zamówienia, a także przeprocesowania realizację i zawarcie US/UP, w szczególności poprzez
    dodanie wybranego Przedmiotu do Koszyka oraz określenie warunków US/UP, w tym sposobu dostawy i płatności w przypadku US.
  </p>
  <p>
    <span class="fancy-font">Generalna Umowa Subskrypcji /GUS</span> - umowa ramowa, która określa ogólne zasady świadczenia subskrypcji
    usług przez Usługodawcę na rzecz Usługobiorcy,
  </p>
  <p>
    <span class="fancy-font">Kaucja</span> - określona suma pieniędzy, która stanowi zabezpieczenie prawidłowego wykonania US, a w
    szczególności terminowego uiszczania należności wynikających z US oraz utrzymania Przedmiotu Subskrypcji w należytym stanie.
  </p>
  <p>
    <span class="fancy-font">Kodeks Cywilny</span> - ustawa kodeks cywilny z dnia 23 kwietnia 1964 roku (t.j. Dz.U. z 2022 r. poz. 1360 ze
    zm.)
  </p>
  <p>
    <span class="fancy-font">Koszyk</span> - wirtualne narzędzie, umożliwiające Użytkownikowi/Usługobiorcy/Pożyczkobiorcy agregację i wybór
    Przedmiotów lub usług przed ich zamówieniem oraz przeliczenie wartości Umowy (w tym ubezpieczenia oraz pobocznych opłat) oraz
    miesięcznych należności wynikających z US lub UP. Podczas wyboru Przedmiotów lub dostępnych usług, Usługobiorca może dowolnie zarządzać
    zawartością Koszyka poprzez dodawanie do niego lub usuwania z niego Przedmiotów lub usług.
  </p>
  <p>
    <span class="fancy-font">Oferta</span> - wszelkie ogłoszenia, reklamy, oferty, cenniki i inne informacje o przedmiotach, podane na
    RentPay lub przekazane Użytkownikowi, w szczególności ich opisy, parametry techniczne i użytkowe oraz ceny, które stanowią zaproszenie
    do zawarcia umowy w rozumieniu art. 71 Kodeksu cywilnego, natomiast nie stanowią oferty w rozumieniu art. 66 i następnych Kodeksu
    cywilnego.
  </p>
  <!-- <p>
    <span class="fancy-font">Platforma internetowa</span> - platforma internetowa znajdująca się pod linkami: https://niesklep.rentup.pl/
    wraz z rozszerzeniami https://rent.wesub.pl z rozszerzeniami
  </p> -->
  <p>
    <span class="fancy-font">Przedmiot Subskrypcji lub Przedmiot</span> - przedmiot lub przedmioty będące przedmiotem lub przedmiotami US.
  </p>
  <p>
    <span class="fancy-font">Promocja</span> - ściśle określony w czasie rabat cenowy na wybrane Przedmioty lub usługi, będące w Ofercie
    Usługodawcy/Pożyczkodawcy i mogące być Przedmiotem Umowy pomiędzy Usługodawcą, a Usługobiorcą/Pożyczkobiorcą.
  </p>
  <p><span class="fancy-font">Regulamin</span> - niniejszy dokument wraz z dokumentami w nim wspomnianymi.</p>
  <p>
    <span class="fancy-font">RentPay</span> - moduł umożliwiający zawieranie transakcji, tj.: pozwalający na wygenerowanie oferty, zawarcie
    GUS oraz US/UP i akceptację warunków wynikających z GUS oraz z US/UP.
  </p>
  <p>
    <span class="fancy-font">Subskrypcja</span> - model korzystania z usług w określonym czasie z ustaloną przez Strony częstotliwością
    płatności opłat subskrypcyjnych.
  </p>
  <p>
    <span class="fancy-font">Ubezpieczenie</span> - umowny obowiązek dokonania świadczenia przez ubezpieczyciela na rzecz ubezpieczonego na
    wypadek powstania zdarzeń określonych w umowie ubezpieczeniowej.
  </p>
  <p><span class="fancy-font">Umowa</span> - stosownie GUS, US lub UP.</p>
  <p>
    <span class="fancy-font">Umowa Subskrypcji/US</span> - umowa/umowy świadczenia Usług, w szczególności dzierżawy, najmu lub subskrypcji
    na Przedmioty Subskrypcji lub/i prowadzenie ksiąg rachunkowych, w której wskazane są m.in.: Przedmiot Subskrypcji wraz ze specyfikacją,
    numerem seryjnym, okres trwania Umowy Subskrypcji, wysokość, termin i sposób płatności opłaty subskrypcyjnej oraz wysokość Kaucji.
  </p>
  <p>
    <span class="fancy-font">Usługodawca/Pożyczkodawca</span> - spółka WeSub S.A., z siedzibą w Warszawie przy ul. Branickiego 15, wpisana
    do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Warszawie XIII Wydział Gospodarczy Krajowego
    Rejestru Sądowego pod nr pod nr KRS: 0000891739, posiadającą REGON: 388564561 oraz NIP: 9512516964, posiadająca kapitał zakładowy w
    wysokości 5 000 000,00 zł.
  </p>
  <p>
    <span class="fancy-font">Umowa Pożyczki/UP</span> - umowa/umowy pożyczki kwoty pieniężnej w formie finansowania Usługobiorcy przez
    Usługodawcę, na podstawie indywidualnie przygotowanej oferty dla Pożyczkobiorcy w Koszyku.
  </p>
  <p>
    <span class="fancy-font">Zamówienie</span> - oświadczenie woli Użytkownika złożone za pośrednictwem Formularza, określające m.in.:
    rodzaj i ilość Przedmiotów Subskrypcji, dane Użytkownika/Usługobiorcy stanowiące złożenie Usługodawcy przez Użytkownika/Usługobiorcę
    oferty zawarcia Umowy za pośrednictwem RentPay będących przedmiotem zamówienia.
  </p>
  <p>
    <span class="fancy-font">WeBook</span> - portal udostępniany Usługobiorcy w ramach zawartych US, który umożliwia Usługobiorcy dostęp i
    zarządzanie zawartymi US, tj.: wgląd do zawartych umów i innych dokumentów, do stanu rozliczeń, w tym dostęp do wystawianych faktur oraz
    możliwości składania wniosków.
  </p>
  <p class="subtitle">IV. ZOBOWIĄZANIA UŻYTKOWNIKA I USŁUGOBIORCY/POŻYCZKOBIORCY</p>
  <p>
    Usługobiorca/Pożyczkobiorca zobowiązany jest do korzystania z RentPay w sposób zgodny z powszechnie obowiązującymi przepisami prawa oraz
    postanowieniami niniejszego Regulaminu. Usługobiorca/Pożyczkobiorca zobowiązany jest do niedostarczania i nieprzekazywania treści o
    charakterze bezprawnym oraz zobowiązany jest do korzystania z RentPay w sposób niezakłócający jej funkcjonowania. Usługobiorca
    zobowiązany jest w szczególności do korzystania z RentPlace lub/i WeBook w sposób zgody z prawem, Regulaminem, zasadami współżycia
    społecznego, mając na uwadze poszanowanie dóbr osobistych oraz praw autorskich i własności intelektualnej WeSub S.A.
    Usługobiorca/Pożyczkobiorca zobowiązany jest do wprowadzania danych zgodnych ze stanem faktycznym i prawnym, niewprowadzających w błąd
    lub naruszających prawa osób trzecich. Usługobiorca/Pożyczkobiorca niniejszym przyjmuje do wiadomości, iż korzysta z RentPay w zakresie
    własnego użytku osobistego. W przypadku naruszenia przez Użytkownika przepisów prawa, postanowień Regulaminu, a także w przypadku
    postępowania przez Użytkownika w sposób utrudniający pracę i prawidłowe funkcjonowanie RentPay, WeSub S.A. ma prawo pozbawić
    Usługobiorcy/Pożyczkobiorcy prawa do korzystania z RentPay lub ograniczyć dostęp do niektórych funkcjonalności RentPay lub/i WeBook.
  </p>
  <p class="subtitle">V. WŁASNOŚĆ INTELEKTUALNA I PRAWA AUTORSKIE</p>
  <p>
    Wszystkie zdjęcia przedmiotów oraz pozostałe materiały (w tym teksty, grafiki, logotypy) zamieszczone na RentPay są własnością WeSub
    S.A. lub zostały użyte przez niego za zgodą osób trzecich, posiadających do nich prawa autorskie. Zabronione jest kopiowanie zdjęć i
    innych materiałów graficznych oraz stosowanie przedruku tekstów zamieszczonych na RentPay w tym ich udostępnianie w Internecie bez
    pisemnej zgody WeSub S.A. lub innej osoby trzeciej, której przysługują prawa autorskie jest niezgodne z prawem i może stanowić podstawę
    do wszczęcia postępowania cywilnego oraz karnego przeciwko dopuszczającym się tego procederu.
  </p>
  <p class="subtitle">VI. KORZYSTANIE Z RENTPAY I PROCEDURA SKŁADANIA ZAMÓWIEŃ</p>
  <ol type="a" start="9">
    <li>Zawieranie transakcji w ramach US i UP</li>
  </ol>
  <p>
    Korzystając z RentPay, Użytkownik może wybrać usługę z jakiej chce skorzystać, tj.: czy chce zawrzeć US na Przedmiot lub Przedmioty,
    które go interesują, czy UP pożyczki kwoty pieniężnej w formie finansowania Usługobiorcy przez Usługodawcę. W przypadku chęci zawarcia
    US, w opisie Przedmiotu znajduje się nazwa Przedmiotu wraz z zakresem cen netto miesięcznie za dany Przedmiot. Po najechaniu kursorem na
    wybrany Przedmiot, Użytkownik może wybrać przycisk „Zamawiam”. Użytkownik może wybrać jeden lub więcej Przedmiotów do Zamówienia. Po
    kliknięciu przycisku „Zobacz więcej” wyświetla się okno, w którym znajdują się informacje o Przedmiocie, tj.: nazwa, cena, ilość oraz
    propozycja innych przedmiotów, które Użytkownik może dodać do Koszyka Aby dokończyć dalszy proces zamówienia, Użytkownik powinien
    kliknąć przycisk „Zamawiam”. Po kliknięciu przycisku „Zamawiam” wyskakujące okno (POPUP) znika, a Przedmioty zostają automatycznie
    przeniesione do Subskrypcji. Ten sam efekt tj. przeniesienie do Koszyka, osiąga się, gdy Użytkownik naciśnie przycisk “Zamawiam”
    bezpośrednio przy przedmiocie. Koszyk to wirtualne narzędzie, inaczej tzw. koszyk, w którym znajduje się podgląd zamówionych
    Przedmiotów. W Koszyku znajdziemy informację dotyczącą jakie Przedmioty zostały wybrane przez Użytkownika, jaka jest ich ilość, opłata
    miesięczna oraz końcowe wyliczenie należności. W dalszej kolejności Użytkownik ma możliwość zobaczenia Oferty oraz jej wygenerowania, a
    następnie akceptacji Oferty oraz przeprocesowania Umowy, tj.: zawarcia GUS, US, korzystając z RentPay. W przypadku chęci zawarcia UP, po
    otrzymaniu oferty pożyczki na e-mail Użytkownika, Uzytkownik po kliknięciu w odpowiedni link zostaje przekierowany do oferty, z którą
    powinien się zapoznać. W przypadku zainteresowania zawarciem UP, Użytkownik wypełnia podstawowe dane do wygenerowania formularza do UP,
    tj.: NIP, imię nazwisko, numer telefonu i adres e-mailowy. Użytkownik powinien odznaczyć wymagane zgody (dot. RODO oraz weryfikacji
    klienta), aby móc wykonać dalsze kroki i dokończyć transakcję. Następnie Użytkownik otrzymuje wiadomość e-mail wraz z linkiem o nazwie
    „Zawarcie umowy”, a po kliknięciu w link poproszony zostanie o uzupełnienie danych (NIP, e-mail, numer telefonu, imię i nazwisko, PESEL,
    nr i seria dowodu osobistego). W dalszej kolejności Użytkownik ma możliwość zobaczenia i akceptacji UP oraz przeprocesowania UP, tj.:
    zawarcia GUS, UP, korzystając z RentPay.
  </p>
  <p class="subtitle">VII. RENTPAY</p>
  <p>
    Aby dokończyć transakcje w ramach US lub UP, Usługobiorca zostanie poproszony o podanie danych osobowych oraz o zapoznanie się z
    klauzulami informacyjnymi przetwarzania danych osobowych, z Regulaminem oraz o dobrowolne wyrażenie zgody na kontakt marketingowy w celu
    wygenerowania Oferty, a następnie Usługobiorcy zostanie przedstawiona Oferta, która zostanie przesłana na adres e-mailowy Usługobiorcy.
    Aby zaakceptować Ofertę, Usługobiorca zostanie poproszony o kliknięcie w wygenerowany link znajdujący się w wiadomości e-mail. Po
    kliknięciu w link, Usługobiorca zostaje przeniesiony na RentPay oraz w celu dokończenia transakcji i zawarcia Umowy, zostaje poproszony
    o uzupełnienie danych, m.in.: nr PESEL oraz serii i numeru dowodu osobistego oraz o zaznaczenie obowiązkowych pól checkbox, tj. m.in.:
    oświadczenia o zapoznaniu się z treścią Umowy oraz oświadczenia o zapoznaniu się z treścią klauzuli informacyjnej o przetwarzaniu danych
    osobowych. Dobrowolnie Usługobiorca może wyrazić zgodę na przetwarzanie danych osobowych w celach marketingowych. W międzyczasie po
    uzupełnieniu wskazanych danych zostaje wygenerowana GUS, z której warunkami Usługobiorca zobowiązany jest się zapoznać poprzez
    kliknięcie przycisku „Zobacz warunki umowy”. Bez zapoznania się z GUS i US lub UP oraz ich warunkami oraz dokumentami stanowiącymi do
    nich załącznik lub załączniki, Usługobiorca nie może zaakceptować warunków GUS, US lub UP oraz kliknąć przycisku „Akceptuję warunki
    umowy”. Usługobiorca zapoznaje się z warunkami GUS, US lub UP oraz akceptuje jej treść poprzez sczytanie całej treści GUS, US lub UP i
    kliknięcie dodatkowo na końcu GUS przycisku „Przeczytałem umowę i akceptuję warunki”. Usługobiorca może także taka umowę zapisać na
    dysku lokalnym. Analogiczny proces ma miejsce przy zawieraniu US oraz UP. Po zaznaczeniu pól obowiązkowych dotyczących zapoznania się z
    treścią GUS lub/i US/UP oraz oświadczenia o zapoznaniu się z treścią klauzuli informacyjnej o przetwarzaniu danych osobowych, a także po
    sczytaniu treści GUS lub/i US/UP, Usługobiorca może kliknąć przycisk „Akceptuję warunki umowy”. Zaznaczenie przez Usługobiorcę
    odpowiedniego pola potwierdzającego zapoznanie się i akceptację warunków oraz treści GUS lub/i US/UP jest równoznaczne z zawarciem umowy
    poprzez oświadczenie woli złożone w formie dokumentowej oraz stanowi potwierdzenie woli zawarcia GUS lub/i US/UP. Na tym etapie w
    zakresie zawierania UP Usługobiorca (Pożyczkobiorca) jest weryfikowany przez Pożyczkodawcę. W przypadku, gdy weryfikacja Usługobiorcy
    (Pożyczkobiorcy) nie wykaże żadnych nieprawidłowości lub wątpliwości, Usługobiorca (Pożyczkobiorca) wykonuje przelew weryfikujący na
    kwotę 1,23 PLN. Po autoryzowaniu przez Usługodawcę przelewu następuje wypłata kwoty pożyczki. Natomiast w zakresie US, Usługobiorca jest
    weryfikowany przez Usługodawcę, a wynik tej weryfikacji Usługobiorca otrzyma w wiadomość e-mail. Następnie, Usługobiorca dostaje
    wiadomość e-mail informującą o Kaucji. Po zapłacie i zaksięgowaniu Kaucji stanowiącej zabezpieczenie danej US, Przedmiot zostaje
    zamówiony przez Usługodawcę. Ostatnim etapem jest przekazanie Przedmiotu do dostawy.
  </p>
  <p>
    Usługodawca zastrzega również możliwość zawierania US lub UP dokumentowej poprzez przekazanie Usługodawcy indywidualnego kodu uzyskanego
    przez Usługobiorcę za pośrednictwem wygenerowanej systemowo wiadomości SMS na numer telefonu Usługobiorcy (dalej: Kod SMS), tj.: US lub
    UP zawierana jest poprzez potwierdzenie jej/ich zawarcia poprzez podanie treści otrzymanej wiadomości SMS na numer telefonu Usługobiorcy
    wskazany stosownie w US lub UP. Przekazanie otrzymanej wiadomości SMS przez Usługobiorcę jest równoznaczne z oświadczeniem woli złożonym
    w formie dokumentowej oraz potwierdzeniem woli zawarcia US lub UP i stanowi jej prawidłowe zawarcie. Przekazanie przez Usługobiorcę
    treści otrzymanej wiadomości SMS na numer telefonu Usługobiorcy wskazany w US lub UP w dedykowanym formularzu lub upoważnionej w imieniu
    Usługodawcy osobie stanowi potwierdzenie zapoznania się i akceptacji warunków US lub UP oraz jest równoznaczne z oświadczeniem woli
    złożonym przez Usługobiorcę jako woli zawarcia US lub UP. Wobec powyższego, w przypadku akceptowania US lub UP Kodem SMS, Usługobiorca
    zobowiązany jest wskazać należący do niego numer telefonu komórkowego, a w przypadku jakichkolwiek zmian ww. numeru niezwłocznie
    powiadomić o tym fakcie Usługodawcę.
  </p>
  <p class="subtitle">VIII. DOSTĘPNOŚĆ USŁUG ORAZ DOSTAWA PRZEDMIOTU</p>
  <p>
    Przedmioty oferowane na RentPay mogą być dostarczone wyłącznie na terytorium Rzeczypospolitej Polski. Dostawa odbywa się przesyłką
    kurierską na adres siedziby Usługobiorcy. Usługobiorca ma możliwość wskazania innego adresu (niż adres siedziby) przed momentem
    realizacji i Zamówienia, niemniej jednak Usługodawca zastrzega sobie prawo do weryfikacji i odmowy wysłania Przedmiotu na inny adres niż
    adres siedziby Usługobiorcy. W tym przypadku, Usługobiorca powinien skontaktować się za pośrednictwem wiadomości e-mail z Usługodawcy na
    adres: biuro@wesub.pl Jeśli Usługobiorca pragnie zamówić Przedmioty do innego kraju Unii Europejskiej za pośrednictwem RentPay, jest to
    możliwe wyłącznie po wcześniejszym uzgodnieniu warunków z Usługodawcą.
  </p>
  <p>
    W przypadku realizacji dostawy poza granice Rzeczypospolitej Polski nie jesteśmy zobowiązani do pokrycia kosztów wysyłki ani zwrotu
    kosztów związanych z wysyłką poza granice Rzeczypospolitej Polski. Dostarczamy Przedmioty zgodnie z realizacją US na wskazany przez
    Usługobiorcę adres we wskazanym terminie. Jeśli termin nie został podany, w przybliżonym przedziale czasowym dostawy, a w każdym
    przypadku w terminie nieprzekraczającym 30 dni od daty zawarcia US. Termin dostawy uzależniony jest od dostępności danego Przedmiotu u
    producenta lub dostawcy, z którym współpracujemy. Jeżeli z powodu wyjątkowych okoliczności spodziewamy się, że może dojść do opóźnienia,
    skontaktujemy się z Usługobiorcą w celu ustalenia nowego terminu dostawy. W rozumieniu niniejszego Regulaminu uznaje się, że „dostawa”
    została zrealizowana lub Zamówienie zostało zrealizowane z chwilą, gdy Usługobiorca wejdzie faktycznie w posiadanie Przedmiotu
    Subskrypcji, co zostanie potwierdzone dostarczeniem przez firmę kurierską Przedmiotu Subskrypcji pod ustalonym adresem dostawy. Prosimy
    pamiętać, że nie realizujemy dostaw na adres skrzynki pocztowej. Proszę pamiętać także, że nie realizujemy dostaw w soboty, w niedziele
    i w święta oraz dni ustawowo wolne od pracy. Usługobiorca zobowiązuje się do odebrania zamówionego i dostarczonego Przedmiotu
    Subskrypcji. W przypadku jakichkolwiek niezgodności i nieprawidłowości z dostawą Przedmiotu Subskrypcji, Usługobiorca zobowiązany jest
    niezwłocznie poinformować o tym fakcie Usługodawcę.
  </p>
  <p class="subtitle">IX. NIEMOŻLIWOŚĆ REALIZACJI ZAMÓWIENIA I DOSTAWY</p>
  <p>
    W przypadku niemożliwości dostarczenia Zamówienia w terminie, mamy prawo anulować Zamówienie, a US zostanie rozwiązana. W takim
    przypadku, wszelkie środki wpłacone z tytułu US zostaną zwrócone Usługobiorcy. Proces zwrotu należności rozpoczyna się od momentu
    anulowania Zamówienia, a zwrot wskazanych w poprzednim zdaniu należności zajmuje do 14 dni.
  </p>
  <p class="subtitle">X. PRZEJŚCIE RYZYKA POSIADANIA DO UŻYWANIA PRZEDMIOTU SUBSKRYPCJI</p>
  <p>
    Z chwilą dostawy za ryzyko związane z Przedmiotem Subskrypcji odpowiada Usługobiorca zgodnie z zawartymi umowami, tj.: GUS, US i
    załącznikami do nich integralnymi, w tym zwłaszcza regulaminu produktu. Usługobiorca w pełni obejmie Przedmiot Subskrypcji w używanie z
    chwilą jego dostawy. Usługodawca wydaje Usługobiorcy Przedmiot Subskrypcji wolny od wad. Wydanie Usługobiorcy Przedmiotu Subskrypcji
    nastąpi w momencie rzeczywistego odbioru Przedmiotu Subskrypcji przez Usługobiorcę. Doręczenie i odbiór przez Usługobiorcę Przedmiotu
    Subskrypcji za pośrednictwem firmy kurierskiej poczytuje się jako odbiór Przedmiotu Subskrypcji bez wniesienia zastrzeżeń co do stanu
    technicznego Przedmiotu Subskrypcji. Usługobiorca po dopełnieniu wszelkich formalności związanych z odbiorem Przedmiotu Subskrypcji za
    pośrednictwem firmy kurierskiej, ma możliwość sprawdzenia w obecności kuriera zawartości przesyłki oraz niezwłocznego zgłoszenia uwag i
    spostrzeżeń Usługodawcy. W przypadku niestwierdzenia uwag i zastrzeżeń, poczytuje się jako odbiór Przedmiotu Subskrypcji bez wniesienia
    zastrzeżeń co do stanu technicznego Przedmiotu Subskrypcji. Usługobiorca przyjmuje do wiadomości, iż w momencie odbioru może zostać
    poproszony o wylegitymowanie się z dokumentu tożsamości przed przedstawicielem firmy kurierskiej, aby móc odebrać Przedmiot Subskrypcji.
    Usługobiorca może również odebrać Przedmiot Subskrypcji osobiście z siedziby Usługodawcy po wcześniejszym ustaleniu terminu lub z punktu
    partnerskiego wskazanego przez Usługodawcę. W takim przypadku, Usługobiorca podpisze z Usługodawcą protokół potwierdzający przekazanie
    Przedmiotu Subskrypcji Usługobiorcy.
  </p>
  <p class="subtitle">XI. OPŁATY I PŁATNOŚCI, PODATEK OD TOWARÓW I USŁUG (VAT)</p>
  <p>
    Ceny wskazane na RentPay, w US oraz załącznikach do US (z wyłączeniem kaucji) stanowią kwoty netto i zostaną powiększone przez
    Usługodawcę o wartość aktualnie obowiązującej stawki podatku VAT. Ceny są wyrażone w złotych polskich (PLN). Ceny uwzględniają opłaty za
    przesyłkę. Ceny mogą ulec zmianie w dowolnym czasie. Zmiany cen nie mają wpływu na Zamówienia złożone przed wejściem w życie zmiany
    ceny. Wszelkie płatności wynikające z US lub UP winny być uiszczone przez Usługobiorcę w terminach wskazanych przez Usługodawcę. W
    przypadku braku wpłaty Kaucji w terminie wskazanym przez Usługodawcę, US zostaje automatycznie rozwiązana. Wpłata Kaucji stanowi warunek
    zawarcia US. Usługodawca zastrzega, iż US ma charakter warunkowy, w tym znaczeniu, że niewpłacenie Kaucji powoduje nie wejście w życie
    US. Za datę uiszczenia Kaucji przyjmuje się datę uznania rachunku Usługodawcy. Wpłata Kaucji możliwa jest po prawidłowym zawarciu US
    pomiędzy Usługodawcą, a Usługobiorcą zgodnie z postanowieniami zawartymi w GUS, US lub/i regulaminie danej usługi lub produktu
    stanowiącej załącznik do GUS lub/i US. W panelu „Moje Zamówienia” pojawi się odpowiedni przycisk o nazwie „Kaucja”, w której będą
    znajdować się informacje dotyczące wpłaty Kaucji. Dodatkowo, Usługobiorca otrzyma wiadomość e-mail potwierdzającą konieczność opłacenia
    Kaucji.
  </p>
  <p class="subtitle">XII. GWARANCJA</p>
  <p>
    Nowe i nieużywane Przedmioty objęte są gwarancją producenta, a w przypadku Przedmiotów używanych gwarancja producenta jest uzależniona
    od czasu używania danego Przedmiotu. Usługobiorca ma możliwość każdorazowego uzyskania informacji dotyczących gwarancji od Usługodawcy.
    Gwarancja producenta to oświadczenie zamieszczone na dokumencie gwarancyjnym. Producent określa czas trwania gwarancji, a w przypadku,
    gdy Producent nie określi czasu trwania gwarancji przyjmuje się, iż czas trwania gwarancji to 2 lata od dnia zakupu Przedmiotu przez
    Usługodawcę od Producenta lub danego dostawcy. Usługodawca nie udziela dodatkowej gwarancji na Przedmioty oraz nie ponosi
    odpowiedzialności w zakresie udzielonej gwarancji przez Producenta.
  </p>
  <p class="subtitle">XIII. UBEZPIECZENIE</p>
  <p>Przedmioty są ubezpieczone na rzecz Usługodawcę.</p>
  <p>
    Zakresem ubezpieczenia objęte są wyłącznie koszty wynikające ze zdarzeń ubezpieczeniowych, objętych ochroną ubezpieczeniową, mających
    miejsce w okresie ubezpieczenia i które miały miejsce na terytorium Rzeczypospolitej Polskiej. Wszelkie definicje zdarzeń
    ubezpieczeniowych znajdują się w ogólnych warunkach ubezpieczenia, które Usługodawca na prośbę Usługobiorcy może udostępnić.
  </p>
  <p>
    Zasady i warunki związane z kwestią ubezpieczeń reguluje niniejszy Regulamin oraz regulamin danej usługi lub produktu stanowiący
    załącznik do GUS lub/i US, a Usługobiorca zobowiązuje się w przypadku wystąpienia jakiegokolwiek zdarzenia przewidzianego w umowie
    ubezpieczenia, niezwłocznie zawiadomić o tym fakcie Usługodawcę jak i zobowiązuje się postępować zgodnie z postanowieniami niniejszego
    Regulaminu oraz GUS lub/i US oraz regulaminu danej usługi lub produktu stanowiącego załącznik do GUS lub/i US , w tym również stosować
    się do wszelkich zaleceń i próśb Usługodawcy.
  </p>
  <p class="subtitle">XIV. PRAWO ODMOWY ZAWARCIA UMOWY</p>
  <p>
    Usługodawca zastrzega sobie prawo do odmowy do zawarcia US/UP z Użytkownikiem, w przypadku, gdy weryfikacja Użytkownika wykaże
    jakiekolwiek ryzyko i nieprawidłowości. Usługodawca nie jest zobowiązany do informowania Użytkownika o skorzystaniu z prawa do odmowy
    zawarcia US/UP oraz do informowania o wystąpieniu ryzyka i nieprawidłowości.
  </p>
  <p class="subtitle">XV. WIRUSY, PIRACTWO, ATAKI KOMPUTEROWE</p>
  <p>
    Usługodawca zapewnia ochronę przesyłanych treści cyfrowych i danych przez stosowanie środków technicznych i organizacyjnych, w celu ich
    zabezpieczenia przed pozyskaniem przez osoby nieupoważnione (m.in.: szyfrowanie SSL, hasła dostępu, programy antywirusowe). Jednakże
    Usługodawca wskazuje, iż korzystanie z sieci Internet i usług świadczonych drogą elektroniczną niesie zagrożenie przedostania się do
    systemu teleinformatycznego i urządzenia Użytkownika szkodliwego oprogramowania lub nieuprawnionego dostępu do danych Użytkownika przez
    osoby trzecie. W celu zminimalizowania zagrożeń, Użytkownik zobowiązany jest stosować odpowiednie zabezpieczenia techniczne, takie jak
    przykładowo: aktualne oprogramowanie antywirusowe.
  </p>
  <p>
    Korzystanie przez Użytkownika z RentPay w sposób niewłaściwy poprzez celowe uruchamiania lub wprowadzania szkodliwych materiałów,
    wirusów, koni trojańskich oraz wszelkich innych oprogramowań mających na celu uszkodzenie lub zniszczenie RentPay jest zabronione.
    Wszelkie próby nieautoryzowanego dostępu do RentPay jak i serwerów, baz danych oraz komputerów będących częścią ww. modułu, są zakazane.
    Użytkownik zobowiązuje się do nieprowadzenia świadomych działań mających na celu czasowe lub trwałe zatrzymanie funkcjonowania RentPay,
    a w szczególności do przeprowadzania prób lub ataków typu odmowa usługi (DOS) lub rozproszona odmowa usługi (DDOS).
  </p>
  <p class="subtitle">XVI. WYMAGANIA TECHNICZNE I PLIKI COOKIES</p>
  <p>
    Do korzystania z usług WeSub S.A. oraz z dostępnych formularzy lub ze sklepu internetowego niezbędne jest spełnienie następujących
    minimalnych wymagań technicznych:
  </p>
  <ol type="I">
    <li>Posiadanie komputera lub innego urządzenia multimedialnego z dostępem do sieci Internet;</li>
    <li>
      Posiadanie przeglądarki internetowej Internet Explorer w wersji 11.0 lub nowszej, Mozilla Firefox w wersji 52.0 lub nowszej, Google
      Chrome w wersji 56.0 lub nowszej, Apple Safari 8.0 lub nowszej z włączoną obsługą appletów Javy, JavaScript i cookies;<br />
    </li>
    <li>posiadanie aktywnego konta poczty elektronicznej (e-mail);</li>
    <li>zalecana minimalna rozdzielczość ekranu 1360 x 1024 pikseli.</li>
  </ol>
  <p>
    Usługodawca podejmuje środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia, w szczególności środki służące zapobieganiu
    pozyskania i modyfikacji przez osoby nieuprawnione danych osobowych przesyłanych przez Internet - w celu zapewnienia bezpieczeństwa
    komunikatów i danych przekazywanych w Formularzu. Usługodawca zapewnia bezpieczeństwo transmisji danych przekazywanych w Formularzu
    poprzez stosowanie protokołu SSL (SecureSocketLayer).
  </p>
  <p>
    Pliki cookies zbierają dane dotyczące korzystania z Formularza, a ich głównym celem jest ułatwienie korzystania z Formularza,
    dostosowanie RentPay do potrzeb i oczekiwań danego Użytkownika, który korzysta z Formularza albo RentPay. Stanowi to personalizację
    podstron RentPay oraz badanie ruchu Użytkowników. Pliki cookies są wykorzystywane na RentPay za zgodą Użytkownika. Zgoda może być
    wyrażona przez Użytkownika poprzez odpowiednie ustawienia oprogramowania, w szczególności przeglądarki internetowej, zainstalowanego w
    urządzeniu telekomunikacyjnym wykorzystywanym przez Użytkownika do przeglądania zawartości RentPay. Użytkownik może również w każdym
    czasie ograniczyć lub wyłączyć cookies w swojej przeglądarce przez takie jej ustawienie, aby blokowała cookies lub ostrzegała
    Użytkownika przed zapisaniem pliku cookies na urządzeniu, z którego korzysta do przeglądania RentPay lub Formularza. W takim przypadku
    może się jednak zdarzyć, że korzystanie z Formularza będzie mniej wydajne, a w pewnych przypadkach nawet niemożliwe.
  </p>
  <p class="subtitle">XVII. DANE OSOBOWE</p>
  <p>
    Informacje i dane osobowe podane przez Użytkownika podlegają przetwarzaniu zgodnie z zasadami ochrony danych. W sprawie zasad
    przetwarzania danych osobowych, Użytkownik może skontaktować się z nami poprzez kontakt mailowy na adres Inspektora Ochrony Danych
    Osobowych: <a href="mailto:iod@wesub.pl">iod@wesub.pl</a> lub tradycyjnie za pośrednictwem drogi pocztowej na adres: WeSub S.A. z
    siedzibą w Warszawie przy ulicy Branickiego 15 (02-972 Warszawa).
  </p>
  <p>
    Administratorem danych osobowych dotyczących Użytkownika oraz osób zaangażowanych po jego stronie w realizację procesu zawarcia GUS, US
    lub UP, a następnie w jej realizacji jest WeSub S.A. z siedzibą w Warszawie przy ul. Branickiego 15. Dane osobowe przetwarzane są w celu
    prawidłowej realizacji niniejszej Umowy na podstawie art. 6 ust.1 pkt b) RODO oraz dane osobowe będą przetwarzane na podstawie art. 6
    ust.1 pkt c) RODO oraz art. 6 ust.1 pkt f) RODO. Podstawą przetwarzania danych osobowych jest zawarcie i wykonanie GUS, US lub UP,. Dane
    osobowe będą udostępniane wyłącznie podmiotom, które biorą udział w procesie związanym z realizacją GUS, US lub UP, jak i również innym
    podmiotom, którym obowiązek przekazania danych osobowych będzie wynikał z przepisów prawa. Podane dane będą podlegały podejmowaniu
    zautomatyzowanych decyzji oraz będą podlegały profilowaniu w zakresie analizy ryzyka finansowego. Administrator na podstawie danych
    zadeklarowanych w dokumentach przedstawionych przez Usługobiorcę podczas dyspozycji przeprowadzenia transakcji dokonuje analizy ryzyka,
    w oparciu o ustalone kryteria w tym wartości transakcji, stanu zadłużenia oraz okresu prowadzenia działalności. Konsekwencja
    profilowania może skutkować niemożnością zawarcia GUS, US lub UP.
  </p>
  <p>
    Dane osobowe będą przechowywane do upływu okresu przedawnienia roszczeń wynikających z zawartej z Usługobiorcy GUS, US lub UP, w tym
    związanych z GUS, US lub UP roszczeń podatkowych. W zakresie, w jakim dane przetwarzane są w celu kierowania treści marketingowych, będą
    one przetwarzane do czasu wycofania zgody lub wniesienia sprzeciwu względem takiego przetwarzania. Usługobiorca ma prawo dostępu do
    treści swoich danych zgodnie z art. 15 RODO, prawo żądania sprostowania danych osobowych, usunięcia, ograniczenia przetwarzania zgodnie
    z art. 18 RODO, prawo do przenoszenia danych i prawo do wniesienia sprzeciwu wobec przetwarzania danych jak i również prawo do
    wniesienia skargi do organu nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych. Niepodanie danych osobowych uniemożliwia zawarcie
    GUS, US lub UP, oraz jej realizację. Podanie danych osobowych w celach marketingowych jest dobrowolne.
  </p>
  <p class="subtitle">XVIII. KONTAKT</p>
  <p>WeSub S.A.<br />ul. Adama Branickiego 15<br />02-972 Warszawa<br />Telefon: 515 925 444<br />Adres e-mail: biuro@wesub.pl</p>
  <p class="subtitle">XIX. POSTANOWIENIA KOŃCOWE</p>
  <p>
    GUS, US lub UP zawierane są w języku polskim. Niniejszy Regulamin dostępny jest nieodpłatnie na RentPay. Regulamin jest udostępniany w
    formie, która umożliwia pozyskanie, odtwarzanie i utrwalanie treści niniejszego Regulaminu za pomocą systemu teleinformatycznego, którym
    posługuje się Usługobiorca. O zmianach Regulaminu Usługobiorca zostanie poinformowany przez umieszczenie nowej treści Regulaminu na
    RentPay. Z tego względu rekomendujemy, aby przed każdym zawarciem GUS, US lub UP apoznać się z treścią Regulaminu. W przypadku dokonania
    zmiany niniejszego Regulaminu, wszystkie US lub UP i Zamówienia zawarte i złożone przed datą wejścia w życie zmiany Regulaminu będą
    realizowane zgodnie z Regulaminem w brzmieniu obowiązującym w dniu zawarcia US lub UP i złożenia Zamówienia przez Użytkownika. W
    sprawach nieuregulowanych zastosowanie mają przepisy Kodeksu Cywilnego. Wszelkie spory wynikające z korzystania z RentPay lub Umowy jak
    i wszelkie kwestie z nimi związane będą rozstrzygane przez sąd powszechny właściwy dla siedziby Usługodawcy lub Elektroniczne Centrum
    Arbitrażu i Mediacji Ultima RATIO - przy czym wybór sądu arbitrażowego albo sądu państwowego przez Powoda będzie miał dla Pozwanego
    charakter wiążący. Uznanie jakiegokolwiek postanowienia niniejszego Regulaminu za niezgodne z prawem, nieważne lub nieskuteczne nie ma
    wpływu na ważność lub skuteczność pozostałych postanowień Regulaminu, zaś w miejsce postanowienia uznanego za niezgodne z prawem,
    nieważne lub nieskuteczne stosuje się odpowiednie postanowienie, które będzie zgodne z prawem, ważne lub skuteczne, a które będzie
    najbardziej zbliżone do treści postanowienia uznanego za niezgodne z prawem, nieważne lub nieskuteczne.
  </p>
  <p>Niniejszy Regulamin wchodzi w życie w dniu 31.10.2022 r. i obowiązuje do GUS, US lub UP zawartych od tego dnia.</p>
</div>
`,
}

export { terms }
