import { memo } from 'react'

import { Avatar, Box, Typography } from '@mui/material'
import { PhoneNumber } from '@rent/ui'
import ConsultantImg from 'assets/images/consultant-profile.jpg'
import { ConsultantData } from 'consts'

const ConsultantInfo = memo(() => {
  const { name, phoneCode, phoneNo } = ConsultantData
  return (
    <Box display="flex">
      <Avatar alt="Consultant" src={ConsultantImg} sx={{ width: 56, height: 56 }} />
      <Box ml={1.6}>
        <Typography variant="body2" sx={{ mb: 0.4 }} color="text.secondary">
          {name}
        </Typography>
        <PhoneNumber phoneCode={phoneCode} phoneNo={phoneNo} />
      </Box>
    </Box>
  )
})

export default ConsultantInfo
