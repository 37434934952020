import ApiEndpoints from 'api/endpoints'
import { OnError, OnSuccess, useMutate } from 'hooks'
import { ICustomerOrder } from 'models'

export type AcceptContractBody = {
  finalRodoAgreementAccepted: boolean
  finalStatuteAccepted: boolean
  finalContrahentProcessingAccepted: boolean
  debitNote: boolean | null
  uuid: string
  buyerPhone: string
}

export const usePostAcceptContract = (onError: OnError, onSuccess: OnSuccess) => {
  return useMutate<ICustomerOrder>(ApiEndpoints.postAcceptContract(), {
    onError,
    onSuccess,
  })
}
