import { APP_ENV_TYPE } from 'config/appConstants'
import { COOKIE_RISK_TRACKER, COOKIE_USER_ID, STORAGE_SESSION_ID } from 'consts'

const storeOrderSummary = [
  // safeup
  'safeUp-radio',
  'safeUp-radio-expand',
  'safeUpPlus-radio',
  'safeUpPlus-radio-expand',

  // promocode
  'promocode',
  'promocode-expand',
  'promocode-button',

  // buttons
  'goToPersonalData-button',
]

const personalData = [
  'firstName',
  'lastName',
  'buyerEmail',
  'buyerPhone',
  'nip',

  // checkboxes
  'selectAll',

  'agreementRodo',
  'agreementRodo-expand',

  'agreementStatute',
  'agreementStatute-expand',

  'agreementKrd',
  'agreementKrd-expand',

  'agreementEmailCommunication',
  'agreementEmailCommunication-expand',

  'agreementPhoneCommunication',
  'agreementPhoneCommunication-expand',

  // buttons
  'goToAgreementGeneration-button',
]

const contractGeneration = [
  'nameAndLastName',
  'pesel',
  'identityCardNumber',
  'identityCardValidFrom',
  'identityCardValidFrom-openDatepicker',
  'identityCardValidTo',
  'identityCardValidTo-openDatepicker',

  'returnAccountNumber',

  // checkboxes
  'agreementAdministrator',
  'agreementAdministrator-expand',
  'agreementContractor',
  'agreementContractor-expand',

  'agreementAcceptance',
  'agreementAcceptance-expand',

  // buttons
  'downloadAgreement-button',
  'editAgreementFormData-button',
  'generateAgreement-button',
  'goToFinalization-button',
  // sms verification
  'smsVerificationSubmitCode-button',
  'smsVerificationResendCode-button',
  'smsVerificationChangePhoneNo-button',
]

const finalization = [
  // payment selection
  'autopay_business_account-radio',
  'autopay_others-radio',
  'debitNote-radio',

  // buttons
  'goBackToContractGeneration-button',
  'orderAndPay-button',
]

const infobox = [
  'contractForm-additional-expand',
  'whyWeNeedYourData-additional-expand',
  'whyItWorthToSubscribe-additional-expand',
  'howItWorks-additional-expand',
]

const links = ['terms-link', 'rodo-link', 'privacyPolicy-link', 'wesub-link', 'wesubDocuments-link']

export const trackerData = {
  CODES_V: '1.0',
  baseUrl: 'https://apii.rentup.pl',
  apiKey: 'hash',
  project: APP_ENV_TYPE === 'prod' ? 'risktracker_prod' : null,
  trackingCookie: COOKIE_RISK_TRACKER,
  userIdCookie: COOKIE_USER_ID,
  sessionId: STORAGE_SESSION_ID,
  collectType: [],
  collectID: [
    ...storeOrderSummary,
    ...personalData,
    ...contractGeneration,
    ...finalization,
    ...infobox,
    ...links,

    /**
     * others
     */
    'downloadAgreementTemplate-button',
  ],
  collectIDMapping: {
    /**
     * personal data
     */
    email: 'buyerEmail',
    phone: 'buyerPhone',
    rodoAgreementAccepted: 'agreementRodo',
    'rodoAgreementAccepted-expand': 'agreementRodo-expand',

    statuteAccepted: 'agreementStatute',
    statuteAcceptedExpand: 'agreementStatute-expand',

    krdAccepted: 'agreementKrd',
    'krdAccepted-expand': 'agreementKrd-expand',

    electronicProcessingAccepted: 'agreementEmailCommunication',
    'electronicProcessingAccepted-expand': 'agreementEmailCommunication-expand',

    phoneProcessingAccepted: 'agreementPhoneCommunication',
    'phoneProcessingAccepted-expand': 'agreementPhoneCommunication-expand',

    /**
     * contract generation
     */
    finalRodoAgreementAccepted: 'agreementAdministrator',
    'finalRodoAgreementAccepted-expand': 'agreementAdministrator-expand',

    finalContrahentProcessingAccepted: 'agreementContractor',
    'finalContrahentProcessingAccepted-expand': 'agreementContractor-expand',

    finalStatuteAccepted: 'agreementAcceptance',
    'finalStatuteAccepted-expand': 'agreementAcceptance-expand',
  },
  ignoreEvent: [], // ["click", "enter_page"],
  debug: false,
}
