import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { RadioAccordionGroup } from '@rent/ui'
import { Currency } from 'components/shared'
import { InsuranceType } from 'consts'
import { ISafeUpProvider } from 'models'

interface InsuranceSelectionProps {
  safeUpProvider: ISafeUpProvider
  safeupPlusPrice: number | null
  safeupPlusTaken: boolean
  handleSetSafeUpPlus: (safeupPlusTaken: boolean) => void
}

const InsuranceSelection = ({ safeUpProvider, safeupPlusPrice, safeupPlusTaken, handleSetSafeUpPlus }: InsuranceSelectionProps) => {
  const intl = useIntl()

  const insuranceData = useMemo(() => {
    const insurances: {
      title: string
      subtitle: string
      key: string
      id: string
      content: React.ReactNode
      rightText: React.ReactNode
    }[] = [
      {
        title: intl.formatMessage({ id: 'insurance_basic' }),
        subtitle: intl.formatMessage({ id: 'insurance_basic_subtitle' }),
        content: safeUpProvider?.content,
        rightText: intl.formatMessage({ id: 'in_price' }),
        key: InsuranceType.SAFE_UP,
        id: InsuranceType.SAFE_UP + '-radio',
      },
    ]
    if (safeupPlusPrice) {
      const safeupPlus = {
        title: intl.formatMessage({ id: 'insurance_extended' }),
        subtitle: intl.formatMessage({ id: 'insurance_extended_subtitle' }),
        content: safeUpProvider?.safeUpPlusContent,
        rightText: <Currency value={safeupPlusPrice} />,
        key: InsuranceType.SAFE_UP_PLUS,
        id: InsuranceType.SAFE_UP_PLUS + '-radio',
      }
      insurances.push(safeupPlus)
    }
    return insurances
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safeUpProvider?.content, safeUpProvider?.safeUpPlusContent, safeupPlusPrice])

  const handleChange = (val: string) => {
    handleSetSafeUpPlus(val === InsuranceType.SAFE_UP_PLUS)
  }

  return (
    <RadioAccordionGroup
      items={insuranceData}
      sx={{ '&:not(:last-child)': { mb: 1 } }}
      selectedValue={safeupPlusTaken ? InsuranceType.SAFE_UP_PLUS : InsuranceType.SAFE_UP}
      onChange={handleChange}
    />
  )
}

export default InsuranceSelection
