import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Box, Typography, TypographyVariant } from '@mui/material'
import { calcProductPrice } from 'helpers'
import { IMappedProduct } from 'models'

import { gridItemStyle } from '../styles'

interface ProductPricesProps {
  product: IMappedProduct
}

const ProductPrices = ({ product }: ProductPricesProps) => {
  const { formatMessage } = useIntl()

  const calculatedProduct = useMemo(() => {
    return calcProductPrice(product)
  }, [product])

  const currency = (variant: TypographyVariant) => (
    <Typography variant={variant} component="span">
      {formatMessage({ id: 'net' })}/{formatMessage({ id: 'mth' })}
    </Typography>
  )

  return (
    <>
      <Typography variant="h6" sx={gridItemStyle}>
        {calculatedProduct.quantity}{' '}
        <Typography variant="body2" component="span">
          {formatMessage({ id: 'pcs' })}
        </Typography>
      </Typography>
      <Typography variant="h6" component="div" sx={gridItemStyle}>
        {calculatedProduct.discount || calculatedProduct.discountAmount ? (
          <Box>
            {/* original price */}
            <Typography variant="body1" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
              {calculatedProduct.totalPrice.toFixed(2)} {formatMessage({ id: 'PLN' })} {currency('body2')}
            </Typography>

            {/* price with promo code */}
            <Typography variant="inherit" color="primary.main">
              {calculatedProduct.totalPriceWithDiscount.toFixed(2)} {formatMessage({ id: 'PLN' })} {currency('body2')}
            </Typography>
          </Box>
        ) : (
          <>
            {/* original price */}
            {calculatedProduct.totalPrice.toFixed(2)} {formatMessage({ id: 'PLN' })} {currency('body2')}
          </>
        )}
      </Typography>
    </>
  )
}

export default ProductPrices
