import { button } from './button'
import { general } from './general'
import { message } from './message'
import { notification } from './notification'

const messages_en = {
  ...general,
  button,
  notification,
  message,
}

export default messages_en
