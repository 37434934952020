import ApiEndpoints from 'api/endpoints'
import dayjs from 'dayjs'
import { OnError, OnSuccess, useMutate } from 'hooks'
import { IAgreementResponse, ICustomerOrder } from 'models'

import { ContractGenerationValidationSchema } from '../ContractGeneration/consts'

export const usePostGenerateOrderContract = (onError: OnError, onSuccess: OnSuccess) => {
  return useMutate<IAgreementResponse>(ApiEndpoints.postGenerateOrderContract(), {
    onError,
    onSuccess,
  })
}

export const prepareGenerateOrderContractData = (
  formData: ContractGenerationValidationSchema,
  originalOrderProducts: ICustomerOrder | null | undefined,
) => {
  const data = {
    uuid: originalOrderProducts?.externalId,
    pesel: formData.pesel,
    nip: originalOrderProducts?.nip,
    email: originalOrderProducts?.buyerEmail,
    phoneNumber: originalOrderProducts?.buyerPhone,
    identityCardNumber: formData.identityCardNumber,
    identityCardValidFrom: dayjs(formData.identityCardValidFrom).format('YYYY-MM-DD'),
    identityCardValidTo: dayjs(formData.identityCardValidTo).format('YYYY-MM-DD'),
    nameAndLastName: formData.nameAndLastName,
    returnAccountNumber: formData.returnAccountNumber,
    identityCardValidIndefinitely: false,
    customerOrderType: 'B2B',
  }
  return data
}
