import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { AcceptContractBody, useGetBlueMediaLink, usePostAcceptContract } from 'components/pages/api'
import { GeneralRoute, RouteQueryParam, RouteQueryParamPaymentType, clearStorage } from 'consts'
import { NotificationsContext, notifyClose, notifyError, notifyLoading } from 'context'

const timerMinutes = 2

type ContractAcceptanceLoading = {
  timerMinutes?: number
  timeIsOver?: boolean
  longWaiting?: boolean
}

interface AcceptContract {
  externalId: string
  isDebitNoteSelected: boolean
  extension: boolean
  buyerPhone: string
}

export const useAcceptContract = ({ externalId, isDebitNoteSelected, extension, buyerPhone }: AcceptContract) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { dispatchNotification } = useContext(NotificationsContext)

  const [contractAcceptanceLoading, setContractAcceptanceLoading] = useState<ContractAcceptanceLoading | null>(null)

  const intervalIDRef = useRef<any>(null)

  const {
    data: autoPayLinkData,
    isLoading: autoPayLinkIsLoading,
    isSuccess: autoPayLinkIsSuccess,
    isError: autoPayLinkIsError,
    refetch: refetchGetBlueMediaLink,
  } = useGetBlueMediaLink(externalId, { enabled: false })

  const redirectToOrderFinishedScreen = useCallback(
    (paymentType: RouteQueryParamPaymentType) => {
      // clear interval
      clearInterval(intervalIDRef.current)
      intervalIDRef.current = null
      // clear session storage
      clearStorage()

      // redirect
      setTimeout(() => {
        if (paymentType === RouteQueryParamPaymentType.AUTOPAY_LINK_GENERATION_SUCCESS) {
          window.location.replace(autoPayLinkData?.blueMediaPaymentLink as string)
        } else {
          navigate({
            pathname: `/${GeneralRoute.ORDER}/${externalId}/${GeneralRoute.ORDER_FINISHED_CONFIRMATION}`,
            search: `?${RouteQueryParam.ORDER_FINISHED_STATUS}=${paymentType}`,
          })
        }
      }, 500)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [externalId, autoPayLinkData],
  )

  const handleSuccessAcceptContract = () => {
    if (extension) {
      redirectToOrderFinishedScreen(RouteQueryParamPaymentType.EXTENSION_SUCCESS)
    } else if (isDebitNoteSelected) {
      redirectToOrderFinishedScreen(RouteQueryParamPaymentType.DEBIT_NOTE_SUCCESS)
    } else {
      // * for Rent Dealer before calling refetchGetBlueMediaLink /orders/blue/media/type/{uuid} is called and checked
      refetchGetBlueMediaLink()
    }
  }
  const handleErrorAcceptContract = () => {
    notifyError(dispatchNotification, {
      title: formatMessage({ id: 'notification.dear_customer' }),
      content: formatMessage({ id: 'notification.accept_contract_default_error' }),
    })
  }

  useEffect(() => {
    if (externalId) {
      // check blue media get link output
      if (autoPayLinkIsSuccess && autoPayLinkData?.blueMediaPaymentLink) {
        setContractAcceptanceLoading({ ...contractAcceptanceLoading, timeIsOver: true })
        redirectToOrderFinishedScreen(RouteQueryParamPaymentType.AUTOPAY_LINK_GENERATION_SUCCESS)
      }
      // if riskReportRed is true
      else if (autoPayLinkIsSuccess && autoPayLinkData?.riskReportRed) {
        setContractAcceptanceLoading({ ...contractAcceptanceLoading, timeIsOver: true })
        redirectToOrderFinishedScreen(RouteQueryParamPaymentType.AUTOPAY_LINK_GENERATION_OUT_OF_TIME)
      }
      // if output is not correct call retry in loop
      else if (autoPayLinkIsSuccess && !autoPayLinkData?.blueMediaPaymentLink) {
        notifyClose(dispatchNotification)
        checkBlueMediaLinkGeneratedCounter()
      }
      // if error occurred
      else if (autoPayLinkIsError && !autoPayLinkIsSuccess && !autoPayLinkIsLoading) {
        redirectToOrderFinishedScreen(RouteQueryParamPaymentType.AUTOPAY_LINK_GENERATION_ERROR)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalId, autoPayLinkData, autoPayLinkIsSuccess, autoPayLinkIsError, autoPayLinkIsLoading])

  useEffect(() => {
    return () => {
      if (externalId && intervalIDRef.current) {
        clearInterval(intervalIDRef.current)
      }
    }
  }, [externalId])

  const checkBlueMediaLinkGeneratedCounter = useCallback(() => {
    setContractAcceptanceLoading({ timerMinutes })
    let currentStep = 0
    const intervalTime = 5000
    const timerValue = timerMinutes * 60 * 1000 * 0.94
    const steps = Math.ceil(timerValue / intervalTime)
    intervalIDRef.current = setInterval(() => {
      currentStep += 1

      if (currentStep > steps) {
        setContractAcceptanceLoading(prev => ({ ...prev, timeIsOver: true }))
        redirectToOrderFinishedScreen(RouteQueryParamPaymentType.AUTOPAY_LINK_GENERATION_OUT_OF_TIME)
      } else {
        if (currentStep > steps - steps / 4) {
          setContractAcceptanceLoading(prev => ({ ...prev, longWaiting: true }))
        }
        refetchGetBlueMediaLink()
      }
    }, intervalTime)
  }, [redirectToOrderFinishedScreen, refetchGetBlueMediaLink])

  const postAcceptContract = usePostAcceptContract(handleErrorAcceptContract, handleSuccessAcceptContract)

  const onSubmitAcceptContract = async () => {
    notifyLoading(dispatchNotification)

    const body: AcceptContractBody = {
      finalRodoAgreementAccepted: true,
      finalStatuteAccepted: true,
      finalContrahentProcessingAccepted: true,
      debitNote: isDebitNoteSelected,
      uuid: externalId,
      buyerPhone,
    }
    await postAcceptContract.mutate(body)
  }

  return { onSubmitAcceptContract, contractAcceptanceLoading, setContractAcceptanceLoading }
}
