import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import { Button } from '@rent/ui'
import { ReactComponent as SadEmojiIcon } from 'assets/icons/SadEmoji.svg'
import { LayoutWithHeader } from 'components/layouts'

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  height: 'calc(100% - 3rem)',
}

export default function OrderCanceledPage() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  return (
    <>
      <Helmet>
        <title>RentPay - {formatMessage({ id: 'notification.order_not_found' })}</title>
      </Helmet>
      <LayoutWithHeader noBgr>
        <Box sx={containerStyle}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box color="grey.200" mb={4}>
              <SadEmojiIcon fill="inherit" />
            </Box>

            <Typography variant="h3" mb={2.4}>
              {formatMessage({ id: 'notification.order_not_found' })}
            </Typography>

            <Typography color="text.secondary" mb={4.8} sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
              {formatMessage({ id: 'notification.order_cancelled' })}
            </Typography>

            <Button onClick={() => navigate(-1)}>{formatMessage({ id: 'button.go_back' })}</Button>
          </Box>
        </Box>
      </LayoutWithHeader>
    </>
  )
}
