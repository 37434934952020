import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { offerProviderBoxBoxStyle } from './styles'

export const OfferProviderBox = () => {
  const intl = useIntl()
  return (
    <Box sx={offerProviderBoxBoxStyle}>
      <Typography variant="h5">{intl.formatMessage({ id: 'summary' })}</Typography>
    </Box>
  )
}

export default OfferProviderBox
