import { SxProps, Theme } from '@mui/material'

export const bannerStyle: SxProps<Theme> = {
  bgcolor: 'grey.800',
  p: 1.8,
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
}
