import { Dispatch, createContext, useCallback, useEffect, useState } from 'react'

import { PaymentType } from 'consts'
import { BranchType } from 'consts/branchType'
import { ICustomerOrder } from 'models'

const initData = { isLoan: false, isExtension: false, externalId: '' }

export type SelectedPaymentType = PaymentType.AUTOPAY_BUSINESS_ACCOUNT | PaymentType.DEBIT_NOTE | null

export const OrderContext = createContext({
  order: null,
  setOrder: () => {},
  contextData: initData,

  selectedPayment: null,
  handleChangesSelectedPayment: () => {},
} as {
  order: ICustomerOrder | null
  setOrder: Dispatch<React.SetStateAction<ICustomerOrder | null>>
  contextData: { isLoan: boolean; isExtension: boolean; externalId: string }

  selectedPayment: SelectedPaymentType
  handleChangesSelectedPayment: (val: SelectedPaymentType) => void
})

export const OrderContextProvider = ({ children }: any) => {
  const [order, setOrder] = useState<ICustomerOrder | null>(null)
  const [data, setData] = useState(initData)
  const [selectedPayment, setSelectedPayment] = useState<SelectedPaymentType>(null)

  useEffect(() => {
    if (order) {
      setData({ isLoan: order?.branch === BranchType.CREDIT, isExtension: Boolean(order.extension), externalId: String(order.externalId) })
    } else {
      setData(initData)
      setSelectedPayment(null)
    }
  }, [order])

  const handleChangesSelectedPayment = useCallback((val: SelectedPaymentType) => {
    setSelectedPayment(val)
  }, [])

  return (
    <OrderContext.Provider
      value={{
        order,
        setOrder,
        contextData: data,

        selectedPayment,
        handleChangesSelectedPayment,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}
