import { button } from './button'
import { customer } from './customer'
import { form } from './form'
import { general } from './general'
import { message } from './message'
import { notification } from './notification'
import { payment } from './payment'
import { privacy } from './privacy'
import { terms } from './terms'

const messages_pl = {
  ...general,
  button,
  notification,
  message,
  customer,
  form,
  payment,
  terms,
  privacy,
}

export default messages_pl
