import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import axios from 'axios'
import { v4 as uuid } from 'uuid'

interface RiskTrackerProps {
  baseUrl: string
  apiKey: string
  project?: string | null
  trackingCookie: string
  userIdCookie?: string
  sessionId?: string
  collectID?: string[]
  collectType?: string[]
  ignoreEvent?: string[]
  collectIDMapping?: { [key: string]: string }
  debug?: boolean
}

const allowedTagNames = ['INPUT', 'BUTTON', 'A', 'svg']

export const useRiskTracker = (props: RiskTrackerProps) => {
  const {
    baseUrl,
    apiKey,
    project,
    trackingCookie,
    userIdCookie = 'user_id',
    sessionId = 'session_id',
    collectID = [],
    collectType = [],
    ignoreEvent = [],
    collectIDMapping,
  } = props

  const [cookies, setCookie] = useCookies([trackingCookie, userIdCookie])

  const [timePressedKeys, setTimePressedKeys] = useState<{ [key: number]: number }>({})
  const [timeBetweenPresses, setTimeBetweenPresses] = useState<{ [key: number]: number }>({})
  const [lastPressDate, setLastPressDate] = useState<number | null>(null)

  const setExpireDate = (month: number) => {
    const currentDate = new Date()
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + month,
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds(),
    )
  }

  function checkIgnoreEvent(eventToIgnore: string) {
    return ignoreEvent.includes(eventToIgnore)
  }
  function println(_msg: any) {
    // console.log(msg)
  }

  function canICollectThis(event: any) {
    if (!event || !event.target) {
      return true
    }

    if (!collectType.includes(event.target.type) && !collectID.includes(event.target.id)) {
      println('Ignoring event for type: ' + event.target.type + ' and id: ' + event.target.id)
      return false
    }

    return true
  }

  function sendRequest(url: any, method: any, data: any, callback: any, errback: any) {
    axios({ method, url, data }).then(callback).catch(errback)
  }

  function responseHandler(response: any) {
    println('Correct. Response: ' + response)
  }
  function errorHandler(response: any) {
    println('Error. Response: ' + response)
  }

  function sendData(eventType: any, args: any) {
    if (!apiKey) console.warn('Wrong API key provided')

    const url = baseUrl + '/' + eventType
    const payload = args.data

    sendRequest(url, 'POST', payload, responseHandler, errorHandler)
  }

  const mapElementId = (targetId: any) => {
    if (collectIDMapping) {
      const elFound = Object.entries(collectIDMapping).find(([, val]: any) => {
        return val === targetId
      })
      return elFound ? elFound[0] : targetId
    } else return targetId
  }

  useEffect(() => {
    const clickHandler = function (event: any) {
      if (!canICollectThis(event)) {
        return
      }
      if (allowedTagNames.includes(event.target.tagName)) {
        sendData('click_event', {
          data: {
            user_id: cookies[userIdCookie],
            session_id: sessionStorage.getItem(sessionId),
            element_id: mapElementId(event.target.id),
            url: location.href,
          },
        })
      }
    }

    function trackerFunction() {
      // setting cookies
      if (!cookies[userIdCookie]) {
        println(`Setting ${userIdCookie}`)

        const expires = setExpireDate(6)
        setCookie(userIdCookie, uuid(), { expires, path: '/' })
      }
      if (!cookies[trackingCookie]) {
        println(`Setting ${trackingCookie}`)
        const expires = setExpireDate(6)
        setCookie(trackingCookie, true, { expires, path: '/' })
      }
      // setting session_id
      if (!sessionStorage.getItem(sessionId)) {
        println(`Setting ${sessionId}`)
        sessionStorage.setItem(sessionId, uuid())
      }

      // click event definition
      if (!checkIgnoreEvent('click')) {
        window.addEventListener('click', clickHandler)
      }

      // Enter page event definition
      if (!checkIgnoreEvent('enter_page') && cookies[userIdCookie]) {
        sendData('enter_page_event', {
          data: {
            user_id: cookies[userIdCookie],
            session_id: sessionStorage.getItem(sessionId),
            url: window.location.href,
            referrer: document.referrer,
            screen_h: screen.height,
            screen_w: screen.width,
            project: project,
            language: navigator.language,
            user_agent: navigator.userAgent,
            cookies: document.cookie,
          },
        })
      }
    }

    // Call the tracker function
    if (baseUrl && project) {
      trackerFunction()
    }

    // Cleanup function if needed
    return () => {
      window.removeEventListener('click', clickHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUrl, project, sessionId, trackingCookie, userIdCookie, cookies])

  const inputHandler = function (e: any) {
    if (!canICollectThis(e)) {
      return
    }
    if (e.type == 'keyup') {
      const data = {
        user_id: cookies[userIdCookie],
        session_id: sessionStorage.getItem(sessionId),
        element_id: mapElementId(e.target.id),
        key: e.code ?? '',
        value: e.target.value ?? '',
        url: location.href,
        time_pressed: new Date().valueOf() - timePressedKeys[e.keyCode],
        time_between_presses: timeBetweenPresses[e.keyCode],
      }
      sendData('input_event', { data })

      setTimePressedKeys({ ...timePressedKeys, [e.keyCode]: 0 })
    }
    if (e.type == 'keydown') {
      setTimePressedKeys({ ...timePressedKeys, [e.keyCode]: Number(new Date().getTime()) })
      setTimeBetweenPresses({
        ...timeBetweenPresses,
        [e.keyCode]: lastPressDate ? new Date().valueOf() - lastPressDate : 0,
      })
      setLastPressDate(new Date().valueOf())
    }
  }

  useEffect(() => {
    // Key press event definition
    if (project && !checkIgnoreEvent('input')) {
      window.addEventListener('keyup', inputHandler)
      window.addEventListener('keydown', inputHandler)
    }

    return () => {
      window.removeEventListener('keyup', inputHandler)
      window.removeEventListener('keydown', inputHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, lastPressDate, timeBetweenPresses, timePressedKeys, location.href])

  return {}
}
