import { memo } from 'react'
import { useIntl } from 'react-intl'

import { Paper, SxProps, Theme, Typography } from '@mui/material'
import { MainAccordion } from '@rent/ui'

import { StyledIDivider, containerSpacings } from '../styledComponents'
import ConsultantInfo from './ConsultantInfo'
import { getAccordionTexts } from './accordionTexts'

const containerStyle: SxProps<Theme> = {
  bgcolor: 'background.default',
  ...containerSpacings,
}
interface HelpBlockProps {
  isLoan?: boolean
}

const HelpBlock = memo(({ isLoan }: HelpBlockProps) => {
  const { formatMessage } = useIntl()
  const accordions = getAccordionTexts(formatMessage)

  return (
    <Paper sx={containerStyle}>
      {/* title */}
      <Typography variant="h5">{formatMessage({ id: 'need_help' })}</Typography>
      <StyledIDivider />

      {/* consultant info */}
      <ConsultantInfo />
      {!isLoan && (
        <>
          <StyledIDivider />

          {/* accordions */}
          {accordions.map((el, idx) => (
            <MainAccordion key={el.title} id={el.id} title={el.title} sx={{ mb: idx !== accordions.length - 1 ? { xs: 1, md: 1.6 } : 0 }}>
              {/* pre-line is needed to format the generated texts */}
              <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
                {el.content}
              </Typography>
            </MainAccordion>
          ))}
        </>
      )}
    </Paper>
  )
})

export default HelpBlock
