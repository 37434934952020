import { SxProps, Theme } from '@mui/material'

export const containerStyle: SxProps<Theme> = ({ typography }) => ({
  'p, li, a': {
    ...typography.body1,
    color: 'text.primary',
  },
  a: {
    color: 'primary.main',
    textDecoration: 'none',
  },
  '.font-bold': {
    fontWeight: 700,
  },
  '.subtitle': {
    ...typography.subtitle1,
    textTransform: 'uppercase',
    mt: 3,
  },
  '.fancy-font': {
    fontWeight: 600,
    bgcolor: 'grey.100',
    px: 0.4,
    py: 0.2,
  },
})
