import { ReactNode, useContext } from 'react'
import { useIntl } from 'react-intl'

import { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { AdditionalInfoBlock } from 'components/shared'
import { OrderContext } from 'context'

const containerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  mb: { xs: 2.4, md: 3.2 },
}

const leftSideContainerStyle: SxProps<Theme> = {
  flexBasis: { xs: '100%', md: '70%' },
  mr: { md: 2.4 },
  mb: 1.6,
}
const mainPaperStyle: SxProps<Theme> = {
  mb: 1.2,
  py: { xs: 2.4, md: 4.8 },
  px: { xs: 1.2, md: 3.2 },
}
const additionalPaperStyle: SxProps<Theme> = {
  mb: 1.2,
  p: { xs: 1.6, md: 3.2 },
}

const rightSideContainerStyle: SxProps<Theme> = {
  mb: 1.6,
  flexBasis: { xs: '100%', md: '30%' },
}

interface StepContentLayoutProps {
  children: ReactNode
  rightSide?: ReactNode
}

const StepContentLayout = ({ children, rightSide }: StepContentLayoutProps) => {
  const { formatMessage } = useIntl()
  const { contextData } = useContext(OrderContext)

  return (
    <Box sx={containerStyle}>
      <Box sx={leftSideContainerStyle}>
        <Paper sx={mainPaperStyle}>{children}</Paper>
        {!contextData.isLoan && (
          <Paper sx={[additionalPaperStyle, { display: { xs: 'none', sm: 'flex' } }]}>
            <AdditionalInfoBlock />
          </Paper>
        )}
        {!contextData.isExtension && (
          <Paper sx={[additionalPaperStyle, { display: { xs: 'none', sm: 'flex' } }]}>
            <Typography color="text.secondary" variant="body2" sx={{ pt: { xs: 1.6, md: 0 } }}>
              {formatMessage({ id: contextData.isLoan ? 'message.loan_preparation_info' : 'message.offer_preparation_explanation' })}
            </Typography>
          </Paper>
        )}
      </Box>
      <Box sx={rightSideContainerStyle}>
        <Box>
          {rightSide}
          {!contextData.isLoan && (
            <Paper sx={[additionalPaperStyle, { display: { xs: 'flex', sm: 'none' } }]}>
              <AdditionalInfoBlock />
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default StepContentLayout
