import React, { ComponentType } from 'react'
import { IntlProvider } from 'react-intl'

import { flatten } from 'flat'
import messages from 'intl'

function withIntl<T extends object>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const i18nConfig = {
      locale: 'pl',
      messages: messages.pl,
    }
    return (
      <IntlProvider messages={flatten(i18nConfig.messages)} locale={i18nConfig.locale} defaultLocale="pl">
        <WrappedComponent {...props} />
      </IntlProvider>
    )
  }
}
export { withIntl }
