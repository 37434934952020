import ApiEndpoints from 'api/endpoints'
import { useRequest } from 'hooks'
import { ICustomerOrder } from 'models'

/**
 * The function `useGetCustomerOrder` is a custom hook that makes a request to retrieve a customer
 * order based on the provided order ID.
 */
export const useGetCustomerOrder = (orderId: string) => {
  return useRequest<ICustomerOrder>(ApiEndpoints.getOrder(orderId), { enabled: !!orderId, retry: 2 })
}
