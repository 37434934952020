const privacy = {
  title: 'POLITYKA PRYWATNOŚCI i PLIKÓW COOKIES',
  content: `
  <div>
  <p class="subtitle">I. Bezpieczeństwo informacji</p>
    <p>
        Bardzo poważnie traktujemy bezpieczeństwo danych. Dokładamy wszelkich starań by chronić dane osobowe przed
        nieuprawnionym dostępem osób trzecich. WeSub S.A. szanuje prawo do prywatności Użytkowników i
        gwarantuje im prawo wyboru w zakresie udostępniania informacji, które ich dotyczą. Dokładamy najwyższych starań,
        by dane osobowe naszych Użytkowników były przetwarzane zgodnie z obowiązującymi przepisami prawa polskiego u
        Unii Europejskiej. Nasza Polityka Prywatności i Plików Cookies informuje, jak przetwarzamy dane osobowe
        Użytkowników naszych usług, a przede wszystkim kto jest administratorem danych osobowych, jakie dane
        przetwarzamy, w jakim celu i na jakiej podstawie przetwarzamy dane, jak długo przetwarzamy dane, jakie prawa
        przysługują Użytkownikom, komu udostępniamy dane.
    </p>
    <p class="subtitle">II. Administrator Danych Osobowych</p>
    <p>
        Administratorem danych osobowych dotyczących Użytkownika/Dzierżawcy oraz osób zaangażowanych po jego stronie w
        realizację procesu zawarcia Umowy, a następnie w jej realizacji jest WeSub S.A. z siedzibą w Warszawie
        przy ul. Branickiego 15.
    </p>
    <p class="subtitle">III. Na jakiej podstawie przetwarzamy dane osobowe oraz jakie prawa przysługują Użytkownikom?</p>
    <p>
        Podstawa prawna pozwalająca nam na przetwarzanie danych Użytkownika zależy od celu ich przetwarzania.
        Przetwarzanie
        danych Użytkownika jest konieczne do spełnienia warunków regulujących korzystanie z Platformy internetowej jak
        również jest niezbędne do wykonania Umowy i świadczenia wszelkich usług. Uważamy, że posiadamy prawnie
        uzasadniony
        interes co do dokonywania niezbędnych weryfikacji, celem wykrywania i zapobiegania potencjalnym nadużyciom lub
        niezgodnego z prawem wykorzystywania naszej Platformy internetowej. Uważamy, że posiadamy również prawnie
        uzasadniony interes do obsługi wniosków i zapytań formułowanych przez Użytkowników za pośrednictwem różnych
        dostępnych form kontaktu. Gdy Użytkownik kontaktuje się z nami w związku z produktem/usługą lub zdarzeniami
        związanymi z Umową, przetwarzanie danych jest konieczne do realizacji Umowy. W odniesieniu do działań
        marketingowych
        zwracamy uwagę, iż podstawą prawną do przetwarzania danych Użytkownika w celach marketingowych jest jego zgoda.
    </p>
    W związku z powyższym, wskazujemy, iż dane osobowe przetwarzane są w celu prawidłowej obsługi Użytkowników, w tym
    udzielania odpowiedzi na kierowane pytania na podstawie art. 6 ust. 1 pkt f RODO, realizacji umów na podstawie art.
    6 ust.1 pkt b) RODO oraz dane osobowe będą przetwarzane na podstawie art. 6 ust.1 pkt c) RODO oraz art. 6 ust.1 pkt
    f) RODO. Podstawą przetwarzania danych osobowych jest zawarcie i wykonanie Umowy. Dane osobowe będą udostępniane
    wyłącznie podmiotom, które biorą udział w procesie związanym z realizacją Umowy jak i również innym podmiotom,
    którym obowiązek przekazania danych osobowych będzie wynikał z przepisów prawa. Podane dane osobowe nie będą
    podstawą podejmowania zautomatyzowanych decyzji ani nie będą podlegały profilowaniu. Dane osobowe będą przechowywane
    do upływu okresu przedawnienia roszczeń wynikających z zawartej z Dzierżawcą Umowy, w tym związanych z Umową
    roszczeń podatkowych. W zakresie, w jakim dane przetwarzane są w celu kierowania treści marketingowych, będą one
    przetwarzane do czasu wycofania zgody lub wniesienia sprzeciwu względem takiego przetwarzania. Użytkownik/Dzierżawca
    prawo dostępu do treści swoich danych zgodnie z art. 15 RODO, prawo żądania sprostowania danych osobowych,
    usunięcia, ograniczenia przetwarzania zgodnie z art. 18 RODO, prawo do przenoszenia danych i prawo do wniesienia
    sprzeciwu wobec przetwarzania danych jak i również prawo do wniesienia skargi do organu nadzorczego, tj. Prezesa
    Urzędu Ochrony Danych Osobowych. Niepodanie danych osobowych uniemożliwia zawarcie Umowy oraz jej realizację.
    Podanie danych osobowych w celach marketingowych jest dobrowolne.
    <p>
        Wskazujemy, iż Użytkownik ma prawo do wycofania zgody - niemniej jednak wycofanie zgody nie ma wpływu na
        zgodność z
        prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. W celu realizacji powyższych
        praw
        należy skontaktować się nami poprzez kontakt mailowy na adres: <a href="mailto:m.ryzop@dpag.pl">m.ryzop@dpag.pl</a> lub tradycyjnie za pośrednictwem
        drogi
        pocztowej na adres: WeSub S.A. z siedzibą w Warszawie przy ulicy Branickiego 15 (02-072 Warszawa).
    </p>

    <p class="subtitle">IV. W jakim celu przetwarzamy dane osobowe?</p>
    <p>
        W zależności od usługi lub funkcji, z której każdorazowo Użytkownik będzie chciał skorzystać, będziemy
        potrzebowali
        różnych danych, które na ogół, w zależności od sytuacji obejmują następujące informacje:
    </p>
    <ul>
        <li>dane umożliwiające identyfikację Użytkownika (np.: imię, nazwisko, dane kontaktowe, itd.)
        <li>informacje finansowe i transakcyjne
        <li>dane dotyczące połączenia, przeglądania stron internetowych
        <li>informacje handlowe
        <li>dane dotyczące upodobań i preferencji Użytkownika.
    </ul>
    <p>
        Prosimy pamiętać, że jeżeli prosimy Użytkownika o podanie jego danych osobowych, w celu udzielenia mu dostępu do
        którejkolwiek z naszej funkcji lub usługi, niektóre z pól zaznaczone będą jako obowiązkowe do wypełnienia,
        ponieważ
        są to dane, które będą niezbędne do świadczenia usługi. Udostępnienie i podanie danych osobowych jest
        dobrowolne,
        aczkolwiek niezbędne do możliwości skorzystania z naszych usług lub funkcji.
    </p>
    <p class="subtitle">V. Komu udostępniamy dane?</p>
    <p>
        Dane mogą być przekazywane podmiotom przetwarzającym dane w imieniu Administratora, m.in.: dostawcom usług
        technologicznych i analitycznych, instytucjom finansowym, dostawcom i partnerom w świadczeniu usług
        logistycznych,
        transportowych i doręczeniowych i/lub współpracujących z nimi firmom, podmiotom świadczącym na naszą rzecz
        usługi
        wsparcia, w tym usługi księgowe i kadrowe. Poza wymienionymi podmiotami, dane osobowe mogą zostać udostępnione
        wyłącznie na żądanie podmiotów upoważnionych do tego na podstawie przepisów prawa.
    </p>
    <p class="subtitle">VI. Przez jaki okres przechowujemy dane?</p>
    <p>
        Okres przechowywania danych zależy od celów do jakich będą one przetwarzane, niemniej jednak w przypadku, gdy
        podstawą prawną przetwarzania danych osobowych jest zgoda Użytkownika, wówczas dane osobowe przetwarzane są tak
        długo, aż zgoda nie zostanie odwołana, a po odwołaniu zgody przez okres czasu odpowiadający okresowi
        przedawnienia
        roszczeń. W przypadku, gdy podstawą przetwarzania danych jest wykonanie Umowy, wówczas dane osobowe przetwarzane
        są
        tak długo jak jest to niezbędne do wykonania Umowy, a po tym czasie przez okres odpowiadający okresowi
        przedawnienia
        roszczeń.
    </p>
    <p class="subtitle">VII. Czym jest plik cookies?</p>
    <p>
        Cookies to mały plik tekstowy, zapisywany przez witrynę internetową na komputerze, telefonie lub jakimkolwiek
        innym
        urządzeniu Użytkownika, zawierający dane na temat korzystania Użytkownika z tej strony. Cookies są potrzebne, w
        celu
        ułatwienia korzystania z witryny i sprawienia by stała się ona bardziej przyjazna Użytkownikowi. Cookies
        pomagają
        nam przykładowo w identyfikacji Użytkownika poprzez zapamiętywanie jego preferencji (język, kraj, itd.)
    </p>
    <p>
        Pliki cookies zbierają dane dotyczące korzystania z Formularza, a ich głównym celem jest ułatwienie korzystania
        z
        Formularza, dostosowanie Platformy internetowej do potrzeb i oczekiwań danego Użytkownika/Dzierżawcy, który
        korzysta
        z Formularza albo Platformy internetowej. Stanowi to personalizację podstron Platformy internetowej oraz badanie
        ruchu Użytkowników. Pliki cookies są wykorzystywane na Platformie internetowej za zgodą Użytkownika/Dzierżawcy.
        Zgoda może być wyrażona przez Użytkownika/Dzierżawcę poprzez odpowiednie ustawienia oprogramowania, w
        szczególności
        przeglądarki internetowej, zainstalowanego w urządzeniu telekomunikacyjnym wykorzystywanym przez
        Użytkownika/Dzierżawcę do przeglądania zawartości Platformy internetowej. Użytkownik/Dzierżawca może również w
        każdym czasie ograniczyć lub wyłączyć cookies w swojej przeglądarce przez takie jej ustawienie, aby blokowała
        cookies lub ostrzegała Użytkownika/Dzierżawcę przed zapisaniem pliku cookies na urządzeniu, z którego korzysta
        do
        przeglądania Platformy internetowej lub Formularza. W takim przypadku może się jednak zdarzyć, że korzystanie z
        Formularza będzie mniej wydajne, a w pewnych przypadkach nawet niemożliwe. Na naszej stronie internetowej
        używamy
        plików cookies, które zawierają informacje pozwalające nam dostosować treści do Twoich potrzeb, usprawnić
        działanie
        naszej strony internetowej oraz analizować ruch na naszej stronie internetowej.
    </p>
    <p class="subtitle">VIII. Zmiany Polityki Prywatności i Plików Cookies</p>
    <p>
        Informacje zawarte w niniejszej Polityce Prywatności i Plików Cookies mogą ulec zmianie wedle naszego uznania. Z
        tego względu rekomendujemy, aby za każdym razem zapoznać się z treścią Polityki Prywatności i Plików Cookies.
    </p>
</div>
`,
}

export { privacy }
