import { useContext, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useRouteError } from 'react-router-dom'

import { Box, Link, Typography } from '@mui/material'
import { Button } from '@rent/ui'
import { ReactComponent as SadEmojiIcon } from 'assets/icons/SadEmoji.svg'
import { LayoutWithHeader } from 'components/layouts'
import { ConsultantData, ErrorCodeType } from 'consts'
import { NotificationsContext, notifyClose } from 'context'

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  height: 'calc(100% - 3rem)',
}

type ErrorInfo = {
  type: ErrorCodeType
  title: string
  content?: React.ReactNode
}

interface ErrorPageProps {
  error?: Error | Response
}

export default function ErrorPage({ error }: ErrorPageProps) {
  const intl = useIntl()
  const routerError: any = useRouteError()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null)
  const { notification, dispatchNotification } = useContext(NotificationsContext)

  const err = error ?? routerError

  useEffect(() => {
    let timer: any
    if (process.env.NODE_ENV === 'development') {
      console.error(err)
    }

    if (routerError || !error) {
      timer = setTimeout(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            pagePath: pathname,
            title: 'Page not found',
          },
        })
      }, 300)
    }

    switch (true) {
      // order not found
      case err?.statusText === ErrorCodeType.ORDER_ID_NOT_FOUND:
        setErrorInfo({
          type: ErrorCodeType.ORDER_ID_NOT_FOUND,
          title: intl.formatMessage({ id: 'notification.order_not_found' }),
          content: intl.formatMessage({ id: 'notification.order_not_found_content' }),
        })
        break
      // page not found
      case err?.statusText === ErrorCodeType.PAGE_NOT_FOUND || err?.status === 404 || pathname === '/404':
        setErrorInfo({
          type: ErrorCodeType.PAGE_NOT_FOUND,
          title: intl.formatMessage({ id: 'notification.page_not_found' }),
        })
        break
      // page forbidden
      case err?.statusText === ErrorCodeType.PAGE_FORBIDDEN || err?.status === 403:
        setErrorInfo({
          type: ErrorCodeType.PAGE_FORBIDDEN,
          title: intl.formatMessage({ id: 'notification.page_forbidden' }),
          content: intl.formatMessage(
            { id: 'notification.page_forbidden_content' },
            { email: <Link href={`mailto:${ConsultantData.email}`}>{ConsultantData.email}</Link> },
          ),
        })
        break

      // other unexpected errors
      default:
        setErrorInfo({
          type: ErrorCodeType.UNEXPECTED,
          title: intl.formatMessage({ id: 'notification.unexpected_error' }),
          content: intl.formatMessage({ id: 'notification.generic_error' }),
        })
        break
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [err, pathname])

  useEffect(() => {
    if (notification.open) {
      notifyClose(dispatchNotification)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  return (
    <LayoutWithHeader noBgr>
      <Box sx={containerStyle}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {errorInfo?.type === ErrorCodeType.PAGE_NOT_FOUND ? (
            <Typography variant="h2" color="grey.300" mb={4} sx={{ fontSize: '10rem !important' }}>
              404
            </Typography>
          ) : (
            <Box color="grey.200" mb={4}>
              <SadEmojiIcon fill="inherit" />
            </Box>
          )}

          <Typography variant="h3" mb={2.4}>
            {errorInfo?.title}
          </Typography>

          <Typography color="text.secondary" mb={4.8} sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
            {errorInfo?.content}
          </Typography>

          {errorInfo?.type === ErrorCodeType.UNEXPECTED ? (
            <Button onClick={() => window.location.reload()}>{intl.formatMessage({ id: 'button.reload_page' })}</Button>
          ) : (
            <Button onClick={() => navigate(-1)}>{intl.formatMessage({ id: 'button.go_back' })}</Button>
          )}
        </Box>
      </Box>
    </LayoutWithHeader>
  )
}
