import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

export const useFormFieldErrorFocus = () => {
  const {
    setFocus,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field: any, a: any) => {
      return errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])
}
