import { Dispatch, createContext, useReducer } from 'react'

import { type DialogAction } from '@rent/ui'

interface NotificationState {
  open: boolean
  type?: 'error' | 'success' | 'loading'
  title?: string
  content?: string
  loading?: boolean
  actions?: DialogAction[]
}

export interface NotificationAction {
  type: 'openErrorNotification' | 'openNotification' | 'closeNotification'
  payload?: { type: 'error' | 'success' | 'loading'; title: string; content?: string; loading?: boolean; actions?: DialogAction[] }
}

const reducer = (state: NotificationState, action: NotificationAction): NotificationState => {
  switch (action.type) {
    case 'openNotification': {
      if (action.payload) {
        return {
          open: true,
          type: action.payload.type,
          title: action.payload.title,
          content: action.payload.content,
          loading: action.payload.loading,
          actions: action.payload.actions,
        }
      }
      return { ...state, open: true }
    }
    case 'closeNotification': {
      return { open: false }
    }
    default: {
      return state
    }
  }
}

const initialState: NotificationState = {
  open: false,
}

export const NotificationsContext = createContext({ notification: initialState, dispatchNotification: () => {} } as {
  notification: NotificationState
  dispatchNotification: Dispatch<NotificationAction>
})

export const NotificationsContextProvider = ({ children }: any) => {
  const [notification, dispatchNotification] = useReducer(reducer, initialState)
  return <NotificationsContext.Provider value={{ notification, dispatchNotification }}>{children}</NotificationsContext.Provider>
}

const notifySuccess = (
  notificationDispatch: React.Dispatch<NotificationAction>,
  { title, content, actions }: { title: string; content?: string; actions?: DialogAction[] },
) =>
  notificationDispatch({
    type: 'openNotification',
    payload: {
      type: 'success',
      title,
      content,
      actions,
    },
  })
const notifyLoading = (
  notificationDispatch: React.Dispatch<NotificationAction>,
  payload?: { title?: string; content?: string; actions?: DialogAction[] },
) => {
  const { title, content, actions } = payload ?? {}
  return notificationDispatch({
    type: 'openNotification',
    payload: {
      type: 'loading',
      loading: true,
      title: title ?? '',
      content,
      actions,
    },
  })
}
const notifyError = (
  notificationDispatch: React.Dispatch<NotificationAction>,
  { title, content, actions }: { title: string; content?: string; actions?: DialogAction[] },
) =>
  notificationDispatch({
    type: 'openNotification',
    payload: {
      type: 'error',
      title,
      content,
      actions,
    },
  })
const notifyClose = (notificationDispatch: React.Dispatch<NotificationAction>) =>
  notificationDispatch({
    type: 'closeNotification',
  })

export { notifySuccess, notifyError, notifyLoading, notifyClose }
