import { type OutlinedInputFieldProperties } from '@rent/ui/models'

export type OnboardingCustomerFieldsPropertiesType = Record<CustomerPersonalDataFields, Omit<OutlinedInputFieldProperties, 'control'>>

export enum CustomerPersonalDataFields {
  firstName = 'firstName',
  lastName = 'lastName',
  nip = 'nip',
  buyerEmail = 'buyerEmail',
  buyerPhone = 'buyerPhone',
}

export enum CustomerPersonalDataConsentsFields {
  selectAll = 'selectAll',
  agreementRodo = 'agreementRodo',
  agreementStatute = 'agreementStatute',
  agreementKrd = 'agreementKrd',
  agreementEmailCommunication = 'agreementEmailCommunication',
  agreementPhoneCommunication = 'agreementPhoneCommunication',
}

export type CustomerPersonalDataFieldsType = {
  [CustomerPersonalDataFields.firstName]: string
  [CustomerPersonalDataFields.lastName]: string
  [CustomerPersonalDataFields.nip]: string | number
  [CustomerPersonalDataFields.buyerEmail]: string
  [CustomerPersonalDataFields.buyerPhone]: string
  [CustomerPersonalDataConsentsFields.selectAll]: boolean
  [CustomerPersonalDataConsentsFields.agreementRodo]: boolean
  [CustomerPersonalDataConsentsFields.agreementStatute]: boolean
  [CustomerPersonalDataConsentsFields.agreementKrd]: boolean
  [CustomerPersonalDataConsentsFields.agreementEmailCommunication]: boolean
  [CustomerPersonalDataConsentsFields.agreementPhoneCommunication]: boolean
}
