import { ApiExternalEndpoints } from 'api/endpoints'
import axios from 'axios'
import { usePostPrepareSecurityDetails } from 'components/pages/api'

interface PrepareSecurityDetails {
  externalId: string
}

export const usePrepareSecurityDetails = ({ externalId }: PrepareSecurityDetails) => {
  const postPrepareSecurityDetails = usePostPrepareSecurityDetails(externalId)

  const prepareSecurityDetails = async () => {
    axios
      .get(ApiExternalEndpoints.getGeolocation().url)
      .then(res => {
        if (res?.data) {
          const orderSecDetails = {
            countryCode: res.data.country_code,
            countryName: res.data.country_name,
            city: res.data.city,
            postal: res.data.postal,
            latitude: res.data.latitude,
            longitude: res.data.longitude,
            ip: res.data.IPv4,
            state: res.data.state,
          }
          postPrepareSecurityDetails.mutate(orderSecDetails)
        }
      })
      .catch(() => {})
  }

  return { prepareSecurityDetails }
}
