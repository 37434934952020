import ApiEndpoints from 'api/endpoints'
import { useRequest } from 'hooks'
import { IVendor } from 'models'

const maxUnsuccessfulRequests = 3

export const useGetVendorData = (vendorId: string) => {
  return useRequest<IVendor>(ApiEndpoints.getVendor(vendorId), {
    enabled: !!vendorId,
    retry: maxUnsuccessfulRequests,
  })
}
