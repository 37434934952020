import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { LayoutWithHeader } from 'components/layouts'

import { containerStyle } from './styles'

export function PrivacyPolicyPage() {
  const { formatMessage } = useIntl()
  return (
    <>
      <Helmet>
        <title>RentPay - {formatMessage({ id: 'privacy_policy' })}</title>
      </Helmet>
      <LayoutWithHeader>
        <Typography variant="h2" component="h1" textAlign="center" mb={6}>
          {formatMessage({ id: 'privacy.title' })}
        </Typography>
        <Box sx={containerStyle} dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'privacy.content' }) }}></Box>
      </LayoutWithHeader>
    </>
  )
}
