import { useIntl } from 'react-intl'

import { Box, Container, Divider, Typography } from '@mui/material'
import { ExternalLinks, GeneralRoute } from 'consts'

import CustomLink from './CustomLink'

const Footer = () => {
  const { formatMessage } = useIntl()

  return (
    <Container>
      <Divider />
      <Box py={2.8} flexDirection={{ xs: 'column-reverse', md: 'row' }} display="flex" justifyContent="space-between" alignItems="center">
        <Typography color="text.secondary" variant="body2" sx={{ pt: { xs: 1.6, md: 0 } }}>
          {formatMessage({ id: 'copyright' }, { year: new Date().getFullYear() })}
        </Typography>

        <Box display="flex" flexWrap="wrap">
          <CustomLink href={`/${GeneralRoute.TERMS}`} target="_blank" id="terms-link">
            {formatMessage({ id: 'terms_of_service' })}
          </CustomLink>
          <CustomLink href={`/${GeneralRoute.PRIVACY_POLICY}`} target="_blank" id="privacyPolicy-link">
            {formatMessage({ id: 'privacy_policy' })}
          </CustomLink>

          <CustomLink href={ExternalLinks.WESUB} target="_blank" id="wesub-link">
            {formatMessage({ id: 'wesub' })}
          </CustomLink>
        </Box>
      </Box>
    </Container>
  )
}

export default Footer
