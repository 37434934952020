/* eslint "@tanstack/query/prefer-query-object-syntax": "off" */
import { MutationOptions, QueryKey, UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { instance } from 'api/axiosInstance'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

/**
 * Rent apps Custom Endpoint Request
 * @see AxiosRequestConfig by default
 * plus an optional queryKey key parameter to be sent in the request object
 */

type EndpointRequest = AxiosRequestConfig & { queryKey?: QueryKey }

export interface ApiConfig<Data = unknown, Error = unknown>
  extends Omit<UseQueryOptions<AxiosResponse<Data>, AxiosError<Error>>, 'initialData'> {
  initialData?: AxiosResponse<Data>
  isCancelable?: boolean
}

export interface ApiReturn<Data, Error>
  extends Pick<
    UseQueryResult<AxiosResponse<Data>, AxiosError<Error>>,
    'isLoading' | 'isFetching' | 'isError' | 'error' | 'status' | 'fetchStatus' | 'isSuccess' | 'refetch'
  > {
  data: Data | undefined
  response: AxiosResponse<Data> | undefined
  queryKey: QueryKey
}

export interface MutationConfig<Data = unknown, Error = unknown>
  extends Omit<MutationOptions<AxiosResponse<Data>, AxiosError<Error>>, 'initialData'> {
  initialData?: Data
}

export interface MutateReturn<Data>
  extends Pick<UseMutationResult<AxiosResponse<Data>>, 'isLoading' | 'isError' | 'error' | 'status' | 'isSuccess' | 'reset' | 'isIdle'> {
  data: Data | undefined
  response: AxiosResponse<Data> | undefined | null
  mutate: any
  mutateAsync: any
}

export interface RequestParams {
  enabled?: boolean
  retry?: boolean
  isCancelable?: boolean
}
/**
 * A wrapper around React-Query useQuery. It uses Axios for requests and returns same results as useQuery do.
 * @param {EndpointRequest} request - The `request` parameter is an object that contains the
 * information needed to make an API request. It typically includes properties like `url`, `method`,
 * `headers`, and `body`.
 * @param  - - `request`: The request object that contains information about the API endpoint to be
 * called.
 * a boolean value indicating whether the request should be retried if it fails.
 * @returns The function `useRequest` returns an object
 */
export function useRequest<Data = unknown, Error = unknown>(
  request: EndpointRequest,
  { initialData, isCancelable, ...config }: ApiConfig<Data, Error> = {},
): ApiReturn<Data, Error> {
  const queryKey = request.queryKey ?? ([request && JSON.stringify(request)] as QueryKey)
  const { isFetching, status, fetchStatus, isError, isLoading, isSuccess, data, error, refetch } = useQuery(
    queryKey,
    isCancelable
      ? ({ signal }) => {
          request.signal = signal
          return instance(request)
        }
      : () => instance(request),
    {
      refetchOnWindowFocus: false,
      initialData,
      ...config,
    },
  )

  return {
    data: data && data?.data,
    response: data,
    error,
    status,
    fetchStatus,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    refetch,
    queryKey,
  }
}

export function useMutate<Data = unknown, Error = unknown>(
  request: EndpointRequest,
  config: MutationConfig<Data, Error> = {},
): MutateReturn<Data> {
  const info = useMutation<AxiosResponse<Data>, AxiosError<Error>>((data: any) => instance({ ...request, data }), config)

  return {
    ...info,
    data: info.data && info.data.data,
    response: info.data,
  }
}

export type OnMutate = ((variables: AxiosError<unknown>) => unknown) | undefined | any
export type OnError = ((error: Error, variables: AxiosError<unknown>, context?: unknown) => void | Promise<unknown>) | undefined | any
export type OnSuccess = ((data: AxiosResponse<any>, variables: AxiosError<unknown>) => void | Promise<void>) | void | undefined | any
