import ApiEndpoints from 'api/endpoints'
import { RequestParams, useRequest } from 'hooks'

import { ContractGenerationValidationSchema } from '../ContractGeneration/consts'
import { prepareGenerateOrderContractData } from './usePostGenerateOrderContract'

export const useGetSaveCustomerAgreementData = (
  { data, formData }: { data: any; formData: ContractGenerationValidationSchema },
  reqParams?: RequestParams,
) => {
  const params = prepareGenerateOrderContractData(formData, data)

  const endpointData = `${params.pesel}/${params.uuid}/${params.identityCardNumber}/${params.phoneNumber}/${params.identityCardValidFrom}/${params.identityCardValidTo}`

  return useRequest(ApiEndpoints.getSaveCustomerAgreementData(endpointData), reqParams ?? {})
}
