export const getAccordionTexts = (formatMessage: ({ id }: { id: string }) => string) => [
  {
    title: formatMessage({ id: 'message.contract_form.title' }),
    content: formatMessage({ id: 'message.contract_form.description' }),
    id: 'contractForm-additional-expand',
  },
  {
    title: formatMessage({ id: 'message.why_we_need_your_data.title' }),
    content: formatMessage({ id: 'message.why_we_need_your_data.description' }),
    id: 'whyWeNeedYourData-additional-expand',
  },
  {
    title: formatMessage({ id: 'message.why_it_worth_to_subscribe.title' }),
    content: formatMessage({ id: 'message.why_it_worth_to_subscribe.description' }),
    id: 'whyItWorthToSubscribe-additional-expand',
  },
  {
    title: formatMessage({ id: 'message.how_it_works.title' }),
    content: formatMessage({ id: 'message.how_it_works.description' }),
    id: 'howItWorks-additional-expand',
  },
]
