import { SxProps, Theme } from '@mui/material'
import Link, { LinkProps } from '@mui/material/Link'
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg'

const linkStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  '&:hover': {
    bgcolor: 'inherit',
    textDecoration: 'underline',
  },
  '&:not(:last-child)': { mr: 2.4 },
  '& svg': {
    width: 'auto',
    height: '2.4rem',
    ml: 0.8,
  },
}

const CustomLink = ({ children, href, ...otherProps }: LinkProps) => {
  return (
    <Link href={href} variant="body2" color="text.secondary" sx={linkStyle} {...otherProps}>
      {children}
      <ChevronRightIcon />
    </Link>
  )
}

export default CustomLink
