import React, { ComponentType } from 'react'

import { OrderContextProvider } from 'context'

function withOrder<T extends object>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    return (
      <OrderContextProvider>
        <WrappedComponent {...props} />
      </OrderContextProvider>
    )
  }
}

export { withOrder }
