import { OrderStatus, RouteQueryParam } from 'consts'
import { useRouteQuery } from 'hooks'

interface PreviewOnly {
  status: OrderStatus | undefined
}

export const usePreviewOnly = ({ status }: PreviewOnly) => {
  const routerQuery = useRouteQuery()
  const previewOnly = routerQuery.get(RouteQueryParam.PREVIEW_ONLY)

  return status === OrderStatus.PREVIEW_ONLY && previewOnly === 'true'
}
