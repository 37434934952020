import { useIntl } from 'react-intl'

import { Button, ButtonProps } from '@rent/ui'
import { ReactComponent as FileIcon } from 'assets/icons/File.svg'

interface DownloadFileButtonProps extends ButtonProps {
  text: string
  id?: string
}

const DownloadFileButton = ({ text, ...otherProps }: DownloadFileButtonProps) => {
  const { formatMessage } = useIntl()

  return (
    <Button variant="outlined" color="inherit" size="small" startIcon={<FileIcon />} sx={{ borderColor: 'grey.200' }} {...otherProps}>
      {formatMessage({ id: text })}
    </Button>
  )
}
export default DownloadFileButton
