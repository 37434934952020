import { useContext, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { zodResolver } from '@hookform/resolvers/zod'
import Box from '@mui/material/Box'
import { Button, InfoBar, StepTitle } from '@rent/ui'
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/ArrowForward.svg'
import { StepContentLayout } from 'components/layouts'
import { NotReadyComponent, OrderSummaryPanel } from 'components/shared'
import { ErrorCodeType, OrderStatus, StepRoute } from 'consts'
import { NotificationsContext, notifyLoading } from 'context'

import { prepareCreateOrderData } from '../api/usePostCreateOrder'
import { useStorageOrderProductsData } from '../hooks'
import { usePluginRouteParams, usePluginVendorData } from '../hooks/plugin'
import FormData from './components/FormData'
import { ValidationSchema, formInitValues, validationSchema } from './consts'
import { useCheckClientConsents, useCreateOrder } from './hooks'

const PersonalDataPlugin = () => {
  const { formatMessage } = useIntl()

  const { dispatchNotification } = useContext(NotificationsContext)

  const { data, vendorData, loading } = usePluginVendorData({ shouldUpdateBannerStatus: false })

  const { routeParams, navigateTo } = usePluginRouteParams()

  const { orderProductsData } = useStorageOrderProductsData({
    items: data
      ? [
          {
            id: data?.id,
            product: data,
            quantityInOrder: 1,
            safeupPlusTaken: false,
          },
        ]
      : undefined,
    orderId: data?.id?.toString(),
    refetchOrderData: () => {
      navigateTo(StepRoute.STORE_ORDER_SUMMARY)
    },
  })

  const methods = useForm<ValidationSchema>({
    defaultValues: formInitValues(data),
    resolver: zodResolver(validationSchema(formatMessage)),
    mode: 'onSubmit',
  })
  const { handleSubmit } = methods

  const { createOrder } = useCreateOrder({ externalId: data?.externalId ?? '', returnURL: data?.externalUrlId })

  const [providedNip, setProvidedNip] = useState('')
  const { marketingConsents, clientConsentsIsLoading } = useCheckClientConsents({ providedNip })

  const onSubmit: SubmitHandler<ValidationSchema> = async formData => {
    notifyLoading(dispatchNotification, {
      title: formatMessage({ id: 'notification.data_verification_title' }),
      content: formatMessage({ id: 'notification.please_wait_several_sec' }),
    })
    const mappedData = prepareCreateOrderData('', formData, orderProductsData, {
      apiBannerParam: routeParams.apiBannerParam,
      bannerGeneratedId: routeParams.generatedBannerId,
      applicationName: 'BN',
      vendorId: vendorData?.id,
    })
    createOrder(mappedData)
  }

  return (
    <Box>
      <NotReadyComponent isLoading={loading} errorType={ErrorCodeType.ORDER_ERROR} redirect>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StepContentLayout
              // right panel
              rightSide={
                <OrderSummaryPanel
                  items={orderProductsData}
                  actionButton={
                    <Button
                      fullWidth
                      type="submit"
                      id="goToAgreementGeneration-button"
                      loading={clientConsentsIsLoading}
                      endIcon={<ArrowForwardIcon />}
                    >
                      {formatMessage({ id: 'button.go_further' })}
                    </Button>
                  }
                />
              }
            >
              <>
                {/* title */}
                <StepTitle
                  title={`2. ${formatMessage({ id: 'personal_data' })}`}
                  subtitle={formatMessage({ id: 'message.the_process_consists_of_steps' }, { number_of_steps: 3 })}
                >
                  <Box sx={{ maxWidth: 500 }}>
                    <InfoBar title={formatMessage({ id: 'message.prepare_id_card' })} align="left" iconSize={1.6} />
                  </Box>
                </StepTitle>

                {/* form data */}
                {data && (
                  <FormData
                    onSubmitForm={onSubmit}
                    orderStatus={OrderStatus.NEW}
                    extension={false}
                    setProvidedNip={setProvidedNip}
                    marketingConsents={marketingConsents}
                    isPlugin
                  />
                )}
              </>
            </StepContentLayout>
          </form>
        </FormProvider>
      </NotReadyComponent>
    </Box>
  )
}

export default PersonalDataPlugin
