import { useCallback } from 'react'
import { useBeforeUnload } from 'react-router-dom'

import ApiEndpoints from 'api/endpoints'
import { APP_ENV_TYPE } from 'config/appConstants'
import { OrderStatus, clearStorage } from 'consts'

interface CancelNewOrder {
  orderId: string
  status: OrderStatus | undefined
  refetchOrderData: () => void
}

export const useCancelNewOrder = ({ orderId, status }: CancelNewOrder) => {
  const handleBeforeUnload = useCallback(() => {
    const url = ApiEndpoints.postCancelNewOrder(orderId).url
    const data = JSON.stringify({})
    const headers = { type: 'application/json' }
    if (navigator.sendBeacon) {
      navigator.sendBeacon(url, new Blob([data], headers))
    } else {
      // Fallback for browsers that do not support navigator.sendBeacon
      fetch(url, {
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
        keepalive: true, // This ensures the request is made even if the page is closed
      })
    }
  }, [orderId])

  useBeforeUnload(
    useCallback(() => {
      if (status === OrderStatus.NEW && orderId && APP_ENV_TYPE === 'prod') {
        clearStorage()
        // refetchOrderData()
        handleBeforeUnload()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, orderId, handleBeforeUnload]),
  )
}
