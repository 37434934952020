import ApiEndpoints from 'api/endpoints'
import { useMutate, useRequest } from 'hooks'

const maxUnsuccessfulRequests = 2

export const useGetPluginVendorData = (bannerId: string) => {
  return useRequest<any>(ApiEndpoints.getPluginVendorData(bannerId), {
    enabled: !!bannerId,
    retry: maxUnsuccessfulRequests,
  })
}

export type PluginProductDataBody = {
  clientProductId: string
  clientSellPrice: string
  apiBannerParam: string
}
export const usePostPluginProductData = () => {
  return useMutate<PluginProductDataBody>(ApiEndpoints.postPluginProductData())
}

export const useUpdateBannerAuditStatus = () => {
  return useMutate(ApiEndpoints.updateBannerAuditStatus())
}

export const prepareUpdateBannerAuditStatusBody = (generatedId: string | null) => {
  if (generatedId && generatedId.trim().length > 0) {
    const body = {
      nip: '',
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      message: '',
      productsInCarts: [],
      applicationName: '',
      rodoAgreementAccepted: false,
      statuteAccepted: false,
      krdAccepted: false,
      electronicProcessingAccepted: false,
      phoneProcessingAccepted: false,
      vendorIntegrationId: '',
      orderId: 0,
      externalOrderId: '',
      apiBannerParam: '',
      bannerGeneratedId: generatedId,
      uuid: '',
      safeUpPlusTaken: false,
      isExternalVendor: false,
      externalVendorLogin: '',
      isClientEmailOffer: false,
      isFrent: false,
      dealerLogin: '',
    }
    return body
  }
  return null
}

export const useCreateOrder = () => {
  return useMutate(ApiEndpoints.createOrder())
}
