import ApiEndpoints from 'api/endpoints'
import { OnError, OnSuccess, useMutate } from 'hooks'
import { ICustomerOrder } from 'models'

export const usePostAcceptOffer = (orderId: string, onError: OnError, onSuccess: OnSuccess) => {
  return useMutate<ICustomerOrder>(ApiEndpoints.postAcceptOffer(orderId), {
    onError,
    onSuccess,
  })
}
