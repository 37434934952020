import { IMappedProduct, IOrderProduct } from 'models'

/**
 * The function `mapProducts` takes an array of order products and maps them to a new array
 * with calculated prices and other properties.
 * @param {IOrderProduct[]} items - An array of objects representing order products
 * @param {boolean} [b2cOnline] - The `b2cOnline` parameter is a boolean flag
 * @returns an array of `IMappedProduct` objects.
 */
export const mapProducts = (items: IOrderProduct[], b2cOnline?: boolean): IMappedProduct[] => {
  const currPrice = b2cOnline ? 'currentB2cPrice' : 'currentPrice'
  return items.map(el => {
    const productPricesType = el.product[currPrice]

    const productPrice = el.monthPriceDiscount
      ? el.monthPriceDiscount * el.quantityInOrder
      : productPricesType.monthPrice * el.quantityInOrder

    const safeupPrice = (productPricesType.safeupPrice ? Number(productPricesType.safeupPrice) : 0) * el.quantityInOrder

    const safeupPlusPrice = productPricesType.safeupPlusPrice ? Number(productPricesType.safeupPlusPrice * el.quantityInOrder) : null

    return {
      id: el.product.id,
      name: el.product.name,
      productPrice,
      pscProductPrice: productPricesType.monthPrice,
      productPriceDiscount: null,
      safeupPrice,
      safeupPlusPrice,
      quantity: el.quantityInOrder,
      deposit: productPricesType.deposit * el.quantityInOrder,
      loanFee: el.product.loanFee === undefined ? null : el.product.loanFee,
      sellPrice: productPricesType.sellPrice ?? 0,
      rentalPeriod: el.numberOfMonths ?? 12,
      safeUpProvider: el.product.safeUpProvider,
      safeupPlusTaken: Boolean(el.safeupPlusTaken),
      discount: el.discount ?? null,
      discountAmount: el.discountAmount ?? null,
      discountId: null,
      discountCode: null,
      totalPriceWithDiscount: null,
    }
  })
}

export const calcProductPrice = ({
  productPrice,
  productPriceDiscount,
  safeupPrice,
  discountAmount,
  discount,
  ...others
}: IMappedProduct) => {
  // productPriceDiscount is returned on generateContract page
  const totalPriceWithDiscount = productPriceDiscount ? productPriceDiscount + safeupPrice : productPrice + safeupPrice

  return {
    ...others,
    safeupPrice,
    discount,
    discountAmount,
    productPrice,
    productPriceDiscount,
    totalPrice: Number(productPrice + safeupPrice),
    totalPriceWithDiscount,
  }
}

export const checkProductsHaveSameRentalPeriod = (items: IMappedProduct[]): boolean =>
  items.every(el => el.rentalPeriod === items[0].rentalPeriod)

export const calcProductsTotalPrice = (items: IMappedProduct[]): number =>
  items.reduce(
    (prev, { totalPriceWithDiscount, safeupPlusTaken, safeupPlusPrice }) =>
      prev + (totalPriceWithDiscount ?? 0) + (safeupPlusTaken && safeupPlusPrice ? safeupPlusPrice : 0),
    0,
  )

export const calcProductsLoanTotalPrice = (items: IMappedProduct[]): number =>
  items.reduce((prev, { sellPrice, loanFee, quantity }) => prev + (sellPrice - (loanFee ?? 0) * quantity), 0)

export const calcProductsTotalDeposit = (items: IMappedProduct[]): number => items.reduce((prev, { deposit }) => prev + deposit, 0)

export const calcProductsTotalLoanFee = (items: IMappedProduct[]): number => items.reduce((prev, { loanFee }) => prev + (loanFee ?? 0), 0)

export const checkAllProductsMatchInterface = (items: IMappedProduct[]): boolean => {
  const isMatch = items.every(item => {
    if (
      typeof item.id !== 'number' ||
      typeof item.name !== 'string' ||
      typeof item.productPrice !== 'number' ||
      typeof item.pscProductPrice !== 'number' ||
      typeof item.safeupPrice !== 'number' ||
      (typeof item.safeupPlusPrice !== 'number' && item.safeupPlusPrice !== null) ||
      typeof item.quantity !== 'number' ||
      typeof item.rentalPeriod !== 'number' ||
      typeof item.deposit !== 'number' ||
      typeof item.safeupPlusTaken !== 'boolean' ||
      (typeof item.discount !== 'number' && item.discount !== null) ||
      (typeof item.discountAmount !== 'number' && item.discountAmount !== null)
    ) {
      return false
    }

    return true
  })

  return isMatch
}
