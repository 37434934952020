import { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import { FallbackProps, withErrorBoundary } from 'react-error-boundary'
import TagManager from 'react-gtm-module'
import { HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PageLayout } from 'components/layouts'
import { CookieBanner, ErrorFallback } from 'components/shared'
import { APP_GTM_ID } from 'config/appConstants'
import { router } from 'config/routing'
import { STORAGE_SESSION_ID } from 'consts'
import 'dayjs/locale/pl'
import { trackerData } from 'helpers'
import { useRiskTracker, withIntl, withNotifications, withOrder, withTheme } from 'hooks'
import { v4 as uuid, validate as validateUuid } from 'uuid'

const queryClient = new QueryClient()
const tagManagerArgs = { gtmId: APP_GTM_ID }

function App() {
  useEffect(() => {
    const sessionId = localStorage.getItem(STORAGE_SESSION_ID)
    if (!sessionId || !(sessionId && validateUuid(sessionId))) {
      localStorage.setItem(STORAGE_SESSION_ID, uuid())
    }
    if (tagManagerArgs.gtmId) {
      TagManager.initialize(tagManagerArgs)
    }
  }, [])

  useRiskTracker(trackerData)

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <PageLayout>
            <RouterProvider router={router} />
            <CookieBanner />
          </PageLayout>
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </CookiesProvider>
  )
}

export default withTheme(
  withIntl(
    withErrorBoundary(withNotifications(withOrder(App)), {
      FallbackComponent: (props: FallbackProps) => <ErrorFallback {...props} isPage />,
    }),
  ),
)
