import { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { AxiosError } from 'axios'
import { ErrorPage } from 'components/pages'
import { ErrorCodeType, GeneralRoute, HttpCode } from 'consts'
import { NotificationsContext, notifyClose, notifyLoading } from 'context'

interface NotReadyComponentProps {
  children?: React.ReactNode
  isSuccess?: boolean
  isLoading?: boolean
  isError?: boolean
  error?: AxiosError | null | Error | unknown
  redirect?: boolean
  errorType?: ErrorCodeType
}

const NotReadyComponent = ({ isLoading, isSuccess, isError, error, errorType, redirect, children }: NotReadyComponentProps) => {
  const { dispatchNotification } = useContext(NotificationsContext)

  useEffect(() => {
    if (isLoading) {
      notifyLoading(dispatchNotification)
    } else {
      notifyClose(dispatchNotification)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (!isError) {
    return <>{children}</>
  }

  if (isError || (!isSuccess && !isLoading)) {
    let err
    if (errorType === ErrorCodeType.ORDER_ERROR && (error as any)?.response?.status === HttpCode.FORBIDDEN) {
      return <Navigate to={`/${GeneralRoute.ORDER_CANCELED}`} replace />
    } else if (redirect) {
      return <Navigate to={`/${GeneralRoute.NOT_FOUND}`} replace />
    } else {
      if ((error as any)?.response?.status === HttpCode.NOT_FOUND) {
        err = new Response(ErrorCodeType.ORDER_ID_NOT_FOUND, { status: HttpCode.NOT_FOUND, statusText: ErrorCodeType.ORDER_ID_NOT_FOUND })
      }
      if ((error as any)?.response?.status === HttpCode.FORBIDDEN) {
        err = new Response(ErrorCodeType.PAGE_FORBIDDEN, { status: HttpCode.FORBIDDEN, statusText: ErrorCodeType.PAGE_FORBIDDEN })
      }
    }
    return <ErrorPage error={err} />
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.info('Child component did not load:', { children })
  }
  return <div />
}

export default NotReadyComponent
