import { SxProps, Theme } from '@mui/material'

export const formBoxStyle: SxProps<Theme> = {
  bgcolor: 'grey.50',
  border: '.1rem solid',
  borderColor: 'grey.200',
  borderRadius: 1,
  px: { xs: 1.6, md: 2.4 },
  py: { xs: 2.4, md: 3 },
  my: 2.4,
}

export const contractContainerBoxStyle: SxProps<Theme> = {
  border: '.1rem solid',
  borderColor: 'grey.200',
  borderRadius: 1,
  mb: 1.6,
}

export const contractContainerTitleBoxStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  alignItems: 'center',
  justifyContent: 'space-between',
  py: 1.6,
  px: { xs: 1.6, md: 2.4 },
}
