import ApiEndpoints from 'api/endpoints'
import { RequestParams, useRequest } from 'hooks'
import { AGREEMENT_TYPE } from 'models'

const maxUnsuccessfulRequests = 1

export const useGetAgreementTemplate = (agreementType: AGREEMENT_TYPE, reqParams?: RequestParams) => {
  return useRequest<Blob>(ApiEndpoints.getAgreementTemplate(agreementType), {
    retry: maxUnsuccessfulRequests,
    ...reqParams,
  })
}
