export interface IAgreementResponse {
  text?: string
  clientOrderUuid?: string
  frameworkAgreementExposedId?: string
  leaseAgreementExposedId?: string
  pdf?: string
  content?: any
  archivalAgreement?: boolean
}

export enum AGREEMENT_TYPE {
  FRAMEWORK_AND_LEASE_AGREEMENT_CREDIT_B2B = 'FRAMEWORK_AND_LEASE_AGREEMENT_CREDIT_B2B',
  FRAMEWORK_AND_LEASE_AGREEMENT = 'FRAMEWORK_AND_LEASE_AGREEMENT',
}
