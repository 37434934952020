const customer = {
  personal_data: 'Dane osobowe',
  buyer_data: 'Dane kupującego',
  buyer_data_loan: 'Dane pożyczkobiorcy',
  client_data: 'Dane klienta',
  contract_data: 'Dane z umowy',
  try_later: 'Spróbuj później',
  delivery_method: 'Sposób dostawy',
  formal_consents: 'Zgody formalne',
  required_consents: 'Zgody wymagane',
}

export { customer }
