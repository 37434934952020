import { SxProps, Theme } from '@mui/material'

export const gridItemContainerStyle = {
  display: 'flex',
  alignItems: { xs: 'flex-start', md: 'center' },
  justifyContent: 'space-between',
  mb: 2.4,
  flexDirection: { xs: 'column', md: 'row' },
}

export const gridItemStyle: SxProps<Theme> = {
  whiteSpace: { xs: 'pre-wrap', sm: 'nowrap' },
  flexBasis: 'calc(10% - 1.6rem)',
  ml: { xs: 0, md: 1.6 },
}
