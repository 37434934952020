import packageJson from '../../package.json'

// RentPay app version:
const APP_VERSION = packageJson.version

const { REACT_APP_API_BASE_URL, REACT_APP_ENV_TYPE, REACT_APP_GTM_ID } = process.env

// CORE APP
const API_BASE_URL = REACT_APP_API_BASE_URL ?? ''
const APP_ENV_TYPE = REACT_APP_ENV_TYPE ?? 'dev'
const APP_GTM_ID = REACT_APP_GTM_ID ?? ''

export { APP_VERSION, API_BASE_URL, APP_ENV_TYPE, APP_GTM_ID }
