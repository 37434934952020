import { createBrowserRouter } from 'react-router-dom'

import { OrderContainer, StepLayout } from 'components/layouts'
import {
  ContractGeneration,
  ErrorPage,
  OrderCanceledPage,
  OrderFinishedConfirmation,
  PersonalData,
  PersonalDataPlugin,
  PrivacyPolicyPage,
  StoreOrderSummary,
  StoreOrderSummaryPlugin,
  TermsPage,
} from 'components/pages'
import { ErrorCodeType } from 'consts'
import { GeneralRoute, StepRoute } from 'consts/routing'

const orderParam = ':orderId'

const stepsRoutes = [
  {
    index: true,
    path: `${orderParam}/${StepRoute.STORE_ORDER_SUMMARY}`,
    element: <StoreOrderSummary />,
  },

  {
    path: `${orderParam}/${StepRoute.PERSONAL_DATA}`,
    element: <PersonalData />,
  },
  {
    path: `${orderParam}/${StepRoute.CONTRACT_GENERATION}`,
    element: <ContractGeneration />,
  },
]

const routes = [
  {
    element: <OrderContainer />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: GeneralRoute.ORDER,
        exact: true,
        element: <StepLayout />,
        loader: checkUrlParams,
        errorElement: <ErrorPage />,
        children: stepsRoutes,
      },
      {
        path: `${GeneralRoute.ORDER}/${orderParam}/${GeneralRoute.ORDER_FINISHED_CONFIRMATION}`,
        element: <OrderFinishedConfirmation />,
        errorElement: <ErrorPage />,
      },
      {
        path: GeneralRoute.TERMS,
        element: <TermsPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: GeneralRoute.PRIVACY_POLICY,
        element: <PrivacyPolicyPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: GeneralRoute.ORDER_CANCELED,
        element: <OrderCanceledPage />,
        errorElement: <ErrorPage />,
      },
      // plugin
      {
        path: `${GeneralRoute.ORDER_PLUGIN}`,
        exact: true,
        element: <StepLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            // index: true,
            path: StepRoute.STORE_ORDER_SUMMARY,
            element: <StoreOrderSummaryPlugin />,
          },
          {
            path: StepRoute.PERSONAL_DATA,
            element: <PersonalDataPlugin />,
          },
        ],
      },
    ],
  },
  {
    path: GeneralRoute.NOT_FOUND,
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: <></>,
    loader: throwPageNotFound,
    errorElement: <ErrorPage />,
  },
]

export async function checkUrlParams({ params }: { params: { orderId?: string } }) {
  if (!params.orderId) throw new Response(ErrorCodeType.PAGE_NOT_FOUND, { status: 404, statusText: ErrorCodeType.PAGE_NOT_FOUND })
  else return {}
}

export async function throwPageNotFound() {
  throw new Response(ErrorCodeType.PAGE_NOT_FOUND, { status: 404, statusText: ErrorCodeType.PAGE_NOT_FOUND })
}

export const router = createBrowserRouter(routes)
