import { useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import { usePostGenerateVerificationCode } from 'components/pages/api'
import { CustomerType } from 'consts'
import { NotificationsContext, notifyClose, notifyError, notifyLoading } from 'context'

interface CodeGeneration {
  externalId: string
}
export const useCodeGeneration = ({ externalId }: CodeGeneration) => {
  const { formatMessage } = useIntl()
  const { dispatchNotification } = useContext(NotificationsContext)

  const postGenerateVerificationCode = usePostGenerateVerificationCode()

  const [codeVerificationOpen, setCodeVerificationOpen] = useState(false)

  const generateVerificationCode = async (buyerPhone: string) => {
    notifyLoading(dispatchNotification)
    await postGenerateVerificationCode.mutate(
      {
        customerOrderType: CustomerType.B2B,
        phoneNumber: buyerPhone,
        uuid: externalId,
      },
      {
        onSuccess: () => {
          setCodeVerificationOpen(true)
          notifyClose(dispatchNotification)
        },
        onError: () => {
          notifyError(dispatchNotification, {
            title: formatMessage({ id: 'notification.dear_customer' }),
            content: formatMessage({ id: 'notification.verification_code_generation_error' }),
          })
        },
      },
    )
  }

  return { codeVerificationOpen, setCodeVerificationOpen, generateVerificationCode }
}
