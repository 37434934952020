import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { AxiosResponse } from 'axios'
import { usePostPromoCode, usePostSuperPromoCode } from 'components/pages/api'
import { NotificationAction, notifyClose, notifyLoading } from 'context'
import { IMappedProduct, IProduct, IPromotion, IPromotionCart } from 'models'

type PromoCodeInfo = {
  type: 'success' | 'error'
  message: string
}

interface PromoCode {
  originProductsData: IProduct[] | undefined
  orderProductsData: IMappedProduct[]
  setOrderProductsData: (value: React.SetStateAction<IMappedProduct[]>) => void
  dispatchNotification: React.Dispatch<NotificationAction>
  disabled?: boolean
}

/**
 * The `usePromoCode` function is a custom hook that handles the logic for applying a promo code to a
 * list of products.
 * @returns The `usePromoCode` hook returns an object with two properties: `promoCodeInfo` and
 * `onSubmitPromoCodeForm`.
 */
export const usePromoCode = ({
  originProductsData,
  orderProductsData,
  setOrderProductsData,
  dispatchNotification,
  disabled = false,
}: PromoCode) => {
  const { formatMessage } = useIntl()
  const [promoCodeInfo, setPromoCodeInfo] = useState<PromoCodeInfo | null>(null)
  const [promoCode, setPromoCode] = useState('')

  const updateProductsList = (productsWithPromotion: IProduct[], promotion: IPromotion) => {
    setOrderProductsData((prev: IMappedProduct[]) =>
      // check if the product is covered by the promotion
      prev.map(el => {
        const promotionAppliedToProduct = productsWithPromotion.find(item => item.id === el.id)
        if (promotionAppliedToProduct) {
          return {
            ...el,
            productPriceDiscount: promotionAppliedToProduct ? promotionAppliedToProduct.currentPrice?.monthPrice : el.productPriceDiscount,
            discountId: promotion?.id,
            discount: promotion?.percent,
            discountAmount: promotion?.discountAmount,
            discountCode: promotion?.code,
          }
        } else return el
      }),
    )
  }

  const handleError = () => {
    setPromoCodeInfo({
      type: 'error',
      message: formatMessage({ id: 'notification.unexpected_error' }),
    })
  }
  const handleSuccess = (data: AxiosResponse<IPromotionCart>) => {
    const { outOfDate, promotion, products } = data?.data ?? {}

    const productsWithPromotion = products?.filter(promoEl => orderProductsData.find(el => el.id === promoEl.id))

    if (outOfDate) {
      setPromoCodeInfo({
        type: 'error',
        message: formatMessage({ id: 'notification.promocode_expired' }),
      })
    }
    // check if any product is covered by the promotion
    else if ((promotion?.percent || promotion?.discountAmount) && productsWithPromotion?.length > 0) {
      setPromoCodeInfo({
        type: 'success',
        message: formatMessage({ id: 'notification.promocode_applied' }, { val: promotion?.code }),
      })
      updateProductsList(productsWithPromotion, promotion)
    } else {
      setPromoCodeInfo({
        type: 'error',
        message: formatMessage({ id: 'notification.promocode_not_found' }),
      })
    }
    notifyClose(dispatchNotification)
  }

  const postPromocode = usePostPromoCode(handleError, handleSuccess)
  const postSuperPromocode = usePostSuperPromoCode()
  const [superPromoIsCalled, setSuperPromoIsCalled] = useState(false)

  useEffect(() => {
    if (!disabled && originProductsData && originProductsData?.length > 0 && !superPromoIsCalled) {
      onSubmitSupePromoCodeForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originProductsData, superPromoIsCalled])

  const onSubmitSupePromoCodeForm = async () => {
    setSuperPromoIsCalled(true)
    await postSuperPromocode.mutate(
      {
        productsId: originProductsData?.map(el => el.id),
        promotionCode: 'whenSuperPromoCtxCodeIsNotObligatory',
      },
      {
        onSuccess: ({ data }: { data: IPromotionCart }) => {
          const { outOfDate, promotion, products, superPromo } = data

          const productsWithPromotion = products?.filter(promoEl => originProductsData?.find(el => el.id === promoEl.id))

          // check if any product is covered by the promotion
          if (!outOfDate && superPromo && productsWithPromotion?.length > 0) {
            setPromoCode(promotion.code as string)
            updateProductsList(productsWithPromotion, promotion)
          }
        },
      },
    )
  }

  const onSubmitPromoCodeForm = async (promotionCode?: string) => {
    notifyLoading(dispatchNotification)
    const data = {
      productsId: orderProductsData.map(el => el.id),
      promotionCode,
    }
    await postPromocode.mutate(data)
  }

  return { promoCodeInfo, onSubmitPromoCodeForm, promoCode }
}
