import { ThemeOptions, createTheme } from '@mui/material/styles'
import { muiConfig } from '@rent/ui/config'

const theme = createTheme({
  breakpoints: muiConfig.breakpoints,
  palette: muiConfig.colors,
  typography: muiConfig.typography,
  components: muiConfig.components,
  ...muiConfig.otherConfig,
} as ThemeOptions)

export default theme
