import { SxProps, Theme } from '@mui/material'

export const containerStyle: SxProps<Theme> = {
  bgcolor: 'common.white',
}

export const titleStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: 'center',
  justifyContent: 'space-between',
  py: 2,
}
