import React, { ComponentType, ReactNode, useContext } from 'react'
import { useIntl } from 'react-intl'

import { Typography } from '@mui/material'
import { BasicDialog, FailedDialog } from '@rent/ui'
import { NotificationsContext, NotificationsContextProvider, notifyClose } from 'context'

const NotificationsWrapper = ({ children }: { children: ReactNode }) => {
  const { formatMessage } = useIntl()
  const { notification, dispatchNotification } = useContext(NotificationsContext)

  const notificationContent = notification.content && (
    <Typography color="text.secondary" textAlign="center" sx={{ whiteSpace: 'pre-line' }}>
      {notification.content}
    </Typography>
  )
  return (
    <>
      {notification.open && notification.type === 'error' && (
        <FailedDialog
          open
          title={notification.title ?? ''}
          actions={
            notification.actions ?? [
              {
                label: formatMessage({ id: 'button.try_later' }),
                onClick: () => notifyClose(dispatchNotification),
              },
            ]
          }
        >
          {notificationContent}
        </FailedDialog>
      )}
      {notification.open && notification.type === 'success' && (
        <BasicDialog open title={notification.title ?? ''} actions={notification.actions ?? []}>
          {notificationContent}
        </BasicDialog>
      )}
      {notification.open && notification.type === 'loading' && (
        <>
          {notification.title ? (
            <BasicDialog open loading title={notification.title} actions={notification.actions ?? []}>
              {notificationContent}
            </BasicDialog>
          ) : (
            <BasicDialog open loading paperSxProps={{ bgcolor: 'inherit', border: 'none' }}></BasicDialog>
          )}
        </>
      )}
      {children}
    </>
  )
}

function withNotifications<T extends object>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    return (
      <NotificationsContextProvider>
        <NotificationsWrapper>
          <WrappedComponent {...props} />
        </NotificationsWrapper>
      </NotificationsContextProvider>
    )
  }
}

export { withNotifications }
