import { useContext } from 'react'
import { useIntl } from 'react-intl'

import { usePostAcceptOffer } from 'components/pages/api'
import { NotificationsContext, notifyError } from 'context'

interface AcceptOffer {
  externalId: string
  refetchOrderData: () => void
}

export const useAcceptOffer = ({ externalId, refetchOrderData }: AcceptOffer) => {
  const { formatMessage } = useIntl()
  const { dispatchNotification } = useContext(NotificationsContext)

  const handleSuccessAcceptOffer = () => {
    refetchOrderData()
  }
  const handleErrorAcceptOffer = () => {
    notifyError(dispatchNotification, {
      title: formatMessage({ id: 'notification.dear_customer' }),
      content: formatMessage({ id: 'notification.default_error_page_reload' }),
      actions: [
        {
          label: formatMessage({ id: 'button.reload_page' }),
          onClick: () => {
            window.location.reload()
          },
        },
      ],
    })
  }

  const postAcceptOffer = usePostAcceptOffer(externalId, handleErrorAcceptOffer, handleSuccessAcceptOffer)

  return { postAcceptOffer }
}
