const payment = {
  autopay: 'AutoPay',
  payment_from_business_account: '{value} - Płatność z konta firmowego',
  payment_from_other_than_business_account: '{value} - Płatność z konta innego niż firmowe',
  fast_processing: 'Najszybsza realizacja zamówienia dzięki automatycznej weryfikacji.',
  not_so_fast_processing: 'Wydłużony czas realizacji do 3 dni roboczych. Konieczna będzie dodatkowa weryfikacja tożsamości.',
  payment_must_be_made_from_a_business_account:
    'Tylko przelew z konta firmowego weryfikuje tożsamość, inne sposoby płatności wymagają dodatkowej weryfikacji',
  debit_note: 'Nota księgowa',
  payment_processing_takes_up_to_several_business_days: '{value} - Realizacja do 7 dni roboczych',
  debit_note_will_be_sent: 'Nota księgowa zostanie wystawiona w przeciągu 24h.',
  you_will_be_redirected_to_the_payment_screen: 'Za chwilę zostaniesz przekierowany do AutoPay w celu opłacenia kaucji.',
}

export { payment }
