import { ChangeEvent, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import Typography from '@mui/material/Typography'
import { ControlledCheckbox } from '@rent/ui'

interface SelectAllProps<T> {
  fields: T & { selectAll: string }
  disabledFields?: { [key: string]: boolean }
}

/**
 * The `SelectAll` function is a React component that renders a checkbox to select all checkboxes
 * in a form.
 * @param  - The `SelectAllProps` interface is a generic interface that takes a type parameter `T`.
 * It has a property `fields` which is of type `T` intersected with an object
 * that has a property `selectAll` of type `string`.
 * And property `disabledFields` which blocks selecting specific items
 */
const SelectAll = <T extends object>({ fields, disabledFields = {} }: SelectAllProps<T>) => {
  const { formatMessage } = useIntl()

  const { control, setValue, clearErrors } = useFormContext()

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    Object.keys(fields).forEach(el => {
      if (!disabledFields[el]) {
        setValue(el, e.target.checked)
        if (e.target.checked) {
          clearErrors(el)
        }
      }
    })
  }
  const watchedCheckboxes = useWatch({ control, name: Object.keys(fields) }).slice(1)
  const watchedSelectAll = useWatch({ control, name: fields.selectAll })

  useEffect(() => {
    if (watchedCheckboxes.every(el => el) && !watchedSelectAll) {
      setValue(fields.selectAll, true)
    }
    if (!watchedCheckboxes.every(el => el) && watchedSelectAll) {
      setValue(fields.selectAll, false)
    }
  }, [fields, setValue, watchedCheckboxes, watchedSelectAll])

  return (
    <>
      <Typography variant="h4" sx={{ mb: 0.8 }}>
        {formatMessage({ id: 'customer.formal_consents' })}
      </Typography>
      <Typography variant="caption" component="p" sx={{ mb: 2.4 }}>
        <Typography variant="inherit" component="span" color="error">
          *{' '}
        </Typography>
        {formatMessage({ id: 'customer.required_consents' })}
      </Typography>

      <ControlledCheckbox
        fieldName={fields.selectAll}
        label={
          <Typography variant="body2" component="span" fontWeight={700}>
            {formatMessage({ id: 'button.select_all' })}
          </Typography>
        }
        onChangeOverride={handleSelectAll}
        control={control}
      />
    </>
  )
}

export default SelectAll
