const message = {
  contract_form: {
    title: 'Jaka jest forma umowy?',
    description:
      'Forma umowy to Generalna Umowa Subskrypcji. Jest to jedna umowa dotycząca usługi, a każdy kolejny wynajęty przez Ciebie przedmiot jest jednostronicowym załącznikiem w postaci umowy subskrypcji.',
  },
  why_we_need_your_data: {
    title: 'Dlaczego potrzebujemy Twoich danych?',
    description:
      'Poprosimy Ciebie o podanie danych potrzebnych do weryfikacji oraz o akceptację umowy. Następnie otrzymasz mail z umową i prośbę o wpłatę kaucji.',
  },
  why_it_worth_to_subscribe: {
    title: 'Dlaczego warto subskrybować swój sprzęt?',
    description: `
    - Płacisz jedynie za korzystanie ze sprzętu, a nie za jego pełną wartość\n
    - Dzięki krótkiej umowie masz pewność częstej wymiany sprzętu\n
    - Z pakietami ochronnymi SafeUp i SafeUp+ nie musisz się martwić o jakiekolwiek uszkodzenie Twojego sprzętu\n
    - Otrzymujesz jedną fakturę VAT, którą wygodnie księgujesz w koszty prowadzenia działalności\n
  `,
  },
  how_it_works: {
    title: 'W jaki sposób odbywa się zawarcie umowy?',
    description:
      'Umowę zawieramy całkowicie online. Po wygenerowaniu oferty wyślemy Ci mail z potwierdzeniem wybranych przez Ciebie przedmiotów oraz z linkiem do dokończenia transakcji. Wystarczy podać dane swojej firmy. Umowę generujemy automatycznie, a jej podpisanie odbywa się również on-line.',
  },
}

export { message }
