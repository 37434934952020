import { memo, useContext, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Box, Divider, Typography } from '@mui/material'
import { RadioAccordionGroup, Spinner } from '@rent/ui'
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg'
import { ReactComponent as AutoPayLogo } from 'assets/images/logo-autopay.svg'
import { useGetShowPayment } from 'components/pages/api'
import { Currency } from 'components/shared'
import { PaymentType } from 'consts'
import { OrderContext } from 'context'

import { formBoxStyle } from '../styles'
import { paymentLogoStyle } from './styles'

interface PaymentBoxProps {
  deposit: number
  disabled: boolean
}

const PaymentBox = memo(({ deposit }: PaymentBoxProps) => {
  const { formatMessage } = useIntl()
  const { contextData, selectedPayment, handleChangesSelectedPayment } = useContext(OrderContext)

  const { data: paymentAvailable, isLoading, isSuccess, isError } = useGetShowPayment(contextData.externalId)

  useEffect(() => {
    if (isSuccess && paymentAvailable) {
      handleChangesSelectedPayment(PaymentType.AUTOPAY_BUSINESS_ACCOUNT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAvailable, isSuccess])

  const paymentData = useMemo(() => {
    const onlinePayments = [
      {
        title: formatMessage(
          { id: 'payment.payment_from_business_account' },
          { value: <strong>{formatMessage({ id: 'payment.autopay' })}</strong> },
        ),
        subtitle: formatMessage({ id: 'payment.fast_processing' }),
        key: PaymentType.AUTOPAY_BUSINESS_ACCOUNT,
        id: PaymentType.AUTOPAY_BUSINESS_ACCOUNT + '-radio',
        rightText: (
          <Box sx={paymentLogoStyle}>
            <AutoPayLogo />
          </Box>
        ),
      },
      {
        title: formatMessage(
          { id: 'payment.payment_from_other_than_business_account' },
          { value: <strong>{formatMessage({ id: 'payment.autopay' })}</strong> },
        ),
        subtitle: formatMessage({ id: 'payment.not_so_fast_processing' }),
        key: PaymentType.AUTOPAY_OTHERS,
        id: PaymentType.AUTOPAY_OTHERS + '-radio',
        rightText: (
          <Box sx={paymentLogoStyle}>
            <AutoPayLogo />
          </Box>
        ),
      },
    ]
    const insurances: {
      title: React.ReactNode
      subtitle: string
      key: string
      id: string
      rightText?: React.ReactNode
    }[] = paymentAvailable
      ? contextData.isLoan
        ? onlinePayments
        : [
            ...onlinePayments,
            {
              title: formatMessage(
                { id: 'payment.payment_processing_takes_up_to_several_business_days' },
                { value: <strong>{formatMessage({ id: 'payment.debit_note' })}</strong> },
              ),
              subtitle: formatMessage({ id: 'payment.debit_note_will_be_sent' }),
              key: PaymentType.DEBIT_NOTE,
              id: PaymentType.DEBIT_NOTE + '-radio',
            },
          ]
      : []

    return insurances
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAvailable])

  return (
    <Box>
      {isLoading && !isSuccess && <Spinner color="primary.main" />}
      {paymentAvailable && !isError && selectedPayment && (
        <Box sx={formBoxStyle}>
          <Typography variant="h4" sx={{ mb: 2.4 }}>
            <Typography variant="inherit" component="span" fontWeight={400} mr={0.4}>
              {formatMessage({ id: 'message.to_pay_now' })}:
            </Typography>
            <Currency value={deposit} mth={false} net={null} />
          </Typography>
          <Box>
            <Box color="text.secondary" display="flex" alignItems="center" mb={1.6}>
              <LockIcon fill="inherit" />
              <Typography variant="caption" ml={0.8}>
                {formatMessage({ id: 'message.secure_SSL_payment' })}
              </Typography>
            </Box>
            <Divider sx={{ my: 2.4 }} />

            <RadioAccordionGroup
              items={paymentData}
              sx={{ '&:not(:last-child)': { mb: 1 } }}
              selectedValue={selectedPayment}
              onChange={val => handleChangesSelectedPayment(val as typeof selectedPayment)}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
})

export default PaymentBox
