const form = {
  firstName_label: 'Imię',
  lastName_label: 'Nazwisko',
  firstName_lastName_label: 'Imię i nazwisko',
  nip_label: 'Numer NIP',
  buyerEmail_label: 'Adres e-mail',
  buyerPhone_label: 'Numer telefonu',
  firstName_placeholder: 'Podaj imię',
  lastName_placeholder: 'Podaj nazwisko',
  nip_placeholder: 'Podaj numer NIP',
  buyerEmail_placeholder: 'Podaj adres e-mail',
  buyerPhone_placeholder: 'Podaj numer telefonu',

  purpose_of_asking_for_a_phone_number: 'Numer telefonu wykorzystujemy do weryfikacji umowy.',

  agreementRodo_label: 'Oświadczam, że zapoznałem się z treścią klauzuli informacyjnej dostępnej na stronie internetowej {link}.',

  agreementStatute_label: 'Zapoznałem się z treścią i warunkami {link} i zobowiązuje się do niego bezwarunkowo stosować.',

  agreementKrd_label: 'Wyrażam zgodę na weryfikację.',
  agreementKrd_label_expanded:
    'Wyrażam zgodę na weryfikację i upoważniam WeSub S.A. z siedzibą w Warszawie do wystąpienia do Krajowego Rejestru Długów Biura Informacji Gospodarczej S.A. o ujawnienie informacji gospodarczych dotyczących mojej osoby.',

  agreementEmailCommunication_label: 'Wyrażam zgodę na komunikację e-mail.',
  agreementEmailCommunication_label_expanded:
    'Wyrażam zgodę na przetwarzanie przez WeSub S.A. moich danych osobowych w postaci adresu poczty elektronicznej w celu przesyłania mi informacji marketingowych dotyczących produktów i usług oferowanych przez WeSub S.A. za pomocą środków komunikacji elektronicznej, stosownie do treści przepisu art. 10 ust. 1 i 2 ustawy o świadczeniu usług drogą elektroniczną.',

  agreementPhoneCommunication_label: 'Wyrażam zgodę na komunikację telefoniczną.',
  agreementPhoneCommunication_label_expanded:
    'Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci podanego przeze mnie numeru telefonu przez WeSub S.A. w celu prowadzenia działań marketingowych  przy użyciu telekomunikacyjnych urządzeń końcowych oraz automatycznych systemów wywołujących w rozumieniu ustawy Prawo telekomunikacyjne.',
  provide_promocode_placeholder: 'Wpisz kod promocyjny',
  representative_label: 'Reprezentant',
  representative_placeholder: 'Wybierz reprezentanta',
  pesel_label: 'PESEL',
  pesel_placeholder: 'Podaj PESEL',
  identityCardNumber_label: 'Seria i numer dowodu osobistego',
  identityCardNumber_placeholder: 'Podaj serie i numer dowodu osobistego',
  identityCardValidFrom_label: 'Data wydania dowodu osobistego',
  identityCardValidTo_label: 'Data ważności dowodu osobistego',

  returnAccountNumber_label: 'Numer rachunku do zwrotu kaucji',
  returnAccountNumber_placeholder: 'Podaj numer rachunku do zwrotu kaucji',

  agreementAdministrator_label: 'Oświadczam, że zapoznałem się z treścią klauzuli informacyjnej.',
  agreementAdministrator_label_expanded:
    'Administratorem danych osobowych dotyczących Usługobiorcy oraz osób zaangażowanych po jego stronie w realizację Generalnej Umowy Subskrypcji oraz Umowy Subskrypcji jest WeSub S.A. z siedzibą w Warszawie przy ul. Branickiego 17.  Dane osobowe przetwarzane są w celu prawidłowej realizacji  Generalnej Umowy Subskrypcji oraz Umowy Subskrypcji na podstawie art. 6 ust.1 pkt b) RODO oraz dane osobowe będą przetwarzane na podstawie art. 6 ust.1 pkt c) RODO oraz art. 6 ust.1 pkt f) RODO. Podstawą przetwarzania danych osobowych jest zawarcie i wykonanie Generalnej Umowy Subskrypcji oraz Umowy Subskrypcji. Usługobiorcy przysługuje prawo dostępu do treści swoich danych zgodnie z art. 15 RODO, prawo żądania sprostowania danych osobowych, usunięcia, ograniczenia przetwarzania zgodnie z art. 18 RODO, prawo do przenoszenia danych i prawo do wniesienia sprzeciwu wobec przetwarzania danych jak i również prawo do wniesienia skargi do organu nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych. Pełna treść klauzuli informacyjnej jest dostępna na stronie internetowej: {link} Jednocześnie oświadczam, że zapoznałem się z treścią klauzuli informacyjnej znajdującej się na ww. stronie internetowej.',

  agreementAdministratorLoan_label: 'Oświadczam, że zapoznałem się z treścią klauzuli informacyjnej.',
  agreementAdministratorLoan_label_expanded:
    'Administratorem danych osobowych dotyczących Pożyczkobiorcy oraz osób zaangażowanych po jego stronie w realizację Umowy Pożyczki jest WeSub S.A. z siedzibą w Warszawie przy ul. Branickiego 15. Dane osobowe przetwarzane są w celu prawidłowej realizacji Umowy Pożyczki na podstawie art. 6 ust.1 pkt b) RODO oraz dane osobowe będą przetwarzane na podstawie art. 6 ust.1 pkt c) RODO oraz art. 6 ust.1 pkt f) RODO. Podstawą przetwarzania danych osobowych jest zawarcie i wykonanie Umowy Pożyczki. Pożyczkobiorcy przysługuje prawo dostępu do treści swoich danych zgodnie z art. 15 RODO, prawo żądania sprostowania danych osobowych, usunięcia, ograniczenia przetwarzania zgodnie z art. 18 RODO, prawo do przenoszenia danych i prawo do wniesienia sprzeciwu wobec przetwarzania danych jak i również prawo do wniesienia skargi do organu nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych. Pełna treść klauzuli informacyjnej jest dostępna na stronie internetowej: {link} Jednocześnie oświadczam, że zapoznałem/zapoznałam się z treścią klauzuli informacyjnej znajdującej się na ww. stronie internetowej.',

  agreementContractor_label: 'Z uwagi na wymogi dotyczące identyfikacji statusu kontrahenta.',
  agreementContractor_label_expanded:
    'Z uwagi na wymogi dotyczące identyfikacji statusu kontrahenta wynikające z Ustawy o Prawach konsumenta z dnia 30 maja 2014 r. (Dz.U.2020.287 t.j. ze zm.), uprzejmie prosimy o zaznaczenie pola potwierdzającego, że zgodnie z danymi zawartymi w szczególności w Centralnej Ewidencji i Informacji o Działalności Gospodarczej, Umowa Subskrypcji jest zawierana bezpośrednio w związku z działalnością gospodarczą Użytkownika i posiada ona dla niego charakter zawodowy. Oświadczam, że powyższe oświadczenie jest zgodne ze stanem faktycznym oraz prawnym. Nieprawdziwe oświadczenie, wprowadzające Usługodawcę w błąd po wcześniejszym złożeniu oświadczenia, może stanowić podstawę do odpowiedzialności odszkodowawczej oraz uprawnienie Usługodawcy do domagania się naprawienia wynikłej z takiego zaniechania po jego stronie szkody.',

  agreementAcceptance_label:
    'Zapoznałem się z treścią Generalnej Umowy Subskrypcji i Umowy Subskrypcji oraz warunkami regulaminu i akceptuję ich warunki.',
  agreementAcceptance_label_expanded:
    'Zapoznałem się z treścią Generalnej Umowy Subskrypcji {frameworkAgreementExposedId} (dalej: Generalna Umowa Subskrypcji), Umowy Subskrypcji {leaseAgreementExposedId} (dalej: Umowa Subskrypcji) i warunkami {link1} oraz akceptuję wszystkie warunki wynikające z powyższych umów. Zobowiązuję się do przestrzegania i wykonywania postanowień wynikających z Generalnej Umowy Subskrypcji, Umowy Subskrypcji oraz z załączników do wyżej wskazanych umów, które stanowią ich integralną część. Oświadczam i gwarantuję, iż nie ma żadnych ograniczeń do zawarcia wyżej wskazanych umów, a osoba składająca niniejsze oświadczenie woli w formie elektronicznej jest właściwie upoważniona. Przyjmuję do wiadomości, iż zgodnie z dyspozycją przepisu z art. {art} Kodeksu Cywilnego, oświadczenie woli złożone w sposób elektroniczny, tj.: w tym przypadku poprzez zaznaczenie Checkbox lub przekazanie Kodu SMS jest równoważne z oświadczeniem woli złożonym w formie dokumentowej oraz potwierdzeniem woli zawarcia Generalnej Umowy Subskrypcji oraz Umowy Subskrypcji.',

  agreementAcceptanceLoan_label: 'Zapoznałem się z treścią Umowy Pożyczki oraz warunkami regulaminu i akceptuję ich warunki.',
  agreementAcceptanceLoan_label_expanded:
    'Zapoznałem/Zapoznałam się z treścią Umowy Pożyczki GUS/2024/08/00008/PO01 (dalej: Umowa Pożyczki) i warunkami {link1} tabelą opłat i prowizji oraz akceptuję wszystkie warunki wynikające z powyższej umowy i załączników. Zobowiązuję się do przestrzegania i wykonywania postanowień wynikających z Umowy Pożyczki oraz z załączników do niej integralnych. Oświadczam i gwarantuję, iż nie ma żadnych ograniczeń do zawarcia wyżej wskazanej umowy, a osoba składająca niniejsze oświadczenie woli w formie elektronicznej jest właściwie upoważniona. Przyjmuję do wiadomości, iż zgodnie z dyspozycją przepisu z art. {art} Kodeksu Cywilnego, oświadczenie woli złożone w sposób elektroniczny, tj.: w tym przypadku poprzez zaznaczenie Checkbox lub przekazanie Kodu SMS jest równoważne z oświadczeniem woli złożonym w formie dokumentowej oraz potwierdzeniem woli zawarcia Umowy Pożyczki.',

  of_terms_and_toip: 'regulaminu oraz tabelą opłat i prowizji',
  want_to_exchange_item_from_another_agreement: 'Chcę wymienić przedmiot z innej Umowy Subskrypcji',
  item_exchange_label: 'Numer umowy, której przedmiot podlega wymianie',
}

export { form }
