import { useEffect, useState } from 'react'

import { IOrderProduct } from 'models'

interface CheckItemsAvailable {
  items: IOrderProduct[] | undefined
  isLoading: boolean
}
export const useCheckItemsAvailable = ({ items, isLoading }: CheckItemsAvailable) => {
  const [allItemsAvailable, setAllItemsAvailable] = useState<boolean | null>(null)

  useEffect(() => {
    if (!isLoading && items && items?.length > 0) {
      const isNotAvailable = Boolean(items?.find(x => x.product?.stock === 0))
      setAllItemsAvailable(!isNotAvailable)
    }
  }, [items, isLoading])

  return { allItemsAvailable }
}
