import { useCookies } from 'react-cookie'
import { useIntl } from 'react-intl'

import { Box, IconButton, Link, Slide, Typography } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg'
import { COOKIE_GENERAL, GeneralRoute } from 'consts'

import { bannerStyle } from './styles'

const CookieBanner = () => {
  const { formatMessage } = useIntl()
  const [cookies, setCookie] = useCookies([COOKIE_GENERAL])

  const setExpireDate = (month: number) => {
    const currentDate = new Date()
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + month,
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds(),
    )
  }
  const onHideBanner = () => {
    const expires = setExpireDate(6)
    setCookie(COOKIE_GENERAL, true, { expires, path: '/' })
  }

  return (
    <>
      <Slide in={!cookies[COOKIE_GENERAL]} direction="up">
        <Box sx={bannerStyle}>
          <Typography variant="body2" color="grey.100">
            {formatMessage({ id: 'message.cookie_content' })}{' '}
            <Link href={`/${GeneralRoute.PRIVACY_POLICY}`} target="_blank">
              {formatMessage({ id: 'button.more' })}
            </Link>
          </Typography>
          <IconButton
            disableRipple={false}
            sx={{
              color: 'grey.300',
              ml: 1.6,
              '& svg': { width: '1.6rem', height: 'auto' },
            }}
            onClick={onHideBanner}
          >
            <CloseIcon fill="inherit" />
          </IconButton>
        </Box>
      </Slide>
    </>
  )
}

export default CookieBanner
