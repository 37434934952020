const notification = {
  page_not_found: 'Nie znaleziono strony',
  page_forbidden: 'Brak dostępu',
  page_forbidden_content:
    'Twoje uprawnienia nie dają Ci dostępu do przeglądania tej strony.\nJeżeli to błąd skontaktuj się z nami pod adresem e-mail {email}',
  page_not_data: 'Nie znaleziono danych',
  unexpected_error: 'Wystąpił nieoczekiwany błąd',
  unexpected_error_try_later: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
  access_denied: 'Nieautoryzowany dostęp',
  data_loading_error: 'Błąd podczas ładowania danych',
  required_field: 'Pole wymagane',
  max_length_small: 'Maksymalnie 100 znaków',
  max_length_medium: 'Maksymalnie 200 znaków',
  max_length_large: 'Maksymalnie 500 znaków',
  email_invalid: 'Niepoprawny e-mail',
  phoneNumber_invalid: 'Niepoprawny numer telefonu',
  phoneNumber_invalid_pl: 'Numer telefonu musi mieć 9 cyfr',
  nip_invalid: 'Niepoprawny numer NIP',
  pesel_invalid: 'Niepoprawny PESEL',
  identity_card_number_invalid: 'Niepoprawny numer dowodu osobistego',
  invalid_date: 'Niepoprawna data',
  identity_card_valid_from_invalid: 'Data wydania dowodu osobistego jest błędna',
  identity_card_valid_to_invalid: 'Data ważności dowodu osobistego jest błędna',
  return_account_number_invalid: 'Niepoprawny numer rachunku bankowego',
  replacement_agreement_number_invalid: 'Niepoprawny numer umowy',
  promocode_not_found: 'Podany kod promocyjny nie istnieje',
  promocode_expired: 'Kod promocyjny jest przeterminowany',
  promocode_applied: 'Produkty objęte kodem promocyjnym {val} zostały przeliczone',
  super_promocode_applied: 'Kod promocyjny {val} został pomyślnie zastosowany!',

  error: 'Błąd',
  bad_data: 'Błędne dane',
  dear_customer: 'Drogi Kliencie',
  default_error: 'Wygląda na to, że podczas procesowania Twojego zamówienia wystąpił nieoczekiwany błąd. Prosimy spróbować za chwilę.',
  default_error_page_reload:
    'Wygląda na to, że podczas procesowania Twojego zamówienia wystąpił nieoczekiwany błąd. Prosimy spróbować za chwilę lub odświeżyć stronę.',

  contract_generation_error: 'Niestety podczas generowania Twojej Umowy wystąpił nieoczekiwany błąd. Prosimy spróbować za chwilę.',
  contract_generation_loading: 'Trwa generowanie umowy',
  please_wait_several_sec: 'Proszę czekać, może to zająć kilkanaście sekund.',
  bad_customer_data_content:
    'Zgodnie z naszą najlepszą wiedzą podane przez Ciebie dane są błędne. Zweryfikuj poprawność danych w zakresie serii i numeru dowodu, daty wydania i ważności dokumentu, PESEL oraz imienia i nazwiska. Jeżeli wszystko jest w porządku prosimy o kontakt pod adresem e-mail biuro@wesub.pl.',
  default_customer_name_selected_error:
    'Wygląda na to, że nie potrafimy znaleźć Twojego nazwiska na liście uprawnionych do reprezentowania wskazanej firmy. Jeżeli to błąd skontaktuj się z nami pod adresem e-mail biuro@wesub.pl.',
  ags_printer_error:
    'Dziękujemy za zainteresowanie naszą ofertą. Informujemy, że otrzymaliśmy Twój wniosek i postaramy się jak najszybciej wydać decyzję. Skontaktuje się z Tobą nasz konsultant.',
  create_order_default_error:
    'Wygląda na to, że podczas procesowania Twojego zamówienia wystąpił nieoczekiwany błąd. Prosimy spróbować za chwilę.',
  data_verification_title: 'Wczytywanie danych kontrahenta',
  data_verification_content: 'Trwa weryfikacja NIP, proszę czekać...',
  data_verification_title_couldnt_check:
    'Niestety nie udało nam się pobrać danych niezbędnych do przygotowania oferty. Prosimy spróbować przeprocesować zamówienie w sklepie jeszcze raz.',
  data_verification_title_check_error:
    'Niestety nie udało nam się pobrać danych niezbędnych do przygotowania oferty. Prosimy spróbować przeprocesować zamówienie w sklepie jeszcze raz.\n\nJeżeli to błąd skontaktuj się z nami pod adresem e-mail biuro@wesub.pl.',
  nip_verification_success_title: 'Weryfikacja przebiegła pomyślnie',
  nip_verification_success_content: 'Dziękujemy za cierpliwość.\nZa chwile nastąpi przeniesienie na ekran generowania umowy.',
  title: 'Dziękujemy za cierpliwość',
  generic_error:
    'Prosimy spróbować za chwilę lub odświeżyć stronę.\nJeżeli to błąd skontaktuj się z nami pod adresem e-mail biuro@wesub.pl.',
  accept_contract_default_error:
    'Wygląda na to, że podczas procesowania Twojego zamówienia wystąpił nieoczekiwany błąd. Prosimy spróbować za chwilę.',
  you_will_be_redirected_to_the_next: 'Za chwilę zostaniesz przekierowany do kolejnego kroku',
  data_verification: 'Trwa weryfikacja danych',
  thank_you_for_your_order: 'Dziękujemy za złożenie zamówienia',
  order_finished: 'Twoje zamówienie jest zakończone, zapoznaj się z resztą asortymentu.',
  order_not_found: 'Nie znaleziono zamówienia',
  order_not_found_content: 'Niestety nie udało się odnaleźć twojego zamówienia. Wygeneruj nową ofertę.',
  order_cancelled: 'Niestety nie udało się odnaleźć twojego zamówienia. Wygeneruj nową ofertę.',
  processing_debit_note:
    'Procesujemy teraz Twoją prośbę o wystawienie noty księgowej. Wrócimy do Ciebie z odpowiedzią mailową w przeciągu 24h.',
  processing_extension: 'Wszystko już gotowe! Dziękujemy za przedłużenie Umowy Subskrypcji na wybrany produkt.',
  contact_us_if_any_question: 'W razie wszelkich pytań zapraszamy na {email}',
  we_need_more_time_to_process_your_order:
    'Niestety potrzebujemy więcej czasu na zweryfikowanie Twojej Umowy. \nWrócimy do Ciebie z informacją mailową.',
  order_processing_generic_error:
    'Wygląda na to, że podczas procesowania Twojego zamówienia wystąpił nieoczekiwany błąd.\nPotrzebujemy więcej czasu na zweryfikowanie Twojej Umowy. \nWrócimy do Ciebie z informacją mailową.',
  do_not_close_browser_window: 'Proszę nie zamykać okna przeglądarki.',
  downloading_agreement_template: 'Trwa generowanie wzoru umowy',
  downloading_agreement_template_content: 'Po wygenerowaniu wzór umowy zostanie automatycznie pobrany w formacie pdf.',
  downloading_agreement_template_error:
    'Niestety podczas generowania wzoru umowy wystąpił nieoczekiwany błąd. Prosimy spróbować za chwilę.',

  replacement_agreement_not_possible:
    'Numer istniejącej umowy jest błędny lub przedmiot umowy nie podlega wymianie. \nNadal możesz zawrzeć nową umowę.',
  replacement_agreement_applied_successfully: 'Produkt z tej umowy zostanie zastąpiony po podpisaniu umowy',
  replacement_agreement_no_must_be_verified: 'Numer umowy musi być zweryfikowany',
  agreement_generated_but_not_accepted:
    'Wygląda na to, że wygenerowałeś umowę, która nie została zaakceptowana. Prosimy spróbować przeprocesować zamówienie w sklepie jeszcze raz.',
  pdf_loading_error: 'Podczas ładowania umowy wystąpił wystąpił nieoczekiwany błąd. Prosimy spróbować za chwilę.',
  products_not_available:
    'Jeden lub więcej produktów w twoim koszyku nie są już dostępne. Prosimy spróbować przeprocesować zamówienie w sklepie jeszcze raz.',
  read_the_agreement: 'Zapoznaj się z umową',
  payment_success: 'Dziękujemy za wpłatę kaucji.\nPrzekażemy Twoje zamówienie do realizacji po zweryfikowaniu płatności.',
  waiting_for_payment:
    'Wygląda na to, że jeszcze nie wpłaciłeś kaucji.\nJeśli nie otrzymałeś linku do płatności skontaktuj się z nami pod adresem e-mail lub telefonicznie.',
  auto_return_to_the_store: 'Powrót do sklepu za: {val} sekund',
  data_loading: 'Trwa ładowanie danych',
  verification_code_generation_error: 'Niestety podczas podpisywania umowy wystąpił nieoczekiwany błąd.\nProsimy spróbować za chwilę.',
  agreement_signed_title: 'Umowa została pomyslnie podpisana!',
  agreement_signed_content: 'Za chwilę zostaniesz przekierowany do ekranu finalizacji.',
  product_not_found:
    'Niestety nie udało się odnaleźć wybranego przez Ciebie produktu. \nJeżeli to błąd skontaktuj się z nami pod adresem e-mail biuro@wesub.pl.',
}

export { notification }
