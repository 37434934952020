import ApiEndpoints from 'api/endpoints'
import { useRequest } from 'hooks'
import { CustomerOrderAgreementInfoDto } from 'models/dto'

const maxUnsuccessfulRequests = 3

export const useGetOrderVerifyCustomer = (orderId: string) => {
  return useRequest<CustomerOrderAgreementInfoDto>(ApiEndpoints.getOrderVerifyCustomer(orderId), {
    retry: maxUnsuccessfulRequests,
    enabled: !!orderId,
  })
}
