import ApiEndpoints from 'api/endpoints'
import { useRequest } from 'hooks'

export type ClientConsents = {
  electronicProcessingAccepted?: boolean
  phoneProcessingAccepted?: boolean
  datePlaced?: string
}

export const useGetCheckClientConsents = (nip: string) => {
  return useRequest<ClientConsents>(ApiEndpoints.getCheckClientConsents(nip), { enabled: !!nip, retry: 1 })
}
