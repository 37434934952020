import ApiEndpoints from 'api/endpoints'
import { RequestParams, useRequest } from 'hooks'
import { CustomerOrderPaymentLinkDto } from 'models/dto'

const maxUnsuccessfulRequests = 3

export const useGetBlueMediaLink = (orderId: string, reqParams?: RequestParams) => {
  return useRequest<CustomerOrderPaymentLinkDto>(ApiEndpoints.getBlueMediaLink(orderId), {
    retry: maxUnsuccessfulRequests,
    ...reqParams,
  })
}
