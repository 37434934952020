import { createSearchParams, useNavigate } from 'react-router-dom'

import { GeneralRoute, RouteQueryPluginParam, StepRoute } from 'consts'
import { useRouteQuery } from 'hooks'

export const usePluginRouteParams = () => {
  const routerQuery = useRouteQuery()
  const navigate = useNavigate()

  const apiBannerParam = routerQuery.get(RouteQueryPluginParam.apiBannerParam)
  const method = routerQuery.get(RouteQueryPluginParam.method)
  const clientProductId = routerQuery.get(RouteQueryPluginParam.clientProductId)
  const generatedBannerId = routerQuery.get(RouteQueryPluginParam.generatedBannerId)
  const clientSellPrice = routerQuery.get(RouteQueryPluginParam.clientSellPrice)

  const routeParams = {
    apiBannerParam: apiBannerParam ?? '',
    method: method ?? '',
    clientProductId: clientProductId ?? '',
    generatedBannerId: generatedBannerId ?? '',
    clientSellPrice: clientSellPrice ?? '',
  }

  const navigateTo = (stepRoute: StepRoute) => {
    navigate({
      pathname: `/${GeneralRoute.ORDER_PLUGIN}/${stepRoute}`,
      search: `?${createSearchParams(routeParams)}`,
    })
  }

  return {
    navigateTo,
    routeParams,
  }
}
