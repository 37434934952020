import { useEffect, useMemo, useState } from 'react'

import { STORAGE_ORDER_PRODUCTS } from 'consts'
import { checkAllProductsMatchInterface } from 'helpers'
import { IMappedProduct, IOrderProduct } from 'models'

interface StorageOrderProductsData {
  items: IOrderProduct[] | undefined
  orderId: string | undefined
  refetchOrderData: () => void
}

/**
 * The `useStorageOrderProductsData` function is a custom hook that manages the storage and retrieval
 * of order product data, including handling data from session storage and triggering a data refetch
 * when necessary.
 * @returns The function `useStorageOrderProductsData` returns an object with two properties:
 * `orderProductsData` and `setOrderProductsData`.
 */
export const useStorageOrderProductsData = ({ items, orderId, refetchOrderData }: StorageOrderProductsData) => {
  const [orderProductsData, setOrderProductsData] = useState<IMappedProduct[]>([])

  const storedData = useMemo(() => {
    const storageData = sessionStorage.getItem(STORAGE_ORDER_PRODUCTS)
    const parsedStorageData = storageData && JSON.parse(storageData)
    const isMatchInterface = parsedStorageData?.orderProductsData && checkAllProductsMatchInterface(parsedStorageData.orderProductsData)
    return isMatchInterface ? parsedStorageData : null
  }, [])

  useEffect(() => {
    if (items) {
      if (storedData && storedData.orderId === orderId) {
        setOrderProductsData(storedData.orderProductsData)
      } else {
        sessionStorage.removeItem(STORAGE_ORDER_PRODUCTS)
        refetchOrderData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, storedData])

  return {
    orderProductsData,
    setOrderProductsData,
  }
}
