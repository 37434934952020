import { useContext } from 'react'
import { useIntl } from 'react-intl'

import { Box, Container, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import ApiEndpoints from 'api/endpoints'
import { useGetAgreementTemplate } from 'components/pages/api'
import { DownloadFileButton } from 'components/shared'
import { NotificationsContext, notifyClose, notifyError, notifyLoading } from 'context'
import { AGREEMENT_TYPE } from 'models'

import { containerStyle, titleStyle } from './styles'

interface StepSubHeaderProps {
  isLoan: boolean
}

const StepSubHeader = ({ isLoan }: StepSubHeaderProps) => {
  const { formatMessage } = useIntl()
  const { dispatchNotification } = useContext(NotificationsContext)

  const agreementType = isLoan ? AGREEMENT_TYPE.FRAMEWORK_AND_LEASE_AGREEMENT_CREDIT_B2B : AGREEMENT_TYPE.FRAMEWORK_AND_LEASE_AGREEMENT

  const { refetch } = useGetAgreementTemplate(agreementType, { enabled: false, isCancelable: true })
  const queryClient = useQueryClient()

  const onDownloadAgreementTemplate = async () => {
    notifyLoading(dispatchNotification, {
      title: formatMessage({ id: 'notification.downloading_agreement_template' }),
      content: formatMessage({ id: 'notification.downloading_agreement_template_content' }),
      actions: [
        {
          label: formatMessage({ id: 'button.cancel' }),
          onClick: () => {
            const queryKey = ApiEndpoints.getAgreementTemplate(agreementType).queryKey
            queryClient.cancelQueries({ queryKey })
            notifyClose(dispatchNotification)
          },
        },
      ],
    })

    const reqInfo = await refetch()

    if (reqInfo.isSuccess) {
      notifyClose(dispatchNotification)
      const file = new Blob([reqInfo.data.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)

      const downloadLink = document.createElement('a')
      downloadLink.href = fileURL
      downloadLink.download = 'wzorUmowyWeSub.pdf'
      downloadLink.click()
    } else if (reqInfo.isError) {
      notifyError(dispatchNotification, {
        title: formatMessage({ id: 'notification.dear_customer' }),
        content: formatMessage({ id: 'notification.downloading_agreement_template_error' }),
      })
    }
  }

  return (
    <Box sx={containerStyle}>
      <Container>
        <Box sx={titleStyle}>
          <Typography variant="h2" mt={{ xs: 0, md: -0.6 }} mb={{ xs: 1.6, md: 0 }}>
            {formatMessage({ id: isLoan ? 'loan_offer' : 'subscription_offer' })}
          </Typography>
          <DownloadFileButton
            text="button.download_agreement_template"
            id="downloadAgreementTemplate-button"
            onClick={onDownloadAgreementTemplate}
          />
        </Box>
      </Container>
    </Box>
  )
}
export default StepSubHeader
