import { ReactNode } from 'react'

import Box from '@mui/material/Box'
import Footer from 'components/shared/Footer'

import { containerStyle } from './styles'

interface PageLayoutProps {
  children: ReactNode
}

const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <Box sx={containerStyle}>
      <Box sx={{ flexGrow: 1, display: 'flex', '& > div': { width: '100%' } }}>{children}</Box>
      <Footer />
    </Box>
  )
}

export default PageLayout
