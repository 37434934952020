import ApiEndpoints from 'api/endpoints'
import { useMutate } from 'hooks'
import { IVerificationCode, VerificationCodeVerifyRes } from 'models'

export const usePostGenerateVerificationCode = () => {
  return useMutate<IVerificationCode>(ApiEndpoints.postGenerateVerificationCode())
}

export const usePostVerifyVerificationCode = () => {
  return useMutate<VerificationCodeVerifyRes>(ApiEndpoints.postVerifyVerificationCode())
}
