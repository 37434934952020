import { useCallback, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useStepLayoutContext } from 'components/layouts'
import {
  PluginProductDataBody,
  prepareUpdateBannerAuditStatusBody,
  useGetPluginVendorData,
  usePostPluginProductData,
  useUpdateBannerAuditStatus,
} from 'components/pages/api'
import { ExternalLinks } from 'consts'
import { NotificationsContext, notifyError } from 'context'
import { IProduct } from 'models'

import { usePluginRouteParams } from './usePluginRouteParams'

interface VendorData {
  shouldUpdateBannerStatus?: boolean
}

export const usePluginVendorData = ({ shouldUpdateBannerStatus }: VendorData) => {
  const { setVendorImg } = useStepLayoutContext()
  const { formatMessage } = useIntl()
  const { routeParams } = usePluginRouteParams()

  const { dispatchNotification } = useContext(NotificationsContext)

  const [loading, setLoading] = useState(true)

  const displayError = () =>
    notifyError(dispatchNotification, {
      title: formatMessage({ id: 'notification.dear_customer' }),
      content: formatMessage({ id: 'notification.product_not_found' }),
      actions: [
        {
          label: formatMessage({ id: 'button.close' }),
          onClick: () => window.location.replace(ExternalLinks.WESUB),
        },
      ],
    })

  useEffect(() => {
    if (!routeParams.apiBannerParam || !routeParams.clientProductId || !routeParams.clientSellPrice || !routeParams.generatedBannerId) {
      setLoading(false)
      displayError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams])

  // get vendor data
  const { data: vendorData, isSuccess, isError } = useGetPluginVendorData(routeParams.apiBannerParam)

  // get product data
  const postPluginProductData = usePostPluginProductData()
  const updateBannerAuditStatus = useUpdateBannerAuditStatus()

  const [productData, setProductData] = useState<IProduct | null>(null)

  const onUpdateBannerAuditStatus = useCallback(async () => {
    if (shouldUpdateBannerStatus) {
      const body = prepareUpdateBannerAuditStatusBody(routeParams.generatedBannerId)
      if (body) {
        await updateBannerAuditStatus.mutate(body)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams.generatedBannerId, shouldUpdateBannerStatus])

  const onGetProductData = async () => {
    const body: PluginProductDataBody = {
      clientProductId: routeParams.clientProductId,
      apiBannerParam: vendorData.apiBannerParam,
      clientSellPrice: routeParams.clientSellPrice,
    }
    await postPluginProductData.mutate(body, {
      onSuccess: ({ data }: { data: IProduct }) => {
        if (data?.product) {
          setLoading(false)
          setProductData(data.product)
        } else {
          displayError()
        }
      },
      onError: () => {
        displayError()
      },
    })
  }

  useEffect(() => {
    if (vendorData?.banner && vendorData?.bannerContentType) {
      setVendorImg({
        src: `data:${vendorData.bannerContentType};base64,${vendorData.banner}`,
        alt: 'vendorImg',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData?.banner, vendorData?.bannerContentType])

  useEffect(() => {
    if (isError) {
      displayError()
    } else if (isSuccess && vendorData) {
      onGetProductData()
      onUpdateBannerAuditStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData, isSuccess, isError])

  return { data: productData, vendorData, loading }
}
