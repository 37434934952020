import ApiEndpoints from 'api/endpoints'
import { useRequest } from 'hooks'
import { IPaymentInfo } from 'models'

/**
 * The useGetPaymentInfo function is a custom hook that retrieves payment information for a specific
 * order ID.
 * @param {string} orderId - The `orderId` parameter is a string that represents the unique identifier
 * of a payment order.
 * @returns The `useGetPaymentInfo` function returns a boolean value.
 */
export const useGetPaymentInfo = (orderId: string, enabled: boolean) => {
  return useRequest<IPaymentInfo>(ApiEndpoints.getPaymentInfo(orderId), { enabled })
}
