import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg'
import { ReactComponent as FileEmptyIcon } from 'assets/icons/FileEmpty.svg'
import { ReactComponent as LabelIcon } from 'assets/icons/Label.svg'
import { ReactComponent as ProtectionIcon } from 'assets/icons/Protection.svg'

export const getCardTexts = (formatMessage: ({ id }: { id: string }) => string) => [
  {
    title: formatMessage({ id: 'message.elastic_contract.title' }),
    subtitle: formatMessage({ id: 'message.elastic_contract.description' }),
    icon: CalendarIcon,
  },
  {
    title: formatMessage({ id: 'message.full_protection.title' }),
    subtitle: formatMessage({ id: 'message.full_protection.description' }),
    icon: ProtectionIcon,
  },
  {
    title: formatMessage({ id: 'message.vat_invoice.title' }),
    subtitle: formatMessage({ id: 'message.vat_invoice.description' }),
    icon: FileEmptyIcon,
  },
  {
    title: formatMessage({ id: 'message.low_costs.title' }),
    subtitle: formatMessage({ id: 'message.low_costs.description' }),
    icon: LabelIcon,
  },
]
