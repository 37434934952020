import { StyledComponent } from '@emotion/styled'
import { Divider, DividerProps, styled } from '@mui/material'

export const StyledIDivider: StyledComponent<DividerProps> = styled(Divider)(({ theme }) =>
  theme.unstable_sx({
    my: { xs: 1.6, md: 2.4 },
  }),
)

export const containerSpacings = {
  p: { xs: 1.6, md: 2.4 },
}
