import { useContext } from 'react'
import { useIntl } from 'react-intl'

import { AxiosError, AxiosResponse } from 'axios'
import { useGetSaveCustomerAgreementData, usePostGenerateOrderContract } from 'components/pages/api'
import { ExternalLinks, HttpCode, PaymentType } from 'consts'
import { NotificationsContext, OrderContext, notifyError } from 'context'
import { IAgreementResponse, ICustomerOrder } from 'models'
import { CustomExceptionData } from 'models/dto'

import { ContractGenerationValidationSchema } from '../consts'
import { useAcceptContract } from './useAcceptContract'

interface ContractGeneration {
  orderData: ICustomerOrder | null | undefined
  formData: ContractGenerationValidationSchema
  handleSuccessGenerateOrderContract: (data: AxiosResponse<IAgreementResponse>) => void
}

export const useContractGeneration = ({ orderData, formData, handleSuccessGenerateOrderContract }: ContractGeneration) => {
  const { formatMessage } = useIntl()
  const { dispatchNotification } = useContext(NotificationsContext)

  const { contextData, selectedPayment } = useContext(OrderContext)

  const { refetch: refetchGetSaveCustomerAgreementData } = useGetSaveCustomerAgreementData(
    { data: orderData, formData },
    { enabled: false },
  )
  const handleErrorGenerateContractError = (error: AxiosError) => {
    if (error?.response?.status === HttpCode.UNPROCESSABLE_ENTITY && error?.response?.data) {
      const errorTitle = (error.response.data as any).title?.toString()
      switch (errorTitle) {
        case CustomExceptionData.BAD_CLIENT_DATA:
          notifyError(dispatchNotification, {
            title: formatMessage({ id: 'notification.bad_data' }),
            content: formatMessage({ id: 'notification.bad_customer_data_content' }),
          })
          return
        case CustomExceptionData.DEFAULT_CLIENT_NAME:
          notifyError(dispatchNotification, {
            title: formatMessage({ id: 'notification.dear_customer' }),
            content: formatMessage({ id: 'notification.default_customer_name_selected_error' }),
          })
          return
        case CustomExceptionData.PRINTER_FRAMEWORK_EXCEPTION:
          if (orderData?.buyerName && orderData?.buyerName?.trim().length > 0) {
            notifyError(dispatchNotification, {
              title: formatMessage({ id: 'notification.dear_customer' }),
              content: formatMessage({ id: 'notification.contract_generation_error' }),
            })
          } else {
            notifyError(dispatchNotification, {
              title: formatMessage({ id: 'notification.dear_customer' }),
              content: formatMessage({ id: 'notification.ags_printer_error' }),
              actions: [
                {
                  label: formatMessage({ id: 'button.close' }),
                  onClick: () => window.location.replace(orderData?.returnURL ?? ExternalLinks.WESUB),
                },
              ],
            })
            refetchGetSaveCustomerAgreementData()
          }
          return
        case CustomExceptionData.TD_ADDRESS_EXCEPTION:
          notifyError(dispatchNotification, {
            title: formatMessage({ id: 'notification.dear_customer' }),
            content: formatMessage({ id: 'notification.ags_printer_error' }),
            actions: [
              {
                label: formatMessage({ id: 'button.close' }),
                onClick: () => window.location.replace(orderData?.returnURL ?? ExternalLinks.WESUB),
              },
            ],
          })
          refetchGetSaveCustomerAgreementData()
          return

        default:
          notifyError(dispatchNotification, {
            title: formatMessage({ id: 'notification.dear_customer' }),
            content: formatMessage({ id: 'notification.contract_generation_error' }),
          })
          return
      }
    }

    notifyError(dispatchNotification, {
      title: formatMessage({ id: 'notification.error' }),
      content: formatMessage({ id: 'notification.contract_generation_error' }),
    })
  }

  const postGenerateOrderContract = usePostGenerateOrderContract(handleErrorGenerateContractError, handleSuccessGenerateOrderContract)

  const { onSubmitAcceptContract, contractAcceptanceLoading } = useAcceptContract({
    externalId: orderData?.externalId ?? '',
    isDebitNoteSelected: orderData?.extension ? orderData?.extension : selectedPayment === PaymentType.DEBIT_NOTE,
    extension: Boolean(contextData.isExtension),
    buyerPhone: formData.buyerPhone,
  })

  return { postGenerateOrderContract, onSubmitAcceptContract, contractAcceptanceLoading }
}
