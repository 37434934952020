import { ReactNode, memo, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Chip } from '@rent/ui'
import { Currency } from 'components/shared'
import { OrderContext } from 'context'
import { calcProductPrice, calcProductsTotalDeposit, calcProductsTotalPrice, checkProductsHaveSameRentalPeriod } from 'helpers'
import { IMappedProduct } from 'models'

import { containerStyle, gridItemContainerStyle, totalPriceStyle } from './styles'

interface ProductsDataSummaryProps {
  items: IMappedProduct[]
  actionButton?: ReactNode | null
}

const ProductsDataSummary = memo(({ items, actionButton }: ProductsDataSummaryProps) => {
  const { formatMessage } = useIntl()
  const { contextData } = useContext(OrderContext)

  const { totalPrice, totalDeposit, rentalPeriod } = useMemo(() => {
    const calculatedProducts = items.map(product => calcProductPrice(product))

    const totalPrice = calcProductsTotalPrice(calculatedProducts)
    const totalDeposit = calcProductsTotalDeposit(calculatedProducts)
    const isSameRentalPeriod = checkProductsHaveSameRentalPeriod(calculatedProducts)

    return {
      totalPrice,
      totalDeposit,
      rentalPeriod: isSameRentalPeriod ? calculatedProducts[0]?.rentalPeriod : null,
    }
  }, [items])

  return (
    <>
      <Divider />
      <Box sx={containerStyle}>
        {/* rentalPeriod */}
        {rentalPeriod && (
          <>
            <Box sx={gridItemContainerStyle}>
              <Typography variant="body2">{formatMessage({ id: 'subscription_period' })}</Typography>

              <Typography variant="body2" fontWeight={700}>
                {rentalPeriod} {formatMessage({ id: 'months' })}
              </Typography>
            </Box>
            <Divider sx={{ my: 1.6 }} />
          </>
        )}

        {/* deposit */}
        <>
          <Box sx={gridItemContainerStyle}>
            <Typography variant="body2">{formatMessage({ id: 'deposit' })}</Typography>
            <Typography variant="body2" fontWeight={700}>
              <Currency value={totalDeposit} mth={false} net={null} />
            </Typography>
          </Box>
          {contextData.isExtension && (
            <Typography variant="subtitle2" mt={-1}>
              {formatMessage({ id: 'message.deposit_transfer' })}
            </Typography>
          )}
          <Divider sx={{ my: 1.6 }} />
        </>

        {/* month rent price */}
        <Box sx={gridItemContainerStyle}>
          <Typography variant="body2" fontWeight={700} mt={0.2} mr={0.8}>
            {formatMessage({ id: 'month_subscription_price' })}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', flexBasis: '80%' }}>
            <Typography variant="h6" sx={totalPriceStyle}>
              <Currency value={totalPrice} mth={false} />
            </Typography>
            <Chip label={formatMessage({ id: 'final_price' })} color="success" />
          </Box>
        </Box>

        {/* actionButton */}
        {actionButton && <Box mt={3.2}>{actionButton}</Box>}
      </Box>
    </>
  )
})
export default ProductsDataSummary
