import { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { Box, Typography } from '@mui/material'
import { Button, Header } from '@rent/ui'
import { ReactComponent as SadEmojiIcon } from 'assets/icons/SadEmoji.svg'
import RentPayLogo from 'assets/images/rentPayLogoBiznes.svg'
import { NotificationsContext, notifyClose } from 'context'

import Footer from '../Footer'

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
}

interface ErrorFallbackProps {
  error?: Error
  isPage?: boolean
}

export default function ErrorFallback({ error, isPage }: ErrorFallbackProps) {
  const intl = useIntl()
  const { notification, dispatchNotification } = useContext(NotificationsContext)

  useEffect(() => {
    if (notification.open) {
      notifyClose(dispatchNotification)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  return (
    <Box display="flex" flexDirection="column" sx={{ minHeight: isPage ? '100vh' : 'auto' }}>
      {isPage && <Header img={{ src: RentPayLogo, alt: 'RentPayLogo' }}></Header>}
      <Box sx={containerStyle}>
        <Box display="flex" flexDirection="column" alignItems="center">
          {process.env.NODE_ENV === 'development' && (
            <Typography color="error.main" textAlign="center" mb={2}>
              {(error as any)?.message}
            </Typography>
          )}

          <Box color="grey.200" mb={4}>
            <SadEmojiIcon fill="inherit" />
          </Box>
          <Typography variant="h3" mb={2}>
            {intl.formatMessage({ id: 'notification.unexpected_error' })}
          </Typography>

          <Typography color="text.secondary" mb={4.8} sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
            {intl.formatMessage({ id: 'notification.generic_error' })}
          </Typography>

          <Button onClick={() => window.location.reload()}>{intl.formatMessage({ id: 'button.reload_page' })}</Button>
        </Box>
      </Box>
      {isPage && <Footer />}
    </Box>
  )
}
