import { memo } from 'react'
import { useIntl } from 'react-intl'

import Grid from '@mui/material/Grid'
import { SimpleCard } from '@rent/ui'

import { getCardTexts } from './cardTexts'

const AdditionalInfoBlock = memo(() => {
  const { formatMessage } = useIntl()
  const cards = getCardTexts(formatMessage)

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1.6 }}>
      {cards.map(el => (
        <Grid item xs={6} sm={3} key={el.title}>
          <SimpleCard {...el} />
        </Grid>
      ))}
    </Grid>
  )
})

export default AdditionalInfoBlock
