import { memo } from 'react'
import { useIntl } from 'react-intl'

import { Currency as RentUICurrency, CurrencyProps as RentUICurrencyProps } from '@rent/ui'

type CurrencyProps = Omit<RentUICurrencyProps, 'formatMessage'>

export const Currency = memo((props: CurrencyProps) => {
  const { formatMessage } = useIntl()

  return <RentUICurrency formatMessage={formatMessage} {...props} />
})

export default Currency
