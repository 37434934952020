import { useEffect, useState } from 'react'

import { useGetCheckClientConsents } from 'components/pages/api'

export type MarketingConsents = {
  electronicProcessingAccepted: boolean
  phoneProcessingAccepted: boolean
}
const initMarketingConsents = {
  electronicProcessingAccepted: false,
  phoneProcessingAccepted: false,
}

interface CheckClientConsents {
  providedNip: string
}

export const useCheckClientConsents = ({ providedNip }: CheckClientConsents) => {
  const [marketingConsents, setMarketingConsents] = useState<MarketingConsents>(initMarketingConsents)

  const {
    data: clientConsentsData,
    isError: clientConsentsIsError,
    isFetching: clientConsentsIsLoading,
  } = useGetCheckClientConsents(providedNip)

  useEffect(() => {
    if (clientConsentsData) {
      setMarketingConsents({
        electronicProcessingAccepted: Boolean(clientConsentsData?.electronicProcessingAccepted),
        phoneProcessingAccepted: Boolean(clientConsentsData?.phoneProcessingAccepted),
      })
    } else if (clientConsentsIsError) {
      setMarketingConsents(initMarketingConsents)
    }
  }, [clientConsentsData, clientConsentsIsError])

  return { marketingConsents, clientConsentsIsLoading }
}
