import { useContext, useEffect } from 'react'
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Box, Link, Typography } from '@mui/material'
import { InfoBar } from '@rent/ui'
import { validatePolish } from '@rent/ui/helpers'
import { FormFieldGenerator, SelectAll } from 'components/shared'
import { ExternalLinks, OrderStatus } from 'consts'
import { OrderContext } from 'context'
import { useFormFieldErrorFocus } from 'hooks'
import { CustomerPersonalDataConsentsFields, CustomerPersonalDataFields } from 'models'

import { ValidationSchema, customerPersonalDataConsentsFieldsProperties, customerPersonalDataFieldsProperties } from '../consts'
import { MarketingConsents } from '../hooks'

interface FormDataProps {
  onSubmitForm: SubmitHandler<ValidationSchema>
  orderStatus: OrderStatus | ''
  extension: boolean
  setProvidedNip: (nip: string) => void
  marketingConsents: MarketingConsents
  isPlugin?: boolean
}

const FormData = ({ onSubmitForm, orderStatus, extension, setProvidedNip, marketingConsents, isPlugin }: FormDataProps) => {
  const { formatMessage } = useIntl()
  const { contextData } = useContext(OrderContext)

  useFormFieldErrorFocus()
  const {
    control,
    setValue,
    formState: { dirtyFields },
  } = useFormContext()

  const watchedNip = useWatch({ control, name: CustomerPersonalDataFields.nip })

  useEffect(() => {
    if (dirtyFields[CustomerPersonalDataFields.nip] && validatePolish.nip(watchedNip)) {
      setProvidedNip(watchedNip)
    } else {
      setProvidedNip('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedNip, dirtyFields])

  useEffect(() => {
    // automatic selection of marketing consents based on provided nip
    if (marketingConsents.electronicProcessingAccepted) {
      setValue(CustomerPersonalDataConsentsFields.agreementEmailCommunication, marketingConsents.electronicProcessingAccepted)
    }
    if (marketingConsents.phoneProcessingAccepted) {
      setValue(CustomerPersonalDataConsentsFields.agreementPhoneCommunication, marketingConsents.phoneProcessingAccepted)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketingConsents])

  return (
    <Box sx={{ maxWidth: 500 }}>
      <>
        <Box mb={{ xs: 3.2, md: 6 }}>
          <Typography variant="h4" sx={{ mb: { xs: 2.4, md: 3.2 } }}>
            {formatMessage({ id: contextData.isLoan ? 'customer.buyer_data_loan' : 'customer.buyer_data' })}
          </Typography>
          {Object.entries(customerPersonalDataFieldsProperties(orderStatus)).map(([key, el]) => (
            <Box key={key} mb={{ xs: 2.4, md: 3.2 }}>
              <FormFieldGenerator fieldName={key as CustomerPersonalDataFields} properties={el} onSubmitForm={onSubmitForm} />
            </Box>
          ))}
        </Box>

        {/* address */}
        {!extension && !contextData.isLoan && (
          <Box mb={{ xs: 3.2, md: 6 }}>
            <Typography variant="h4" sx={{ mb: 2.4 }}>
              {formatMessage({ id: 'customer.delivery_method' })}
            </Typography>
            <InfoBar
              title={formatMessage({
                id: isPlugin ? 'message.address_for_plugin_transaction' : 'message.address_was_provided_in_the_store',
              })}
              align="left"
            />
          </Box>
        )}

        {/* consents */}
        <Box>
          <SelectAll
            fields={CustomerPersonalDataConsentsFields}
            disabledFields={{
              [CustomerPersonalDataConsentsFields.agreementEmailCommunication]: marketingConsents.electronicProcessingAccepted,
              [CustomerPersonalDataConsentsFields.agreementPhoneCommunication]: marketingConsents.phoneProcessingAccepted,
            }}
          />

          <Box ml={2.4} mt={1.6}>
            {Object.entries(customerPersonalDataConsentsFieldsProperties(marketingConsents, formatMessage)).map(([key, el]) => (
              <Box key={key} mb={1.6}>
                <FormFieldGenerator fieldName={key as CustomerPersonalDataFields} properties={el} />
              </Box>
            ))}
            {(marketingConsents.electronicProcessingAccepted || marketingConsents.phoneProcessingAccepted) && (
              <InfoBar
                title={formatMessage(
                  { id: 'message.marketingContentsAlreadyAccepted' },
                  {
                    link: (
                      <Link target="_blank" href={ExternalLinks.WEBOOK} rel="noreferrer" color="text.primary" id="rodo-link">
                        WeBook
                      </Link>
                    ),
                  },
                )}
                align="left"
                sx={{ mt: -0.6 }}
              />
            )}
          </Box>
        </Box>
      </>
    </Box>
  )
}

export default FormData
