import { useContext, useState } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { CodeVerification } from '@rent/ui'
import { usePostVerifyVerificationCode } from 'components/pages/api'
import { OrderContext } from 'context'
import { VerificationCodeVerifyRes } from 'models'

interface CodeVerificationContainerProps {
  onChangePhoneNo: () => void
  onResendCode: () => void
  buyerPhone: string
  onSubmitForm: SubmitHandler<any>
}

const CodeVerificationContainer = ({ buyerPhone, onChangePhoneNo, onResendCode, onSubmitForm }: CodeVerificationContainerProps) => {
  const { formatMessage } = useIntl()
  const postVerifyVerificationCode = usePostVerifyVerificationCode()
  const { handleSubmit } = useFormContext()
  const { contextData } = useContext(OrderContext)

  const [errorInfo, setErrorInfo] = useState('')

  const onSubmitCode = async (code: string) => {
    await postVerifyVerificationCode.mutate(
      {
        smsCode: code,
        uuid: contextData.externalId,
      },
      {
        onSuccess: ({ data }: { data: VerificationCodeVerifyRes }) => {
          if (data.codeVerification) {
            setErrorInfo('')
            handleSubmit(onSubmitForm)()
          } else {
            setErrorInfo(formatMessage({ id: 'message.incorrect_verification_code' }))
          }
        },
        onError: () => {
          setErrorInfo(formatMessage({ id: 'message.incorrect_verification_code' }))
        },
      },
    )
  }

  return (
    <CodeVerification
      title={formatMessage({ id: contextData.isLoan ? 'message.agreement_signing_loan' : 'message.agreement_signing' })}
      phoneNo={buyerPhone}
      onResendCode={onResendCode}
      onChangePhoneNo={onChangePhoneNo}
      onSubmitCode={onSubmitCode}
      errorMsg={errorInfo}
      loading={postVerifyVerificationCode.isLoading}
      btnTitle={contextData.isExtension ? 'button.extending' : contextData.isLoan ? 'button.confirm_and_pay' : 'button.order_and_pay'}
    />
  )
}

export default CodeVerificationContainer
