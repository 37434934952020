import { Link } from '@mui/material'
import { validateAgreementNo, validateIban, validatePolish } from '@rent/ui/helpers'
import { ExternalLinks, PHONE_REGEXP_PL } from 'consts'
import dayjs from 'dayjs'
import {
  ContractGeneratedDataFields,
  ContractGenerationDataConsentsFields,
  ContractGenerationDataFields,
  ReplacementAgreementFields,
} from 'models'
import { z } from 'zod'

export const validationSchema = (formatMessage: ({ id }: { id: string }) => string) =>
  z
    .object({
      [ContractGenerationDataFields.nameAndLastName]: z
        .string()
        .trim()
        .min(1, { message: formatMessage({ id: 'notification.required_field' }) }),

      [ContractGenerationDataFields.pesel]: z
        .string()
        .trim()
        .min(1, { message: formatMessage({ id: 'notification.required_field' }) })
        .refine(value => validatePolish.pesel(value), { message: formatMessage({ id: 'notification.pesel_invalid' }) }),
      [ContractGenerationDataFields.identityCardNumber]: z
        .string()
        .trim()
        .min(1, { message: formatMessage({ id: 'notification.required_field' }) })
        .refine(value => validatePolish.identityCard(value), {
          message: formatMessage({ id: 'notification.identity_card_number_invalid' }),
        }),
      [ContractGenerationDataFields.identityCardValidFrom]: z.coerce
        .date({
          errorMap: (issue, { defaultError }) => ({
            message: issue.code === 'invalid_date' ? formatMessage({ id: 'notification.invalid_date' }) : defaultError,
          }),
        })
        .max(new Date(new Date().setDate(new Date().getDate() - 1)), {
          message: formatMessage({ id: 'notification.identity_card_valid_from_invalid' }),
        }),
      [ContractGenerationDataFields.identityCardValidTo]: z.coerce
        .date({
          errorMap: (issue, { defaultError }) => ({
            message: issue.code === 'invalid_date' ? formatMessage({ id: 'notification.invalid_date' }) : defaultError,
          }),
        })
        .min(new Date(), {
          message: formatMessage({ id: 'notification.identity_card_valid_to_invalid' }),
        }),
      [ContractGenerationDataFields.returnAccountNumber]: z
        .string()
        .trim()
        .min(1, { message: formatMessage({ id: 'notification.required_field' }) })
        .refine(value => validateIban(value), {
          message: formatMessage({ id: 'notification.return_account_number_invalid' }),
        }),

      [ContractGenerationDataFields.buyerPhone]: z
        .string()
        .min(1, { message: formatMessage({ id: 'notification.required_field' }) })
        .refine(value => PHONE_REGEXP_PL.test(value), { message: formatMessage({ id: 'notification.phoneNumber_invalid_pl' }) }),

      [ReplacementAgreementFields.replacementAgreement]: z.boolean(),
      [ReplacementAgreementFields.replacementAgreementVerified]: z.boolean(),

      [ReplacementAgreementFields.replacementAgreementNumber]: z.string(),

      // checkboxes
      [ContractGenerationDataConsentsFields.selectAll]: z.boolean(),

      [ContractGenerationDataConsentsFields.agreementAdministrator]: z.boolean().refine(val => val, {
        message: formatMessage({ id: 'notification.required_field' }),
      }),
      [ContractGenerationDataConsentsFields.agreementContractor]: z.boolean().refine(val => val, {
        message: formatMessage({ id: 'notification.required_field' }),
      }),
      [ContractGeneratedDataFields.agreementGenerated]: z.boolean(),
      [ContractGeneratedDataFields.agreementAcceptance]: z.boolean(),
    })
    .refine(data => !(data.agreementGenerated && !data.agreementAcceptance), {
      message: formatMessage({ id: 'notification.required_field' }),
      path: ['agreementAcceptance'],
    })
    .superRefine(({ replacementAgreement, replacementAgreementVerified, replacementAgreementNumber }, ctx) => {
      if (replacementAgreement && !replacementAgreementNumber) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: formatMessage({ id: 'notification.required_field' }),
          path: ['replacementAgreementNumber'],
        })
      }
      if (replacementAgreement && !validateAgreementNo(replacementAgreementNumber)) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: formatMessage({ id: 'notification.replacement_agreement_number_invalid' }),
          path: ['replacementAgreementNumber'],
        })
      }
      if (replacementAgreement && validateAgreementNo(replacementAgreementNumber) && !replacementAgreementVerified) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: formatMessage({ id: 'notification.replacement_agreement_no_must_be_verified' }),
          path: ['replacementAgreementNumber'],
        })
      }
    })

export type ContractGenerationValidationSchema = z.infer<ReturnType<typeof validationSchema>>

export const contractGenerationDataFieldsProperties = {
  [ContractGenerationDataFields.pesel]: {
    type: 'text',
    inputProps: { fullWidth: true },
  },
  [ContractGenerationDataFields.identityCardNumber]: {
    type: 'text',
    inputProps: { fullWidth: true },
  },
  [ContractGenerationDataFields.identityCardValidFrom]: {
    type: 'datepicker',
    datePickerSlotProps: {
      textField: {
        name: ContractGenerationDataFields.identityCardValidFrom,
        fullWidth: true,
      },
    },
    datePickerProps: { maxDate: dayjs().subtract(1, 'day') },
  },
  [ContractGenerationDataFields.identityCardValidTo]: {
    type: 'datepicker',
    datePickerSlotProps: {
      textField: {
        name: ContractGenerationDataFields.identityCardValidTo,
        fullWidth: true,
      },
    },
    datePickerProps: { minDate: dayjs().add(1, 'day') },
  },
}

export const contractGenerationDataConsentsFieldsProperties = (isLoan: boolean) => ({
  [ContractGenerationDataConsentsFields.agreementAdministrator]: {
    type: 'checkbox-accordion',
    required: true,
    customLabelExpanded: {
      id: `form.${ContractGenerationDataConsentsFields.agreementAdministrator}${isLoan ? 'Loan' : ''}_label_expanded`,
      values: {
        link: (
          <Link target="_blank" href={ExternalLinks.RODO} rel="noreferrer" color="text.primary" id="rodo-link">
            {ExternalLinks.RODO}
          </Link>
        ),
      },
    },
  },
  [ContractGenerationDataConsentsFields.agreementContractor]: {
    type: 'checkbox-accordion',
    required: true,
  },
})

export const formInitValues = ({ buyerPhone }: { buyerPhone: string }) => ({
  nameAndLastName: '',
  pesel: '',
  identityCardNumber: '',
  identityCardValidFrom: undefined,
  identityCardValidTo: undefined,
  returnAccountNumber: '',
  buyerPhone,

  replacementAgreement: false,
  replacementAgreementVerified: false,
  replacementAgreementNumber: '',

  selectAll: false,
  agreementAdministrator: false,
  agreementContractor: false,
  agreementGenerated: false,
  agreementAcceptance: false,
})
