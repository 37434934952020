import { ReactNode } from 'react'

import { Breakpoint, Container, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import { Header } from '@rent/ui'
import RentPayLogo from 'assets/images/rentPayLogoBiznes.svg'

import { mainPaperStyle } from './styles'

interface LayoutWithHeaderProps {
  children: ReactNode
  maxWidth?: Breakpoint | string
  noBgr?: boolean
}

const LayoutWithHeader = ({ children, maxWidth = 'lg', noBgr }: LayoutWithHeaderProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Header img={{ src: RentPayLogo, alt: 'RentPayLogo' }} />
      <Container sx={{ height: '100%' }}>
        <Box sx={{ maxWidth, margin: '0 auto', height: '100%' }}>{noBgr ? children : <Paper sx={mainPaperStyle}>{children}</Paper>}</Box>
      </Container>
    </Box>
  )
}

export default LayoutWithHeader
