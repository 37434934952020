export enum GeneralRoute {
  NOT_FOUND = '404',
  ORDER = 'order',
  ORDER_PLUGIN = 'order-plugin',
  TERMS = 'terms',
  PRIVACY_POLICY = 'privacy-policy',
  ORDER_FINISHED_CONFIRMATION = 'order-finished-confirmation',
  ORDER_CANCELED = 'order-canceled',
}
export enum StepRoute {
  STORE_ORDER_SUMMARY = 'store-order-summary',
  STORE_ORDER_SUMMARY_PLUGIN = 'store-order-summary-plugin',
  PERSONAL_DATA = 'personal-data',
  PERSONAL_DATA_PLUGIN = 'personal-data-plugin',
  CONTRACT_GENERATION = 'contract-generation',
  FINALIZATION = 'finalization',
}

export enum RouteQueryParam {
  PREVIEW_ONLY = 'preview-only',
  ORDER_FINISHED_STATUS = 'order-finished-status',
}
export enum RouteQueryPluginParam {
  apiBannerParam = 'apiBannerParam',
  method = 'method',
  clientProductId = 'clientProductId',
  clientSellPrice = 'clientSellPrice',
  generatedBannerId = 'generatedBannerId',
}

export enum RouteQueryParamPaymentType {
  EXTENSION_SUCCESS = 'extension-success',
  DEBIT_NOTE_SUCCESS = 'debit-note-success',
  AUTOPAY_LINK_GENERATION_SUCCESS = 'autopay-link-generation-success',
  AUTOPAY_LINK_GENERATION_ERROR = 'autopay-link-generation-error',
  AUTOPAY_LINK_GENERATION_OUT_OF_TIME = 'autopay-link-generation-out-of-time',
  AUTOPAY_LINK_GENERATION_RISK_REPORT_RED = 'autopay-link-generation-output',
  AUTOPAY_CALLBACK = 'autopay-callback',
}

export enum RouteQueryParamFinishedStatus {
  AGREEMENT_NOT_ACCEPTED = 'agreement_not_accepted',
  PRODUCTS_NOT_AVAILABLE = 'products_not_available',
}
