const button = {
  proceed_to_order: 'Przejdź do zamówienia',
  go_further: 'Przejdź dalej',
  go_back: 'Wróć do poprzedniej strony',
  try_later: 'Spróbuj później',
  try_again: 'Spróbuj ponownie',
  reload_page: 'Odśwież stronę',
  more: 'Więcej',
  less: 'Mniej',
  select_all: 'Zaznacz wszystkie',
  confirm: 'Zatwierdź',
  generate_contract: 'Wygeneruj umowę',
  close: 'Zamknij',
  cancel: 'Anuluj',
  edit_data: 'Edytuj dane',
  edit_contract_data: 'Edytuj dane do umowy',
  download_agreement: 'Pobierz umowę',
  download_agreement_template: 'Pobierz wzór umowy',
  ok: 'OK',
  order_and_pay: 'Zamawiam i płacę',
  confirm_and_pay: 'Potwierdzam i płacę',
  go_to_the_store: 'Powrót do sklepu',
  verify: 'Weryfikuj',
  accept: 'Zaakceptuj',
  extending: 'Przedłużam',
  sign_and_go_further: 'Podpisuję umowę i przechodzę dalej',
  sign_agreement: 'Podpisz umowę',
  resend_code: 'Wyślij jeszcze raz',
  change_phone: 'Zmień numer telefonu',
}

export { button }
