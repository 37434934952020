export interface BaseDto {}

export interface IdDto extends BaseDto {
  id?: string
}

export interface PageDto extends BaseDto {
  currentPage?: number
  numberRecords?: number
}

// export interface RecordList<X, T> {
//   count?: number
//   totalCount?: number
//   records?: T[]
// }

export interface ContractExtensionProductDto extends IdDto {
  name?: string
  description?: string
  externalId?: string
  registrationNumber?: string
  atrValue?: string
  stock?: number
  currentMonthPrice?: number
  safeupPrice?: number
  safeupPlusPrice?: number
  initialValueItem?: number
  lastInstallmentAmount?: number
  previousDeposit?: number
  previousRv?: number
  calcQueryResult?: boolean
  recordIdDsa?: number
  endToBuyRatio?: number
  paymentDelay?: number
  categoryName?: string
}

export interface ContractExtensionSearchCriteriaDto extends PageDto {
  currentCtx?: ContractExtensionSearchCtx
  permissionCtx?: PermissionCtx
  orderCtx?: OrderCtx
  productName?: string
  atrValue?: string
  description?: string
  registrationNumber?: string
  externalId?: string
  categoryIds?: number[]
}

export interface CustomerOrderAgreementInfoDto extends Serializable {
  externalRequestType?: string
  agreementClientUrl?: string
  contractEligible?: boolean
}

export interface CustomerOrderBlueMediaCtxDto extends Serializable {
  blueMediaPayment?: boolean
  vendorId?: number
  externalId?: string
}

export interface CustomerOrderPaymentLinkDto extends Serializable {
  blueMediaPaymentLink: string | null
  vendorId?: number
  externalId: string
  riskReportRed?: boolean
  vendorLogin?: string
}

export interface CustomerOrderSearchCriteriaDto extends PageDto {
  id?: number
  name?: string
  nip?: string
  telephone?: string
  status?: string
  dateFrom?: Date
  dateTo?: Date
  vendorIds?: number[]
}

export interface CustomerOrderVendorSearchCriteriaDto extends PageDto {
  safeUpPlusTaken?: boolean
  status?: string
  dealerLogin?: string[]
  salonId?: number[]
  bossId?: number[]
  dateFrom?: Date
  dateTo?: Date
  permissionCtx?: PermissionCtx
  selectCustomerType?: SelectCustomerType
  searchCtx?: SearchCtx
  vendorId?: number
  userLogin?: string
  fileConfigurationDto?: FileConfigurationDto[]
}

export interface DateWrapperDto extends Serializable {
  value?: Date
}

export interface StringResponseDto extends Serializable {
  body?: string
}

export interface ProductCategorySearchCriteriaDto extends PageDto {
  vendorId?: number
  convertedProduct?: boolean
}

export interface FileConfigurationDto {
  id?: number
  headerName?: string
  propertyName?: string
}

export interface JobResultDto extends IdDto {
  type?: string
  status?: JobStatus
  parameters?: string
  maxRetryCount?: number
  retry?: number
  message?: string
  updateDate?: Date
  lastStart?: Date
  firstStart?: Date
  finishDate?: Date
}

export interface JobSearchCriteriaDto extends PageDto {
  partialParameter?: string
  jobStatus?: JobStatus
  jobType?: JobType
  daysAgo?: number
}

export interface RentDealerProductCategoryDto extends IdDto {
  name?: string
}

export interface RentDealerProductDto extends IdDto {
  name?: string
  description?: string
  externalId?: string
  suggestionIds?: string
  stock?: number
  productSellType?: ProductSellType
  externalUrlId?: string
  integrationId?: string
  manufYear?: number
  loanFee?: number
  category?: RentDealerProductCategoryDto
  currentPrice?: RentDealerProductPriceDto
  currentB2cPrice?: RentDealerProductPriceDto
}

export interface RentDealerProductPriceDto extends IdDto {
  sellPrice?: number
  monthPrice?: number
  preparePrice?: number
  deposit?: number
  safeupPrice?: number
  safeupPlusPrice?: number
}

export interface UserExtraDto extends BaseDto {
  id?: number
  firstName?: string
  phoneNumber?: number
  saloon?: string
}

export interface VendorDto {
  id?: number
  name?: string
  shortName?: string
  nip?: number
  login?: string
  apiBannerParam?: string
}

export interface VendorLoginEmployeeDto {
  id?: number
  login?: string
}

export interface FileWrapper extends BaseDto {
  content?: any
  fileName?: string
  applicationType?: string
  fileExtensionType?: FileExtensionType
}

export interface PdfBookstoreWrapper {
  pdfBookstore?: PdfBookstore
}

export interface AliorApplicationCallback extends BaseDto {
  callback?: boolean
}

export interface AliorApplicationCallbackResponse extends AliorApplicationCallback {
  customerId?: number
}

export interface AnonymousAgreementData extends BaseDto {
  postalCode?: string
  city?: string
  street?: string
  country?: string
  houseNumber?: string
  apartmentNumber?: string
  addressDifferentFromCorrespondence?: boolean
  noStreet?: boolean
}

export interface AnonymousOfferData extends BaseDto {
  postalCode?: string
  carrierName?: string
  city?: string
  street?: string
  country?: string
  houseNumber?: string
  apartmentNumber?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  otherData?: boolean
}

export interface Serializable {}

export enum CrudType {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum CustomExceptionData {
  DUPLICATE_GUS_FRAMEWORK_AGREEMENT_EXCEPTION = 'customer has a GUS framework agreement',
  PRINTER_FRAMEWORK_EXCEPTION = 'AGS printer wrong agreement status',
  CART_PROPORTIONS_EXCEPTION = 'Bad cart proportions',
  IDREAM_ORDER_EXCEPTION = 'accessory count more than percent of order included in vendor calculation param',
  AGREEMENT_EXCEPTION = 'Riskreport is RED',
  TD_ADDRESS_EXCEPTION = 'No external address found',
  DUPLICATE_USER_EMAIL_EXCEPTION = 'exist user assigned to this email',
  NIP_ERROR = 'NIP checking error',
  BAD_CLIENT_DATA = 'Client data are invalid',
  DEFAULT_CLIENT_NAME = 'Default client name is selected',
  UNPROCESSABLE_LEGAL_FORM = 'Unsupported form of company law',
}

export enum ProductSellType {
  PRODUCT_HERO = 'PRODUCT_HERO',
  ACCESSORY = 'ACCESSORY',
}

export enum XlsCalculatorCtx {
  PRODUCT_CATEGORY_RECALCULATION = 'PRODUCT_CATEGORY_RECALCULATION',
  WIBOR_RECALCULATION = 'WIBOR_RECALCULATION',
  GUI_PRODUCT_CRUD_RECALCULATION = 'GUI_PRODUCT_CRUD_RECALCULATION',
  RENT_DEALER_SELL_PRICE_RECALCULATION = 'RENT_DEALER_SELL_PRICE_RECALCULATION',
  API_V2_PRODUCT_RECALCULATE = 'API_V2_PRODUCT_RECALCULATE',
  COLLECT_PRODUCTS = 'COLLECT_PRODUCTS',
  CONTRACT_EXTENSION = 'CONTRACT_EXTENSION',
}

export enum ContractExtensionSearchCtx {
  CONTRACT_EXTENSION = 'CONTRACT_EXTENSION',
  RENT_DEALER = 'RENT_DEALER',
}

export enum OrderCtx {
  ASC_CTX = 'ASC_CTX',
  DESC_CTX = 'DESC_CTX',
  NONE = 'NONE',
}

export enum PermissionCtx {
  B2B_PERMISSION = 'B2B_PERMISSION',
  B2C_PERMISSION = 'B2C_PERMISSION',
}

export enum SearchCtx {
  FRENT_VIEW = 'FRENT_VIEW',
  RENT_DEALER_VIEW = 'RENT_DEALER_VIEW',
  SUPER_USER_VIEW = 'SUPER_USER_VIEW',
}

export enum SelectCustomerType {
  B2B = 'B2B',
  B2C = 'B2C',
  ALL = 'ALL',
}

export enum FileExtensionType {
  CSV = 'CSV',
  XLSX = 'XLSX',
  PDF = 'PDF',
}

export enum PdfBookstore {
  AGREEMENT_DRAFT_B2C = 'doc-b2c-draft',
  CREDIT_INSTR_B2B = 'doc-credit',
  DEALER_INSTR_B2B = 'doc-dealer',
  DEALER_INSTR_B2C = 'doc-dealer-b2c',
  SELF_FINANCING = 'doc-fin',
  KOMPUTRONIK_INSTR = 'doc-komputronik',
  KOMPUTRONIK_MANUAL_SERVICEDESK = 'komputronik-manual-servicedesk',
  KOMPUTRONIK_INSTR_FOR_WITHDRAWING = 'instrukcja-odstapienia-od-umowy-najmu-b2c',
  KOMPUTRONIK_STATEMENT_OF_WITHDRAWAL = 'oswiadczenie-o-odstapieniu-od-umowy',
  KOMPUTRONIK_VIRTUAL_CART_INSTRUCTION_B2B = 'instrukcja-b2b-wirtualny-koszyk',
  AGREEMENT_DRAFT_B2B = 'draft_umowy_klient_biznesowy',
  B2B_INSTR = 'instrukcja_procesu_klient_biznesowy',
  SAFE_UP = 'safeup',
  SAFE_UP_RANGE = 'zakres_pakietow_safeup_klient_biznesowy',
  IDREAM_SAFE_UP_RANGE = 'idream_zakres_pakietow_safeup_klient_biznesowy',
  SHARE_DATA_CONSENT_B2B = 'zgoda_na_udostepnienie_danych_klient_biznesowy',
  SHARE_DATA_CONSENT_B2C = 'zgoda-na-udostepnianie-danych',
  ARCHIVE_AGREEMENT_INFO = 'archiveAgreementInfo',
}

export enum JobStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
}

export enum JobType {
  RISKY_EMAIL_SEND_EMAIL = 'RISKY_EMAIL_SEND_EMAIL',
  COMPANY_DATA_REQUEST = 'COMPANY_DATA_REQUEST',
  GENERATE_OFFER_PDF = 'GENERATE_OFFER_PDF',
  GENERATE_CONTRACT_PDF = 'GENERATE_CONTRACT_PDF',
  SEND_OFFER = 'SEND_OFFER',
  SEND_OFFER_ONLY = 'SEND_OFFER_ONLY',
  COMPLETE_ORDER = 'COMPLETE_ORDER',
  GENERATE_RISK_REPORT = 'GENERATE_RISK_REPORT',
  GENERATE_RISK_TRANSPARENT_DATA_REPORT = 'GENERATE_RISK_TRANSPARENT_DATA_REPORT',
  GENERATE_RISK_RULE_ENGINE_TRANSPARENT_DATA_REPORT = 'GENERATE_RISK_RULE_ENGINE_TRANSPARENT_DATA_REPORT',
  GENERATE_RISK_INFO_REPORT = 'GENERATE_RISK_INFO_REPORT',
  CLOSE_NOT_SIGNED_AGREEMENTS = 'CLOSE_NOT_SIGNED_AGREEMENTS',
  CLOSE_NOT_ACCEPTED_OFFERS = 'CLOSE_NOT_ACCEPTED_OFFERS',
  SEND_CONTRACT_DATA_TO_BACKOFFICE = 'SEND_CONTRACT_DATA_TO_BACKOFFICE',
  SEND_PROTOCOL_DATA_TO_BACKOFFICE = 'SEND_PROTOCOL_DATA_TO_BACKOFFICE',
  SEND_DECISION_EMAIL_TO_USER = 'SEND_DECISION_EMAIL_TO_USER',
  VALIDATE_CUSTOMER_ORDER = 'VALIDATE_CUSTOMER_ORDER',
  RECREATE_OFFER = 'RECREATE_OFFER',
  SEND_RISK_DECISION_EMAIL = 'SEND_RISK_DECISION_EMAIL',
  CLOSE_NOT_ACTIVE_OFFERS = 'CLOSE_NOT_ACTIVE_OFFERS',
  UPDATE_CLIENT_CUSTOMER_ORDER = 'UPDATE_CLIENT_CUSTOMER_ORDER',
  VALIDATE_PRODUCT_PRICE = 'VALIDATE_PRODUCT_PRICE',
  UNIFICATE_PRODUCT_PRICE = 'UNIFICATE_PRODUCT_PRICE',
  VALIDATE_SAFEUP_PRODUCT_PRICE = 'VALIDATE_SAFEUP_PRODUCT_PRICE',
  RESIZE_PICTURE = 'RESIZE_PICTURE',
  SEND_PREORDER = 'SEND_PREORDER',
  SET_VENDOR_ID = 'SET_VENDOR_ID',
  MIGRATE_PHOTOS = 'MIGRATE_PHOTOS',
  MIGRATE_VENDOR = 'MIGRATE_VENDOR',
  RECALCULATE_PRICE = 'RECALCULATE_PRICE',
  SET_VENDOR_IMAGE_ID = 'SET_VENDOR_IMAGE_ID',
  CHANGE_PRODUCTS_IN_RECYCLE_BIN = 'CHANGE_PRODUCTS_IN_RECYCLE_BIN',
  REMOVE_OLD_BANNER_AUDIT_DATA = 'REMOVE_OLD_BANNER_AUDIT_DATA',
  CURRENCY_RATE = 'CURRENCY_RATE',
  CREATE_JIRA_TASK = 'CREATE_JIRA_TASK',
  CREATE_JIRA_RISK_TASK = 'CREATE_JIRA_RISK_TASK',
  CALCULATE_B2C_PRICE = 'CALCULATE_B2C_PRICE',
  CALCULATE_B2C_MAIN_PRICES = 'CALCULATE_B2C_MAIN_PRICES',
  DOWNLOAD_KOMPUTRONIK_PRODUCTS = 'DOWNLOAD_KOMPUTRONIK_PRODUCTS',
  DOWNLOAD_CYFROWE_PRODUCTS = 'DOWNLOAD_CYFROWE_PRODUCTS',
  PAYMENT_CHECK = 'PAYMENT_CHECK',
  SEND_PAYMENT_LINK = 'SEND_PAYMENT_LINK',
  EVICT_ALL_CACHE = 'EVICT_ALL_CACHE',
  CALCULATE_PRODUCTS_IN_CATEGORY = 'CALCULATE_PRODUCTS_IN_CATEGORY',
  VERIFY_UPDATE_PRODUCTS_IN_CATEGORY = 'VERIFY_UPDATE_PRODUCTS_IN_CATEGORY',
  TRANSITION_JIRA_TASK = 'TRANSITION_JIRA_TASK',
  TRANSITION_JIRA_RISK_TASK = 'TRANSITION_JIRA_RISK_TASK',
  CONTRACT_EXTENSION = 'CONTRACT_EXTENSION',
  FIRE_SCRAPPER = 'FIRE_SCRAPPER',
  FIRE_FILE_SCRAPPER = 'FIRE_FILE_SCRAPPER',
  FIRE_FILE_VENDOR_SCRAPPER = 'FIRE_FILE_VENDOR_SCRAPPER',
  MULTI_THREAD_SCRAPPER = 'MULTI_THREAD_SCRAPPER',
  SCRAPPER_EXECUTE = 'SCRAPPER_EXECUTE',
  AUTOMATIC_RECALCULATION_WIBOR = 'AUTOMATIC_RECALCULATION_WIBOR',
  MULTI_THREAD_WIBOR = 'MULTI_THREAD_WIBOR',
  WIBOR_RECALCULATION_EXECUTE = 'WIBOR_RECALCULATION_EXECUTE',
  AUTHOLOGIC_PREPARE_URL = 'AUTHOLOGIC_PREPARE_URL',
  AUTHOLOGIC_STATUS = 'AUTHOLOGIC_STATUS',
  TRADER_REPORT = 'TRADER_REPORT',
  CREDIT_CANCELLATION = 'CREDIT_CANCELLATION',
}
