import { SxProps, Theme } from '@mui/material'
import { containerSpacings } from 'components/shared/styledComponents'

export const containerStyle: SxProps<Theme> = {
  bgcolor: 'background.paper',
  borderBottomLeftRadius: '.8rem',
  borderBottomRightRadius: '.8rem',
  ...containerSpacings,
}

export const gridItemContainerStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  mb: 1.6,
}

export const totalPriceStyle: SxProps<Theme> = ({ typography }) => ({
  textAlign: 'right',
  fontFamily: typography.h5.fontFamily,
  mb: 0.8,
  whiteSpace: 'pre-wrap',
})
