const general = {
  product_selection: 'Wybór produktu',
  personal_data: 'Dane osobowe',
  contract_generation: 'Generowanie umowy',
  finalization: 'Finalizacja',
  safe_purchase: 'Bezpieczne zakupy',
  summary: 'Podsumowanie',
  agreement: 'Umowa',
}

export { general }
