import { memo, useContext } from 'react'

import Box from '@mui/material/Box'
import { FormFieldGenerator, SelectAll } from 'components/shared'
import { OrderContext } from 'context'
import { ContractGenerationDataConsentsFields, ContractGenerationDataFields } from 'models'

import { contractGenerationDataConsentsFieldsProperties } from '../consts'

const DataConsents = memo(() => {
  const { contextData } = useContext(OrderContext)

  return (
    <Box>
      <SelectAll fields={ContractGenerationDataConsentsFields} />

      <Box ml={2.4} mt={1.6}>
        {Object.entries(contractGenerationDataConsentsFieldsProperties(contextData.isLoan)).map(([key, el]) => (
          <Box key={key} mb={1.6}>
            <FormFieldGenerator fieldName={key as ContractGenerationDataFields} properties={el} />
          </Box>
        ))}
      </Box>
    </Box>
  )
})

export default DataConsents
